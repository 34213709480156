<script>
  export default {
    name: 'InitializeFormMixin',
    watch: {
      initialValues(val) {
        this.$refs.form?.finalForm.initialize(val);
      }
    },
  };
</script>

<style scoped>

</style>
