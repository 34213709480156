import DataProvider from '@/api/DataProvider';
import HttpAdapter from '@/api/adapters/HttpAdapter';
import MockAdapter from '@/api/adapters/MockAdapter';
import mocks from '@/api/cbr/mocks';
import httpClient from '@/api/httpClient';
import resources from '@/api/cbr/resources';
import getEnvVariable from '@/utils/getEnvVariable';
import store from '@/store';

class CommunityMockAdapter extends MockAdapter {
    constructor(data) {
        super(data);
    }

    getOne(data, resource, params) {
        switch (resource) {
        case 'livingUnits':
            return data.livingUnits[0];
        default:
            return super.getOne(data, resource, params);
        }
    }

    getList(data, resource, params) {
        const items = super.getList(data, resource, params);

        switch (resource) {
        case 'leaseHistory':
            return { items };

        default:
            return items;
        }
    }

    applyPurposeForAll(data, resource, params) {
        const list = this.getList(data, resource, { type: params.data.type });
        list.forEach(record => {
            this.patch(data, resource, {
                data: {
                    id: record.id,
                    businessPurpose: params.data.businessPurpose,
                },
            });
        });
        return this.getList(data, resource, { type: params.data.type });
    }
}

const baseURL = getEnvVariable('VUE_APP_CBR_API_URL');
const beforeRequestInterceptor = ({ requestConfig, action }) => {
    const resultingConfig = {
        ...requestConfig,
        headers: {
            ...requestConfig.headers,
            'X-Quext-Customer-Id': store.getters['auth/customerId'],
            'X-Quext-Community-Id': store.getters['auth/community']?.id,
        },
    };

    if (action === 'applyPurposeForAll') {
        resultingConfig.headers['Content-Type'] = 'application/json';
    }

    return resultingConfig;
};

const httpAdapter = new HttpAdapter(httpClient(baseURL), resources, beforeRequestInterceptor);
const mockAdapter = new CommunityMockAdapter(mocks);

export default new DataProvider({
    handle(action, resource, params) {
        switch (resource) {
        case 'profile':
        case 'floorPlans':
        case 'unitTypes':
        case 'livingBuildings':
        case 'locations':
        case 'buildingStructureTypes':
        case 'unitStructureTypes':
        case 'livingUnits':
        case 'businessPurposes':
        case 'parkingSpaces':
            return httpAdapter.handle(action, resource, params);

        default:
            return mockAdapter.handle(action, resource, params);
        }
    },
});
