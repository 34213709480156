const getApplication = state => state.application;
const getApplicationsList = state => state.applications;
const getcurrentApplicant = state => state.currentApplicant;
const getFutureGuarantor = state => state.future_guarantor;
const getFutureApplicant = state => state.future_applicant;

export default {
    getApplication,
    getcurrentApplicant,
    getApplicationsList,
    getFutureGuarantor,
    getFutureApplicant,
};
