import store from '@/store';

const createPermissionChecker = permissionId => () => store.getters['cbr/hasPermission'](permissionId);

export default [
    {
        routeName: 'cbr.floorplans',
        items: [
            {
                title: 'add new floor plan',
                routeName: 'cbr.floorplans.create',
                params: {},
                allowed: createPermissionChecker('FPC'),
            },
        ],
    },
    {
        routeName: 'cbr.floorplans.details',
        items: [],
    },
];
