<template>
  <auth-card :loading="loading">
    <template v-slot:heading>set up multi-factor authentication for your account</template>
    <template v-slot:default>
<!--      <div class="mfa-options">-->
<!--        <radio-input :options="mfaTypes" v-model="currentMfaType"></radio-input>-->
<!--      </div>-->
      <div v-show="currentMfaType === 'totp'">
        <div class="pb-6 mb-6 border-b">
          <span>1. install an authenticator app on your mobile device</span>
          <Tooltip icon="info" text="Google Authenticator, Duo Mobile or Authy app" class="ml-2">
            <template v-slot:default>
              <div>
                <p class="font-600 mb-2">authenticator apps:</p>
                <div v-for="(group, index) in authenticatorApps" :key="index" >
                  <span class="my-2 block">{{ group.title }}</span>
                  <ul :style="{'list-style': 'square inside'}">
                    <li v-for="(app, liIndex) in group.apps" :key="liIndex">
                      <a :href="app.link" target="_blank">
                        {{ app.title }} <icon name="externalLink" class="w-2 h-2 inline-block" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </template>
          </Tooltip>
        </div>
        <div class="pb-6 mb-6 border-b">
          <div class="mb-2">
            <span>2. use this app to scan the QR code or enter a setup key manually</span>
            <button @click="toggleSecretKey" class="btn-action ml-1" type="button">
              ({{secretTogglerText}} <icon class="w-4 h-4" :class="{'transform rotate-180': secretKeyVisible}" name="chevronDown"/>)
            </button>
          </div>
          <div v-show="secretKeyVisible" class="mb-4">
            <input class="form-input" :value="secretKeyValue" />
          </div>
          <div class="qr-code-wrapper">
            <div class="qr-code" :class="{ 'qr-code--visible': qrCodeVisible}">
              <qrcode-vue :value="qrValue" :size="150" level="Q" />
            </div>
            <div class="qr-toggler">
              <button @click="toggleQrCode" class="btn-primary bg-white" :style="{ backgroundColor: '#ffffff'}" type="button">
                {{qrTogglerText}}
              </button>
            </div>
          </div>
        </div>
        <Form :submit="verifyMFACode" class="w-full" ref="codeform">
          <div class="mb-2">3. please enter the six digit code from your authenticator app</div>
          <div class="mb-4">
            <TextField name="mfacode" label="authenticator code" type="text" :validate="[required, minLength(6)]" autocomplete="off" />
          </div>
          <div>
            <button type="submit" class="btn-primary btn-solid btn-auth">submit</button>
          </div>
        </Form>
      </div>
    </template>
  </auth-card>
</template>

<script>
  import {Auth, Hub} from 'aws-amplify';
  import {AuthState, AUTH_STATE_CHANGE_EVENT, UI_AUTH_CHANNEL} from '@aws-amplify/ui-components';
  import { dispatchToastHubEvent } from '@/components/auth/helpers';
  import QrcodeVue from 'qrcode.vue'
  import {SIGNED_IN} from "@/utils/authEvents";
  import EventBus from "@/utils/EventBus";
  import AuthCard from "@/components/auth/AuthCard";
  import NotifyMixin from "@/mixins/NotifyMixin";
  import ValidatorMixin from "@/components/form/ValidatorMixin";
  import Form from "@/components/form/Form";
  import TextField from "@/components/form/TextField";
  // import RadioInput from "@/components/ui/RadioInput";
  import Tooltip from "@/components/ui/Tooltip";
  import Icon from "@/components/ui/Icon";

  const authenticatorApps = [
    {
      title: 'iOS',
      apps: [
        {
          title: 'Authy',
          link: 'https://apps.apple.com/us/app/authy/id494168017',
        },
        {
          title: 'Duo Mobile',
          link: 'https://apps.apple.com/us/app/duo-mobile/id422663827',
        },
        {
          title: 'Google Authenticator',
          link: 'https://apps.apple.com/us/app/google-authenticator/id388497605',
        },
        {
          title: 'LastPass Authenticator',
          link: 'https://apps.apple.com/us/app/lastpass-authenticator/id1079110004',
        },
        {
          title: 'Microsoft Authenticator',
          link: 'https://apps.apple.com/us/app/microsoft-authenticator/id983156458',
        }
      ]
    },
    {
      title: 'Android',
      apps: [
        {
          title: 'Authy',
          link: 'https://play.google.com/store/apps/details?id=com.authy.authy',
        },
        {
          title: 'Duo Mobile',
          link: 'https://play.google.com/store/apps/details?id=com.duosecurity.duomobile',
        },
        {
          title: 'Google Authenticator',
          link: 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2',
        },
        {
          title: 'LastPass Authenticator',
          link: 'https://play.google.com/store/apps/details?id=com.lastpass.authenticator',
        },
        {
          title: 'Microsoft Authenticator',
          link: 'https://play.google.com/store/apps/details?id=com.azure.authenticator',
        }
      ]
    }
  ];

export default {
  name: "SetupMFA",
  mixins: [NotifyMixin, ValidatorMixin],
  components: {
    Icon,
    QrcodeVue,
    Form,
    TextField,
    AuthCard,
    // RadioInput,
    Tooltip,
  },
  data() {
    return {
      authenticatorApps: authenticatorApps,
      secretKey: '',
      secretKeyVisible: false,
      qrCodeVisible: false,
      loading: false,
      mfaTypes: [
        { value: "totp", label: "set up the authenticator app"},
        { value: "sms", label: "enable sms verification"},
      ],
      currentMfaType: 'totp',
      user: {},
    }
  },
  computed: {
    qrValue: function() {
      if (!this.qrCodeVisible || !this.secretKey || !this.user.username) {
        return '';
      }
      return `otpauth://totp/AWSCognito:${this.user.username}?secret=${this.secretKey}&issuer=Cognito`;
    },
    secretKeyValue: function() {
      if (!this.secretKey) {
        return 'Failed to generate a secret key';
      }
      return this.secretKey;
    },
    qrTogglerText: function() {
      return `${this.qrCodeVisible ? 'hide' : 'show'} qr code`;
    },
    secretTogglerText: function() {
      return `${this.secretKeyVisible ? 'hide' : 'show'} secret code`;
    },
  },
  methods: {
    toggleQrCode() {
      this.qrCodeVisible = !this.qrCodeVisible;
    },

    toggleSecretKey() {
      this.secretKeyVisible = !this.secretKeyVisible;
    },

    generateSecretKey() {
      return Auth.setupTOTP(this.user).then((code) => {
        this.secretKey = code;
      })
    },

    async verifyMFACode({mfacode}) {
      try {
        await Auth.verifyTotpToken(this.user, mfacode);

        await Auth.setPreferredMFA(this.user, 'TOTP');

        EventBus.emit(SIGNED_IN);
      } catch (e) {
        // Token is not verified
        console.log(e);
        dispatchToastHubEvent({ message: e.message === 'Code mismatch' ? 'Invalid code received for user': e.message });
      }
    },

    resetForm() {
      this.$refs.codeform?.restart();
      this.secretKey = '';
      this.secretKeyVisible = false;
      this.qrCodeVisible = false;
    },

  },
  mounted() {
    Hub.listen(UI_AUTH_CHANNEL, ({payload: {event, data, message}}) => {
      if (event === AUTH_STATE_CHANGE_EVENT && message === AuthState.TOTPSetup) {
        this.resetForm();

        this.user = data;

        this.generateSecretKey().catch((e) => { console.error(e); });
      }
    });
  }
}
</script>
<style scoped>
  .qr-code {
    opacity: 0.2;
  }

  .qr-code--visible {
    opacity: 1;
  }

  .qr-toggler {
    @apply absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center;
  }

  .qr-code--visible + .qr-toggler {
    opacity: 0;
  }

  .qr-code-wrapper {
    @apply relative;
    margin: 1.5rem auto 0.5rem;
    width: 150px;

    &:hover > .qr-toggler {
       opacity: 1;
    }

    &:hover > .qr-code {
      opacity: 0.2;
    }
  }
</style>
