export default {
    rentableItems: '/ri',
    assets: {
        getList: ['GET', '/ri/{riId}/assets'],
        create: ['POST', '/ri/{riId}/assets'],
        update: ['PATCH', '/ri/{riId}/assets/{id}'],
        delete: ['DELETE', '/ri/{riId}/assets/{id}'],
        nameCheck: ['POST', '/ri/{riId}/assets/name-check'],
        activate: ['POST', '/ri/{riId}/assets/activate'],
        deactivate: ['POST', '/ri/{riId}/assets/deactivate'],
        bulkDelete: ['POST', '/ri/{riId}/assets/delete'],
    },
    riNameCheck: '/ri/name-check',
    riReservationsToCancel: '/ri/{riId}/count-reservations-to-cancel',
    chargeCodes: '/charge-codes',
    categories: '/categories',
    timeUnits: '/settings/ts/units',
    locations: '/locations',

    buildings: '/buildings',
    units: '/buildings/{buildingId}/units',
    profiles: '/buildings/{buildingId}/units/{unitId}/profiles',

    weekdays: '/community/weekdays',
    communitySchedule: '/community/working-hours',
    communityTimezone: '/community/tz',

    reservations: {
        getList: ['GET', '/ri/{riId}/reservations'],
        create: ['POST', '/ri/{riId}/reservations'],
        getOne: ['GET', '/ri/{riId}/reservations/{id}'],
        cancel: ['POST', '/ri/{riId}/reservations/cancel'],
        availabilityStats: ['POST', '/ri/{riId}/reservations/calc-availability-stats'],
        availableDays: ['GET', '/ri/{riId}/availability/days'],
        availableTime: ['GET', '/ri/{riId}/availability/time'],
    },

    timeline: {
        getAssets: ['GET', '/ri/{riId}/assets/paged'],
        getReservations: ['POST', '/ri/{riId}/assets/filter-reservations'],
    },

    validateReservation: 'ri/{riId}/reservations/validate-on-create',

    permissions: '/my/permissions',
};
