const actions = [
    {
        routeName: 'manage_residents.resident-details',
        items: [
            {
                title: 'print ledger',
                routeName: '',
            },
            {
                title: 'add charge or credit',
                routeName: 'leases.addRecurring',
            },
            {
                title: 'renew lease',
                routeName: 'renewals.editRenewalOffer',
            },
            {
                title: 'transfer resident',
                routeName: 'transfer_resident.index',
            },
            {
                title: 'notice to vacate',
                routeName: '',
            },
            {
                title: 'manage payment settings',
                routeName: '',
            },
        ],
    },
];

export default actions;
