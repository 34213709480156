<!-- eslint-disable vue/no-deprecated-slot-attribute -->
<template>
    <amplify-authenticator username-alias="username" ref="auth">
        <SignIn slot="sign-in" @submitted="({username}) => user = username"/>
        <ForgotPassword slot="forgot-password"/>
        <RequireNewPassword slot="require-new-password"/>
        <ConfirmSignIn slot="confirm-sign-in"/>
        <SetupMFA slot="totp-setup"/>
        <ResetPassword v-if="!authenticated"/>
        <slot v-if="authenticated" v-bind:logout="logout"></slot>
    </amplify-authenticator>
</template>

<script>
import { Auth, Hub } from 'aws-amplify';
import { AUTH_CHANNEL, AUTH_STATE_CHANGE_EVENT, UI_AUTH_CHANNEL, AuthState } from '@aws-amplify/ui-components';
import { difference, isEqual, isEmpty } from 'lodash-es';
import EventBus from '@/utils/EventBus';
import { INIT_PROFILE_UPDATE, SIGNED_IN, SIGNED_OUT } from '@/utils/authEvents';
import AuthMixin from '@/components/auth/AuthMixin';
import PendoMixin from '@/components/auth/PendoMixin';
import NotifyMixin from '@/mixins/NotifyMixin';
import ForgetDeviceMixin from '@/components/auth/ForgetDeviceMixin';
import SignIn from '@/components/auth/custom_auth_flow/SignIn';
import ForgotPassword from '@/components/auth/custom_auth_flow/ForgotPassword';
import RequireNewPassword from '@/components/auth/custom_auth_flow/RequireNewPassword';
import ConfirmSignIn from '@/components/auth/custom_auth_flow/ConfirmSignIn';
import SetupMFA from '@/components/auth/custom_auth_flow/SetupMFA';
import ResetPassword from '@/components/auth/custom_auth_flow/ResetPassword';
import { dispatchAuthStateChangeEvent } from '@/components/auth/helpers';

const REFRESH_INTERVAL = 60000;

export default {
    name: 'Authenticator',
    components: {
        ConfirmSignIn,
        ForgotPassword,
        RequireNewPassword,
        ResetPassword,
        SetupMFA,
        SignIn,
    },
    mixins: [
        AuthMixin,
        PendoMixin,
        NotifyMixin,
        ForgetDeviceMixin,
    ],
    data() {
        return {
            authData: undefined,
            userData: {},
            profilePollingIntervalId: undefined,
            customerAccessPollingIntervalId: undefined,
            signedIn: false, // TODO: a temp workaround for double AuthState.SignedIn message after SignIn
        }
    },
    watch: {
        'profile.customerId': {
            handler: function (val) {
                // For quext admins in case if customer context is selected we need to fetch customer's apps access
                // then refresh it from time to time
                if (this.isQuext) {
                    if (this.customerAccessPollingIntervalId) {
                        clearInterval(this.customerAccessPollingIntervalId);
                    }

                    if (val) {
                        this.getCustomerAppAccess();
                        this.startCustomerAccessPolling();
                    }
                }
            },
            immediate: true,
        },
    },
    methods: {
        async logout() {
            try {
                if (this.profilePollingIntervalId) {
                    clearInterval(this.profilePollingIntervalId);
                }

                if (this.customerAccessPollingIntervalId) {
                    clearInterval(this.customerAccessPollingIntervalId);
                }
                await Auth.signOut();

                this.signedIn = false;
            } catch (error) {
                this.notifyError('error signing out: ', error);
            }
        },
        getProfile() {
            return this.$authDataProvider.get('profile')
                .then(({customerUsers: profiles = []}) => {
                    if (isEmpty(profiles)) {
                        this.notifyError('Your profile is disabled');
                        this.logout();
                        return;
                    }

                    if (!isEqual(this.profiles, profiles)) {
                        this.setProfiles(profiles);
                    }

                    const refreshedProfile = profiles.find(p => p.profileId === this.profile?.profileId) || profiles[0];

                    if (!isEqual(refreshedProfile, this.profile)) {
                        this.setProfile(refreshedProfile);
                    }
                })
                .catch(error => {
                    this.notifyError(error.message);
                });
        },
        getCustomerAppAccess() {
            if (!this.profile?.customerId) {
                return;
            }
            return this.$authDataProvider.getList('customerAppAccesses', {customerId: this.profile.customerId})
                .then(({content}) => {
                    if (
                        (content.length !== this.customerAppCodes.length)
                        || difference(content.map(app => app.appCode), this.customerAppCodes).length > 0
                    ) {
                        this.setCustomerApps(content);
                    }
                })
                .catch((err) => this.notifyError(err));
        },
        startProfilePolling() {
            if (!this.isQuext) {
                this.profilePollingIntervalId = setInterval(this.getProfile, REFRESH_INTERVAL);
            }
        },
        startCustomerAccessPolling() {
            clearInterval(this.customerAccessPollingIntervalId);
            this.customerAccessPollingIntervalId = setInterval(this.getCustomerAppAccess, REFRESH_INTERVAL);
        },
        checkMfaSetupRequired() {
            return this.$authDataProvider.get('profile')
                .then(({mfaSetupRequired}) => {
                    return mfaSetupRequired;
                })
                .catch(error => {
                    this.notifyError(error.message);
                });
        },
    },
    mounted() {
        Hub.listen(AUTH_CHANNEL, ({payload: {event, data}}) => {
            switch (event) {
                case 'signIn':
                    this.userData = data;
                    break;
                case 'signOut':
                    EventBus.emit(SIGNED_OUT);

                    if (this.profilePollingIntervalId) {
                        clearInterval(this.profilePollingIntervalId);
                    }

                    if (this.customerAccessPollingIntervalId) {
                        clearInterval(this.customerAccessPollingIntervalId);
                    }
                    this.signOut();
                    break;
            }
        });

        Hub.listen(UI_AUTH_CHANNEL, ({payload: {data, event, message}}) => {
            // TODO: check double AuthState.SignedIn message
            if (event === AUTH_STATE_CHANGE_EVENT && message === AuthState.SignedIn && !this.signedIn) {
                this.authData = data;
                this.signedIn = true;

                if (!this.authenticated) {
                    this.checkMfaSetupRequired().then((isMfaSetupRequired) => {
                        if (isMfaSetupRequired) {
                            dispatchAuthStateChangeEvent(AuthState.TOTPSetup, data);
                        } else {
                            EventBus.emit(SIGNED_IN);
                        }
                    });
                }
            }
        });

        if (this.authenticated) {
            this.startProfilePolling();

            if (this.isQuext && this.profile?.customerId) {
                this.startCustomerAccessPolling();
            }

            // Initialize Pendo session
            this.initializePendoVisitor();
        } else {
            Auth.currentAuthenticatedUser()
                .then(() => {
                    this.logout();
                })
                .catch(() => {
                });
        }

        EventBus.on(SIGNED_IN, () => {
            this.getProfile().finally(() => {
                Auth.currentAuthenticatedUser()
                    .then(({username, attributes}) => {
                        return this.signIn({
                            ...this.userData.attributes,
                            ...attributes,
                            username,
                        })
                    })
                    .then(() => {
                        // We need to refresh customer user profile info to prevent access ASAP when customer is removed
                        this.startProfilePolling();
                        // Initialize Pendo session
                        this.initializePendoVisitor();
                    })
                    .catch(error => {
                        console.error(error.message);
                    });
            });
            this.processUserDevice();
        });

        EventBus.on(INIT_PROFILE_UPDATE, () => {
            this.getProfile();
        });
    },
    beforeUnmount() {
        clearInterval(this.customerAccessPollingIntervalId);
        clearInterval(this.profilePollingIntervalId);
    },
};
</script>

<style scoped>
amplify-authenticator {
    --amplify-primary-color: #186EA8;
    --amplify-secondary-tint: #186EA8;
    --amplify-primary-shade: #2A4365;
    --amplify-primary-tint: #105D91;
}
</style>
