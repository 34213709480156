export default {
    //GET
    getAllLeases: 'lease-phase',
    getAllAccounting: 'accounting',
    getAllOccupancies: 'occupancy/business/{business_id}?page={page}&page_limit={limit}',
    getOccupancy: 'occupancy',
    getRentalInsuranceStatusAndType: 'rental-insurance/list-status-and-type',
    document_package_instance: {
        create: ['POST', 'document/document-package-instance'],
        patch: ['PATCH', 'document/document-package-instance/{document_package_instance_id}/{business_unit_id}/{customer_id}'],
    },
    document_event: 'document/document-event',
    getPetParty: 'pet/party/{party_id}',
    getVehicleParty: 'vehicle/party/{party_id}',
    getCustomTagParty: 'party-custom-tag/party/{party_id}',
    getPendingMoveIns: 'move-in/list-pending',

    //POST
    createRentalInsurance: 'rental-insurance',

    partner: {
        create: ['POST', 'document/partner'],
        getOne: ['GET', 'document/partner/{partner_id}'],
        getList: ['GET', 'document/partner'],
        delete: ['DELETE', 'document/partner/{partner_id}'],
    },
    document_field_map: {
        create: ['POST', '/document/partner-document/document-field-map/{partner_id}/{partner_document_id}'],
    },
    partner_document: {
        create: ['POST', '/document/partner-document'],
        getOne: ['GET', '/document/partner-document/{partner_id}/{partner_document_id}'],
        getList: ['GET', '/document/partner-document'],
    },
    document_category: {
        create: ['POST', '/document/document-category'],
        getOne: ['GET', '/document/document-category/{document_category_name}'],
        getList: ['GET', '/document/document-category'],
    },
    partner_field: {
        create: ['POST', '/document/partner-field'],
        getList: ['GET', '/document/partner-field'],
        patch: ['PATCH', '/document/partner-field/{partner_id}/{partner_field_name}'],
        getOne: ['GET', '/document/partner-field/{partner_id}/{partner_field_name}'],
        delete: ['DELETE', '/document/partner-field/{partner_id}/{partner_field_name}'],
    },
    quext_field: {
        create: ['POST', '/document/quext-field'],
        getList: ['GET', '/document/quext-field'],
        patch: ['PATCH', '/document/quext-field/{quext_field_name}/{quext_field_data_type}'],
        getOne: ['GET', '/document/quext-field/{quext_field_name}/{quext_field_data_type}'],
        delete: ['DELETE', '/document/quext-field/{quext_field_name}/{quext_field_data_type}'],
    },
    quext_field_concept: '/document/quext-field-includes-quext-concept',
    quext_concept: {
        create: ['POST', '/document/quext-concept'],
        getList: ['GET', '/document/quext-concept'],
        patch: ['PATCH', '/document/quext-concept/{quext_concept_name}'],
        getOne: ['GET', '/document/quext-concept/{quext_concept_name}'],
        delete: ['DELETE', '/document/quext-concept/{quext_concept_name}'],
    },
    field_translation: {
        create: ['POST', '/document/field-translation'],
        patch: ['PATCH', '/document/field-translation/{partner_id}/{partner_field_name}/{quext_field_name}'],
        delete: ['DELETE', '/document/field-translation/{partner_id}/{partner_field_name}/{quext_field_name}'],
    },

    purposes: '/purposes',
    purposeCategories: '/purpose-category',
    partnersPurposes: '/partners/{partnerId}/purposes',
    communityPartners: '/community/{communityId}/partners',
    communityPurposes: '/community/{communityId}/purposes',
};
