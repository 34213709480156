import store from '@/store';

export default [
    {
        routeName: 'sreq.index',
        items: [
            {
                title: 'add new request',
                routeName: 'sreq.create',
                params: {},
                allowed: () => store.getters['sreq/hasPermission']('SRC'),
            },
        ],
    },
    {
        routeName: 'sreq.create',
        items: [],
    },
    {
        routeName: 'sreq.details',
        items: [
            {
                title: 'print',
                routeName: 'sreq.print.single',
                params: {
                    requestId: '{requestId}',
                },
                allowed: () => store.getters['sreq/hasPermission']('SRPr'),
            },
            {
                title: 'view feed',
                routeName: 'sreq.feed',
                params: {
                    requestId: '{requestId}',
                },
            },
            {
                title: 'add new in/out time',
                routeName: 'sreq.timing.notes',
                params: {
                    requestId: '{requestId}',
                },
            },
        ],
    },
    {
        routeName: 'sreq.print.single',
        items: [],
    },
    {
        routeName: 'sreq.settings.community',
        items: [
            {
                title: 'apply settings to other communities',
                routeName: 'sreq.settings.community.bulk',
                params: {},
                allowed: () => store.getters['sreq/hasPermission']('CSM'),
            },
        ],
    },
];
