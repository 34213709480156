import {get} from "lodash-es";
import {i18n} from "@/i18n";
import {parsePhoneNumberFromString} from "libphonenumber-js";
import {
  getScheduleIntersection,
  lessThanTime,
  equalTime,
} from "@/utils/Schedule";

export const composeValidators =
    (...validators) =>
        (value, values, meta) =>
            validators.reduce((acc, validator) => acc || validator(value, values, meta), undefined);

export const triggerValidator =
(triggerField, triggerValue, validator) =>
    (value, values, meta) =>
        get(values, triggerField) === triggerValue ? validator(value, values, meta) : undefined;

const isValid = (value, validator) => !validator(value);

export const required = value =>
    (value || typeof value === 'number' ? undefined : i18n.global.t('validation.required'));

export const notEmpty = value =>
    value && value.length > 0 ? undefined : i18n.global.t('validation.required');

export const notEmptyRange = values =>
    (values?.[0] || values?.[0] === 0) && (values?.[1] || values?.[1] === 0) ? undefined : i18n.global.t('validation.required');

export const email = value =>
    value && !/^[a-zA-Z0-9](?:[a-zA-Z0-9._+%!#$&'*/=?^`{|}~-]{0,62}[a-zA-Z0-9])?@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i.test(value)
        ? i18n.global.t('validation.email')
        : undefined;

export const uuid = value =>
    value && !/^[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$/.test(value)
        ? 'invalid id format'
        : undefined;

export const maxLength = max => value =>
    value && value.length > max ? i18n.global.t(Array.isArray(value) ? 'validation.max_array_length' : 'validation.max_length', {max}) : undefined;

export const minLength = min => value =>
    value && value.length < min ? i18n.global.t(Array.isArray(value) ? 'validation.min_array_length' : 'validation.min_length', {min}) : undefined;

export const maxHMTLTextContentLength = max => value => {
    const el = document.createElement('span');
    el.innerHTML = value;
    return el.textContent.length > max ? `Must be ${max} characters or less` : undefined;
};

export const minHMTLTextContentLength = min => value => {
    const el = document.createElement('span');
    el.innerHTML = value;
    return el.textContent.length < min ? `Must be ${min} characters or more` : undefined;
};

export const alphaNumeric = value =>
    value && /[^a-zA-Z0-9 ]/i.test(value)
        ? i18n.global.t('validation.alphanumeric')
        : undefined;

export const alphaNumericKey = value =>
    value && /[^a-zA-Z0-9_.]/i.test(value)
        ? 'Only alphanumeric characters, . and _'
        : undefined;

export const phoneNumber = value =>
    value && !parsePhoneNumberFromString(value)?.isPossible()
        ? i18n.global.t('validation.phone')
        : undefined;

export const countryPhoneNumber = countryCode => value =>
    value && !parsePhoneNumberFromString(value, countryCode)?.isPossible()
        ? i18n.global.t('validation.phone')
        : undefined;

export const usPhoneNumber = value =>
    value && !/^(\+1[0-9]{10})$/i.test(value)
        ? i18n.global.t('validation.us_phone')
        : undefined;

export const noSpaces = value =>
    value && /\s/.test(value)
        ? i18n.global.t('validation.no_spaces')
        : undefined;

export const oneTrueRequired = groupName =>
    (value, values) => {
        if (!values[groupName]) {
            return i18n.global.t('validation.selected');
        }

        let oneTrue = false;

        for (const val of Object.values(values[groupName])) {
            if (val) {
                oneTrue = true;
            }
        }

        return oneTrue ? undefined : i18n.global.t('validation.selected');
    };

export const safeUrl = value =>
    value && !/^http/.test(value)
        ? i18n.global.t('validation.safe_url')
        : undefined;

export const number = value =>
    (value != null && isNaN(value) ? i18n.global.t('validation.number') : undefined);

export const naturalNumber = value =>
    (!isNaN(value) && value % 1 === 0 ? undefined : i18n.global.t('validation.natural'));

export const minValue = min => value =>
    (!isNaN(value) && value >= min ? undefined : i18n.global.t('validation.min_value', {min}));

export const maxValue = max => value =>
    (!isNaN(value) && value <= max ? undefined : i18n.global.t('validation.max_value', {max}));

export const minDateTime = (min, formatter = value => value.toLocaleString()) => value => {
  if (!value) return undefined;
  const minDate = new Date(min);
  return minDate <= (new Date(value)) ?
    undefined :
    i18n.global.t('validation.min_datetime', {min: formatter(minDate)});
}

export const maxDateTime = (max, formatter = value => value.toLocaleString()) => value => {
  if (!value) return undefined;
  const maxDate = new Date(max);
  return maxDate >= (new Date(value)) ?
    undefined :
    i18n.global.t('validation.max_datetime', {max: formatter(maxDate)});
}

/**
 *
 * @param min minimum duration in minutes
 * @returns {function(*): undefined|string}
 */
export const minDuration = min => value => {
  return (!isNaN(value) && value >= min ? undefined : i18n.global.t('validation.min_duration', {
    hours: Math.floor(min / 60),
    minutes: min % 60,
  }));
}

/**
 *
 * @param max maximum duration in minutes
 * @returns {function(*): undefined|string}
 */
export const maxDuration = max => value =>
  (!isNaN(value) && value <= max ? undefined : i18n.global.t('validation.max_duration', {
    hours: Math.floor(max / 60),
    minutes: max % 60,
  }));

export const maxDecimalPlaces = max => value =>
    (value.toString().split('.')?.[1]?.length > max ? i18n.global.t('validation.max_decimal_places', {max}) : undefined);

export const numbersRange = values => {
    const min = +values?.[0] || 0;
    const max = +values?.[1] || 0;

    return min <= max ? undefined : 'Please specify minimum value less than maximum value';
};

export const rangeStartMin = min => values => {
  return min <= +values?.[0] ? undefined : i18n.global.t('validation.min_value', {min});
};

export const amountNotEmpty = precision => value => {
    const divider = Math.pow(10, precision);
    const digits = String(value).replace(/\D+/g, '') || '0';

    return (parseFloat(digits) / divider).toFixed(precision) > 0 ? undefined : i18n.global.t('validation.required');
};

export const validTime = (value) => {
  const invalidIndexes = value.reduce((acc, {openTime, closeTime, working24}, index) => {
    if(!working24 && lessThanTime(closeTime, openTime)){
      acc.push(index);
    }

    return acc;
  }, []);

  return invalidIndexes.length ? {message: i18n.global.t('validation.invalid_time'), rows: invalidIndexes} : undefined;
}

export const notEqualTime = (value) => {
  const invalidIndexes = value.reduce((acc, {openTime, closeTime, working24}, index) => {
    if(!working24 && equalTime(openTime, closeTime)){
      acc.push(index);
    }

    return acc;
  }, []);

  return invalidIndexes.length ? { message: i18n.global.t('validation.equal_time'), rows: invalidIndexes } : undefined;
}

export const notEmptySchedule = (value) => {
  return value.length === 0 ? i18n.global.t('validation.empty_schedule') : undefined;
}

export const validSchedule = (value) => {
  const intersection = getScheduleIntersection(value);

  return intersection ? { message: i18n.global.t('validation.invalid_schedule'), rows: intersection } : undefined;
}

export const cognitoUsername = value => {
    if (isValid(value, email)) {
        return undefined;
    }

    if (isValid(value, countryPhoneNumber('US'))) {
        return undefined;
    }

    const valueToTest = value[0] === '+' ? value : `+${value}`;
    return isValid(valueToTest, phoneNumber)
        ? undefined
        : i18n.global.t('validation.cognitoUsername');
};
