export default {
    quextUsers: '/profiles/super-admins',
    quextUsersDeactivate: '/profiles/super-admins/{profileId}/deactivate',
    quextUsersReactivate: '/profiles/super-admins/{profileId}/reactivate',
    quextUsersRoles: '/roles/super-admins',
    apps: '/apps',
    allApps: '/apps/all',
    appEndpointStatus: '/apps/api-endpoint-status',
    appCustomers: '/apps/{appId}/accesses',
    appCustomerUsers: '/apps/{appId}/accesses/{appAccessId}/profiles',
    appCustomersRevoke: '/apps/{appId}/accesses/{appAccessId}/revoke',
    appCustomersRestore: '/apps/{appId}/accesses/{appAccessId}/restore',
    appPermissions: '/apps/{appId}/permissions',
    appRoles: '/apps/{appId}/roles',
    appSync: '/apps/{appId}/sync',
    appSyncResult: '/apps/syncs/{syncId}',
    bundles: '/bundles',
    bundleUsage: '/bundles/{bundleId}/usages',
    bundleMetaroleUsage: 'bundles/{bundleId}/metaroles/{metaroleId}/customer-profiles',
    customers: '/customers',
    communities: '/communities',
    communitiesImport: {
        create: ['POST', '/customers/{customerId}/import-communities'],
    },
    communitiesValidateImportFile: {
        create: ['POST', '/customers/{customerId}/import-communities/validate-file'],
    },
    communitiesImportStatus: {
        get: ['GET', '/customers/{customerId}/import-communities/{importId}'],
    },
    communitiesImportTemplate: {
        get: ['GET', '/import-communities/download-example-file'],
    },
    communityLicensedAppAccess: {
        get: ['GET', '/customers/{customerId}/communities/{communityId}/app-accesses'],
    },
    customerCommunities: '/customers/{customerId}/communities',
    customerApps: '/customers/{customerId}/authorized-apps',
    customerRoles: '/customers/{customerId}/roles',
    customerAuthRoles: '/roles/customers',
    customerProfiles: '/customers/{customerId}/customer-profiles',
    customerUsers: '/customers/{customerId}/customer-users',
    customerDeletedUsers: '/profile-snapshots',
    customerAdmins: '/customers/{customerId}/customer-users/admins',
    customerAccesses: '/customers/{customerId}/accesses',
    customerAppAccesses: '/customers/{customerId}/app-accesses',
    customerLicensing: {
        update: ['POST', '/customers/{customerId}/licensed-communities'],
    },
    customerPermissions: '/customers/{customerId}/permissions',
    customerRoleDetails: '/customers/{customerId}/roles/{roleId}',
    synchronizeRoles: '/customers/{customerId}/roles/synchronization',
    synchronizePermissions: '/customers/{customerId}/permissions/synchronization',
    profile: '/profile',
    timezones: '/timezones',
    userCommunities: '/my/communities',
    reInviteCustomerUser: '/customers/{customerId}/customer-users/{profileId}/re-invite',
    reInviteQuextUser: '/profiles/super-admins/{id}/re-invite',
    resetCustomerUserPassword: '/customers/{customerId}/customer-users/{profileId}/reset-password',
    resetQuextUserMfa: {
        create: ['POST', '/profiles/super-admins/{id}/reset-mfa'],
    },
    resetCustomerUserMfa: {
        create: ['POST', '/customers/{customerId}/customer-profiles/{profileId}/reset-mfa'],
    },
    managementRoles: '/communities/management-roles',
    tourSchedulingTypes: '/communities/tour-scheduling-types',
    weekdays: '/communities/weekdays',
};
