export const LOAD_IMAGES = 'LOAD_IMAGES';
export const SET_IMAGES = 'SET_IMAGES';
export const SET_EDIT_IMAGE_URL = 'SET_EDIT_IMAGE_URL';
export const SET_ID_IMAGE_TO_EDIT = 'SET_ID_IMAGE_TO_EDIT';
export const SET_IMAGE_DESCRIPTION = 'SET_IMAGE_DESCRIPTION';
export const SET_IS_FROM_EDIT_PHOTO = 'SET_IS_FROM_EDIT_PHOTO';
export const SET_LOADING = 'SET_LOADING';
export const SET_PHOTO_TAGS = 'SET_PHOTO_TAGS';
export const SET_TAGS_VALUE = 'SET_TAGS_VALUE';
export const CLEAN_STATE = 'CLEAN_STATE';

export default {
    [LOAD_IMAGES]: (state, payload) => (state.images = payload),
    [SET_IMAGES]: (state, payload) => (state.images = payload),
    [SET_EDIT_IMAGE_URL]: (state, payload) => (state.editImageUrl = payload),
    [SET_ID_IMAGE_TO_EDIT]: (state, payload) => (state.idImageToEdit = payload),
    [SET_IMAGE_DESCRIPTION]: (state, payload) => (state.imageDescription = payload),
    [SET_IS_FROM_EDIT_PHOTO]: (state, payload) => (state.isFromEditPhoto = payload),
    [SET_PHOTO_TAGS]: (state, payload) => (state.photoTags = payload),
    [SET_TAGS_VALUE]: (state, payload) => (state.tagsValue = payload),
    [SET_LOADING]: (state, payload) => (state.loading = payload),
    [CLEAN_STATE]: (state, payload) => {
        switch (payload) {
        case 'images':
            state.images = [];
            break;
        case 'currentIndex':
            state.currentIndex = 0;
            break;
        default:
            state.images = [];
            state.currentIndex = 0;
        }
    },
};
