const persistedValues = state => state.persistedValues;

const hasPermission = (state, getters, rootState, rootGetters) => permissionIds => {
    const allPermissions = rootGetters['auth/getAppPermissions']('RI');

    if (!allPermissions) {
        return false;
    }

    const community = rootGetters['auth/community'];

    if (!community) {
        return false;
    }

    const communityPermissions =
        allPermissions.find(({ communityId }) => communityId === community.id) ||
        allPermissions.find(({ communityId }) => communityId === null);

    if (!communityPermissions) {
        return false;
    }

    const permissionSet = new Set(communityPermissions.permissions.map(({ id }) => id));

    return (Array.isArray(permissionIds) ? permissionIds : [permissionIds]).some(id => permissionSet.has(id));
};

export default {
    persistedValues,
    hasPermission,
};
