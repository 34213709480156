import DataProvider from '@/api/DataProvider.js';
import HttpAdapter from '@/api/adapters/HttpAdapter';
import resources from './resources';
import httpClient from '@/api/httpClient';
import getEnvVariable from '@/utils/getEnvVariable';

const baseURL = getEnvVariable('VUE_APP_LEASING_SERVICE_URL');

const actions = ['get', 'getOne', 'getList', 'create'];

const moveInResources = ['getAllLeases', 'getAllAccounting', 'getOccupancy'];

const beforeRequestInterceptor = ({ requestConfig, action, resource }) => {
    if (actions.includes(action) && moveInResources.includes(resource)) {
        return {
            ...requestConfig,
            responseType: requestConfig.params.responseType,
            headers: { ...requestConfig.params.headers },
        };
    }

    return requestConfig;
};

const httpAdapter = new HttpAdapter(httpClient(baseURL), resources, beforeRequestInterceptor, ({ response }) => response);

export default new DataProvider(httpAdapter);
