import store from '@/store';

const createPermissionChecker = permissionId => () => store.getters['amnt/hasPermission'](permissionId);

export default [
    {
        routeName: 'cbr.living.units.details',
        items: [
            {
                title: 'manage amenities',
                routeName: 'cbr.living.units.amenities',
                params: {},
                allowed: createPermissionChecker('UL_V'),
            },
        ],
    },
    {
        routeName: 'cbr.living.units.amenities',
        items: [
            {
                title: 'manage amenity configuration',
                routeName: 'cbr.living.units.assignment',
                params: {},
                allowed: createPermissionChecker('CL_UA'),
            },
        ],
    },
    {
        routeName: 'cbr.living.units.amenities.details',
        items: [],
    },
    {
        routeName: 'cbr.living.units.groups.details',
        items: [],
    },
];
