import store from '@/store';

const createPermissionChecker = permissionId => () => store.getters['cbr/hasPermission'](permissionId);

export default [
    {
        routeName: 'cbr.living.buildings',
        items: [
            {
                title: 'add new building',
                routeName: 'cbr.living.buildings.create',
                params: {},
                allowed: createPermissionChecker('LSC'),
            },
        ],
    },
    {
        routeName: 'cbr.living.units',
        items: [
            {
                title: 'add new unit',
                routeName: 'cbr.living.units.create',
                params: {},
                allowed: createPermissionChecker('LSC'),
            },
        ],
    },
];
