<template>
    <div class="relative" :class="{'flex items-center' : flexHeader === true }">
        <span :class="disabled ? 'cursor-default' : 'cursor-pointer'" @click="toggleMenu">
            <slot name="header">Menu</slot>
        </span>
        <nav
            class="drop-menu"
            :class="{[direction]: true, 'p-4': !removePadding, 'rounded': rounded}"
            v-show="isOpen"
            :style="'overflow: ' + overflow + ';min-width:' + minWidth"
        >
            <slot name="default" :close="close" />
        </nav>
    </div>
</template>

<script>
    import EventBus from "@/utils/EventBus";

    export default {
        props: {
            direction: {
                type: String,
                required: false,
                default: 'left'
            },
            overflow: {
                type: String,
                default: 'auto',
                required: false
            },
            minWidth: {
                type: String,
                required: false,
                default: '13.85rem'
            },
            closeOnOutsideClick: {
                type: Boolean,
                required: false,
                default: true
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            flexHeader: {
                type: Boolean,
                required: false,
                default: false,
            },
            removePadding: {
                type: Boolean,
                required: false,
                default: false,
            },
            rounded: {
                type: Boolean,
                default: true
            }
        },
        data: () => {
            return {
                isOpen: false,
            }
        },
        methods: {
            toggleMenu(e) {
                this.isOpen = !this.disabled && !this.isOpen;
                EventBus.emit('dropdown-menu-toggle', e);
                e.stopPropagation();
            },
            handleOutsideClickEvent(e) {
                if (this.$el.contains(e.target)) {
                    return;
                }
                this.close();
            },
            close() {
                this.isOpen = false;
            }
        },
        mounted() {
            if(this.closeOnOutsideClick) {
                EventBus.on('dropdown-menu-toggle', this.handleOutsideClickEvent);
                window.addEventListener('click', this.handleOutsideClickEvent);
            }
        }
    }
</script>

<style scoped>
    .drop-menu {
        box-shadow: 0 0.5rem 1rem rgba(222, 224, 228, 0.5);
        z-index: 9999;
        max-height: 60vh;
        overflow-y: auto;
        top: 100%;
        @apply bg-white absolute left-0 text-left border z-20;
    }
    .drop-menu.right {
        right: 0;
        left: auto;
    }
    .drop-menu.open {
        display: block;
    }
    .drop-menu.top {
      bottom: 100%;
      top: auto;
    }
</style>
