<template>
  <div class="collapsible-tags">
    <template v-if="items.length">
      <div ref="wrapper" class="collapsible-tags__tags">
        <Tag
            ref="tags"
            v-for="(appliedFilter, index) of items"
            :key="index"
            :text="appliedFilter.text"
            :deletable="appliedFilter.clearable && !readonly"
            @click="$emit('delete', appliedFilter)"
            :title="appliedFilter.text"
            :disabled="disabled"
        />

        <Tag
            v-if="itemsToHideCount > 0"
            @click="toggleExpand"
            :disabled="disabled"
            :interactive="expandable"
        >
          <template v-if="!expanded">
            +{{ itemsToHideCount }}
          </template>
          <template v-else>
            <ChevronLeftIcon class="h-5"/>
          </template>
        </Tag>
      </div>
    </template>

    <template v-else>
      <slot name="empty-placeholder">
        <Tag
            text="all"
            :deletable="false"
            class="tag"
            title="all"
        />
      </slot>
    </template>
  </div>
</template>

<script>
import Tag from "@/components/ui/Tag";
import ChevronLeftIcon from "@/components/ui/icons/ChevronLeftIcon";

export default {
  components: {ChevronLeftIcon, Tag},

  props: {
    /**
     * Tag items to display. The format is:
     * ```
     * {
     *     text: '...',
     *     clearable: true,
     * }
     * ```
     *
     * `text` is required and `clearable` is optional here
     */
    items: {
      type: Array,
      required: true,
    },

    /**
     * Maximum number of rows visible in collapsed state
     */
    maxRows: {
      type: Number,
      default: 2,
    },

    /**
     * If tags can be deletable
     */
    readonly: {
      type: Boolean,
      default: false,
    },

    /**
     * Allow to expand cropped items
     */
    expandable: {
      type: Boolean,
      default: false,
    },

    /**
     * Disabled state
     */
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: [
    'delete'
  ],

  data() {
    return {
      expanded: false,
      itemsToHideCount: 0,
      resizeObserver: null,
    };
  },

  computed: {
    maxHeight() {
      // 30 is a tag height with bottom margin. Subtract 4 to eliminate last row margin
      return this.maxRows * 30 - 4;
    },
  },

  watch: {
    items: {
      handler() {
        this.showAllTags();
        this.$nextTick(() => {
          if (!this.$refs.tags) return;

          if (this.expanded) {
            this.showAllTags();
          } else {
            this.hideExtraTags();
          }
        });
      },
      immediate: true,
      deep: true,
    },

    expanded(value) {
      if (value) {
        this.showAllTags();
      } else {
        this.hideExtraTags();
      }
    },
  },

  methods: {
    toggleExpand() {
      this.expanded = !this.expanded;
    },

    hideExtraTags() {
      if (!this.$refs.tags) return;

      const { tags } = this.$refs;
      let firstIndexToHide = tags.findIndex(({ $el }) => $el.offsetTop > this.maxHeight);
      // width of +n button with big number (just in case)
      const REST_BTN_WIDTH = 40;
      // right margin
      const REST_BTN_MARGIN = 4;

      if (firstIndexToHide < 0 && !this.expanded) {
        firstIndexToHide = this.getFirstHiddenIndex();
      }

      if (firstIndexToHide >= 0) {
        const lastToShow = tags[firstIndexToHide - 1].$el;
        const placeAtRight = lastToShow.offsetParent.clientWidth - lastToShow.offsetLeft - lastToShow.offsetWidth - REST_BTN_MARGIN;
        if (placeAtRight < REST_BTN_WIDTH) {
          // hide one more tag to fit "+n" button
          firstIndexToHide -= 1;
        }

        for (const index in tags) {
          tags[index].$el.classList[index < firstIndexToHide ? 'remove' : 'add']('hidden');
        }
      } else {
        this.showAllTags();
      }

      this.updateHiddenCount();
    },

    showAllTags() {
      if (!this.$refs.tags) return;

      for (const tag of this.$refs.tags) {
        tag.$el.classList.remove('hidden');
      }
    },

    updateExtraTags() {
      this.showAllTags();
      this.hideExtraTags();
    },

    updateHiddenCount() {
      const firstHiddenIndex = this.getFirstHiddenIndex();

      this.itemsToHideCount = firstHiddenIndex >= 0 ? this.items.length - firstHiddenIndex : 0;
    },

    getFirstHiddenIndex() {
      return this.$refs.tags.findIndex(({ $el }) => $el.classList.contains('hidden'));
    },

    initResizeObserver(){
      this.resizeObserver = new ResizeObserver(() => {
        if (this.expanded) {
          return;
        }
  
        this.updateExtraTags();
      })
  
      this.resizeObserver.observe(this.$refs.wrapper);
    },

    destroyResizeObserver(){
      if(this.resizeObserver){
        this.resizeObserver.disconnect();
        this.resizeObserver = null;
      }
    },
  },

  mounted() {
    if(this.$refs.wrapper){
      this.initResizeObserver();
    }
  },

  beforeUnmount() {
    this.destroyResizeObserver();
  }
}
</script>

<style scoped>
.collapsible-tags {
  @apply flex;
}

.collapsible-tags__tags {
  @apply flex flex-wrap overflow-hidden relative flex-grow;
}

.tag {
  @apply lowercase mb-1;
  max-width: 150px;

  &.hidden {
    @apply hidden;
  }
}
</style>
