<template>
  <div>
    <ContextSelect
        v-model="customerCommunity"
        ref="communitySelector"
        class="community-context-selector"
        :options="options"
        trackBy="id"
        label="name"
        placeholder="select community"
        :disabled="disabled"
        :no-clear="!community"
    >
      <template v-slot:empty-result>
        {{options.length === 0 ? 'No communities available' : 'No results found matching your search'}}
      </template>
    </ContextSelect>
  </div>
</template>

<script>
import {mapActions} from 'vuex';
import AuthMixin from '@/components/auth/AuthMixin';
import NotifyMixin from '@/mixins/NotifyMixin';
import ContextSelect from '@/components/auth/context_select/ContextSelect';

export default {
    name: 'CommunitySelect',
    components: {
      ContextSelect,
    },
    mixins: [AuthMixin, NotifyMixin],
    props: {
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    data() {
        return {
            customerCommunity: null,
            communitiesUnsubscribe: null,
            options: []
        }
    },
    watch: {
        'profile.customerId': function (val) {
            if (val) {
                this.fetchCommunities();
            } else {
                this.options = [];
                this.customerCommunity = null;
            }
        },
        community: {
          handler: function (community) {
            if (community) {
              this.fetchCommunityAppCodes(community.id);
            } else {
              this.setCustomerCommunityAppCodes([]);
            }
          }
        },
        customerCommunity(value = null) {
          this.changeCommunity(value);
          this.$refs.communitySelector.search = null;
        },
    },
    methods: {
        ...mapActions({
            clearLibrary: 'files/clearLibrary',
            loadFilesLibraryInStore: 'files/loadLibrary'
        }),
        async fetchCommunities() {
            try {
                const response = await this.$authDataProvider.get('userCommunities');
                this.options = response.content.map(({id, name, timezoneId}) => ({id, name, timezoneId}));

                this.setCommunities(this.options);

                if (this.options.length === 1) {
                    this.customerCommunity = this.options[0];
                } else {
                  if (this.community?.id && this.options.find(({id}) => id === this.community.id)) {
                    this.customerCommunity = this.community;
                  } else {
                    this.customerCommunity = null;
                  }
                }
            } catch (e) {
                this.notifyError('Failed to fetch data for communities selector');
            }
        },
        async fetchCommunityAppCodes(communityId) {
          try {
            const response = await this.$authDataProvider.get('communityLicensedAppAccess', {
              customerId: this.profile.customerId,
              communityId,
            });

            this.setCustomerCommunityAppCodes(response.content.map(app => app.appCode));
          } catch (e) {
            this.notifyError('Failed to fetch applications authorized for the selected community');
          }
        },
        fetchCommunityFilesLibrary() {
            this.$filemanagerDataProvider.get('communityFiles', { communityId: this.community?.id })
                .then( (response) => {
                    let files = [];
                    response.forEach( file => {
                        files.push({
                            file_uuid: file.file_uuid,
                            base_directory: file.base_directory,
                            community_id: file.community_id,
                            original_name: file.file_name,
                            file_url: file.file_url,
                            thumbnail_url: file.thumbnail_url,
                            file_type: file.file_type,
                            file_size: file.file_size,
                            created_at: file.created_at,
                            updated_at: file.updated_at,
                            tags: [],
                        });
                    });
                    this.loadFilesLibraryInStore(files);
                })
        },
        changeCommunity(community) {
            this.setCommunity(community);
            (community) ? this.fetchCommunityFilesLibrary() : this.clearLibrary();
        },
    },
    mounted() {
        if (this.profile.customerId) {
            this.fetchCommunities();
        }
    },
}
</script>
<style scoped>
.community-context-selector {
  &:deep(.context-selector__input) {
     @apply font-700;

    &::placeholder {
       @apply font-700;
     }
  }
}
</style>