import store from '@/store';

const permissionChecker = () => store.getters['msgs/hasPermission'];

export default [
    {
        path: '/messaging',
        name: 'msgs.index',
        component: () => import(/* webpackChunkName: "residentMessaging" */ '@/views/msgs/Index'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'resident messaging',
            title: 'resident messaging',
            flowStartsWith: 'msgs.index',
            product: 'msgs',
            permissionChecker,
        },
    },
    {
        path: '/messaging/create',
        name: 'msgs.create',
        component: () => import(/* webpackChunkName: "residentMessaging" */ '@/views/msgs/Create'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'add new',
            title: 'add new message',
            flowStartsWith: 'msgs.index',
            product: 'msgs',
            permissionChecker,
        },
    },
    {
        path: '/messaging/:messagingId',
        name: 'msgs.details',
        component: () => import(/* webpackChunkName: "residentMessaging" */ '@/views/msgs/Details'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'message',
            title: 'message',
            flowStartsWith: 'msgs.index',
            product: 'msgs',
            permissionChecker,
        },
    },
    {
        path: '/messaging/:messagingId/duplicate',
        name: 'msgs.duplicate',
        component: () => import(/* webpackChunkName: "residentMessaging" */ '@/views/msgs/Create'),
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'duplicate',
            title: 'duplicate message',
            flowStartsWith: 'msgs.index',
            product: 'msgs',
            permissionChecker,
        },
    },
];
