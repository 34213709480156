export const SET_LEASES = 'SET_LEASES';
export const SET_LEASE = 'SET_LEASE';

export default {
    [SET_LEASES]: (state, payload) => {
        state.leases = payload;
    },
    [SET_LEASE]: (state, payload) => {
        state.lease = payload;
    },
};
