<template>
  <div class="auth-wrapper">
    <BeatLoader :loading="true" />
  </div>
</template>
<script>
  import {AuthState} from '@aws-amplify/ui-components/dist/collection/common/types/auth-types';
  import {
    dispatchAuthStateChangeEvent,
  } from '@aws-amplify/ui-components/dist/collection/common/helpers';
  import BeatLoader from "@/components/ui/BeatLoader";

  export default {
    name: 'ResetPassword',
    components: { BeatLoader },
    watch: {
      $route(to) {
        if (to.path === "/reset-password") {
          this.redirectToResetPassword(to.query?.username);
        }
      }
    },
    methods: {
      redirectToResetPassword(username) {
        dispatchAuthStateChangeEvent(AuthState.ForgotPassword, { codeSent: true, username });
      }
    },
    render: function () {
      return null;
    }
  };
</script>
