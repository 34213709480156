const notifySuccess = ({ commit }, payload) => {
    commit('NOTIFY_SUCCESS', payload);
};

const notifySuccessBack = ({ commit }, payload) => {
    commit('NOTIFY_SUCCESS_BACK', payload);
};

const notifyError = ({ commit }, payload) => {
    commit('NOTIFY_ERROR', payload);
};

const reset = ({ commit }) => {
    commit('RESET_ALERT');
};

export default {
    notifySuccess,
    notifySuccessBack,
    notifyError,
    reset,
};
