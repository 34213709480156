import { mapActions, mapGetters } from 'vuex';
import { isEqual } from 'lodash-es';
import Queue from 'queue-promise';
import permissionFetchers from '@/api/permission_fetchers';

const REFRESH_INTERVAL = 5 * 60 * 1000;

export default {
    data() {
        return {
            fetchAppPermissionsIntervalId: undefined,

            // use queue for permission Promises to prevent requests from racing
            permissionsPromisesQueue: new Queue({
                concurrent: 1,
                interval: 0,
            }),
        };
    },
    computed: {
        ...mapGetters({
            appsPermissions: 'auth/appsPermissions',
            appAccesses: 'auth/appAccesses',
        }),
    },
    methods: {
        ...mapActions({
            setAppPermissions: 'auth/setAppPermissions',
            clearAppPermissions: 'auth/clearAppPermissions',
        }),
        fetchAppPermissions() {
            if (this.permissionsPromisesQueue.shouldRun) {
                this.permissionsPromisesQueue.clear();
            }

            Object.keys(this.appsPermissions)
                .filter(code => this.appAccesses?.find(app => app === code) === undefined)
                .forEach(code => this.clearAppPermissions(code));

            if (!this.appAccesses) return;

            for (const appCode of this.appAccesses) {
                if (permissionFetchers[appCode]) {
                    this.permissionsPromisesQueue.enqueue(() =>
                        permissionFetchers[appCode](this).then(permissions => {
                            this.setAppPermissions({ appCode, permissions });
                        })
                    );
                }
            }
        },
        startAppPermissionsPolling() {
            this.fetchAppPermissions();
            // to be be replaced with WST events listening somewhere in the future
            clearInterval(this.fetchAppPermissionsIntervalId);
            this.fetchAppPermissionsIntervalId = setInterval(this.fetchAppPermissions, REFRESH_INTERVAL);
        },
    },
    watch: {
        appAccesses: {
            handler: function (val, oldVal) {
                if (!isEqual(val, oldVal)) {
                    this.startAppPermissionsPolling();
                }
            },
            deep: true,
        },
    },
    mounted() {
        this.startAppPermissionsPolling();
    },
    beforeUnmount() {
        clearInterval(this.fetchAppPermissionsIntervalId);
    },
};
