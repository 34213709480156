<template>
  <header
    id="top-bar"
    class="flex justify-between items-center mb-12"
    :class="{
      'dark bg-black py-4 px-8 -mt-4 -mx-8':
        $route.meta && $route.meta.loadInModal,
      'sticky py-6 z-20 top-0 bg-gray-100': $route.meta && $route.meta.stickyTopBar,
    }"
  >
    <div class="flex items-center">
      <div :class="{ 'text-white': $route.meta && $route.meta.loadInModal }">
        <router-link :to="{ name: 'home' }">
          <icon class="topbar--logo" name="logo"></icon>
        </router-link>
      </div>
      <div class="ml-8" v-if="Boolean(profile)">
        <customer-context-select v-if="Boolean(isQuext)" :disabled="selectorsDisabled" />
        <profile-context-select v-else :disabled="selectorsDisabled" />
        <community-selector :disabled="selectorsDisabled" />
      </div>
    </div>
    <div class="flex items-start" v-if="Boolean(profile)">
      <div class="mr-6">
        <profile-drop-down
          :user="profile.firstName"
          :logout="logout"
        ></profile-drop-down>
      </div>
      <div
        v-if="false"
        class="text-gray-700 cursor-pointer mr-6 relative"
      >
        <notifications
          :notifications="notifications"
          class="h-5 w-5"
        ></notifications>
      </div>
      <div class="text-gray-700 cursor-pointer" @click="openNav">
        <icon
          name="menu"
          class="h-5 w-5"
          :class="{ 'text-white': $route.meta && $route.meta.loadInModal }"
        ></icon>
      </div>
    </div>
  </header>
</template>

<script>
import Icon from "@/components/ui/Icon";
import Notifications from "./Notifications";
import AuthMixin from "@/components/auth/AuthMixin";
import CustomerContextSelect from "@/components/auth/context_select/CustomerContextSelect";
import CommunitySelector from "@/components/auth/context_select/CommunitySelect";
import ProfileContextSelect from '@/components/auth/context_select/ProfileContextSelect';
import ProfileDropDown from "./ProfileDropDown";
import EventBus from "@/utils/EventBus";

export default {
  components: {
    CommunitySelector,
    CustomerContextSelect,
    ProfileContextSelect,
    Icon,
    Notifications,
    ProfileDropDown,
  },
  mixins: [AuthMixin],
  props: {
    selectorsDisabled: {
      type: Boolean,
      default: false,
    },
    logout: {
      type: Function,
      required: true,
    },
  },
  data: () => {
    return {
      notifications: [
        {
          text:
            "you have a new property from the api that does not have a website.",
          date: "37 minutes ago",
          type: "cms",
          route: "websites.create",
        },
        {
          text: "You have a user that needs an update.",
          date: "2 hours ago",
          type: "users",
          route: "users.index",
        },
      ],
      isDark: false,
    };
  },
  methods: {
    openNav() {
      EventBus.emit("open-nav-menu");
    },
  },
};
</script>
<style>
.topbar--logo {
  width: 5rem;
}
</style>
