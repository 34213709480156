<template>
    <div>
        <ContextSelect
            :value="customer"
            @input="updateCustomer"
            ref="customerSelector"
            :options="options"
            trackBy="customerId"
            label="customerName"
            placeholder="select customer"
            :disabled="disabled"
            :no-clear="!profile.customerId"
        />
    </div>
</template>

<script>
import { sortBy } from 'lodash-es';
import AuthMixin from '@/components/auth/AuthMixin';
import NotifyMixin from '@/mixins/NotifyMixin';
import EventBus from '@/utils/EventBus';
import {INIT_CUSTOMERS_FETCH, SIGNED_OUT} from '@/utils/authEvents';
import ContextSelect from '@/components/auth/context_select/ContextSelect';

const OPTIONS_REFRESH_INTERVAL = 60000;

  export default {
    name: 'CustomerContextSelect',
    components: {
      ContextSelect,
    },
    mixins: [AuthMixin, NotifyMixin],
    props: {
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        customer: null,
        loadedOptions: [],
        optionsPollingIntervalId: undefined,
      }
    },
    computed: {
      quextProfile: function () {
        return this.profiles[0];
      },
      options() {
        return [
          {
            ...this.profile,
            customerId: null,
            customerName: 'Unselect customer',
            $isDisabled: !this.customer,
          },
          { $isSeparator: true },
        ].concat(this.loadedOptions);
      }
    },
    watch: {
      customer(value) {
        const newProfile = value || this.quextProfile;
        this.setProfile(newProfile);
        if (!value) {
            this.setCustomerApps([]);
        }
        this.$refs.customerSelector.search = null;
      },
    },
    mounted() {
      this.customer = this.profile?.customerId ? this.profile : null;

      this.getOptions()
        .then(() => {
          this.optionsPollingIntervalId = setInterval(this.getOptions, OPTIONS_REFRESH_INTERVAL);
        });

      EventBus.on(INIT_CUSTOMERS_FETCH, () => {
        this.getOptions();
      });

      EventBus.on(SIGNED_OUT, () => {
        if (this.optionsPollingIntervalId) {
          clearInterval(this.optionsPollingIntervalId);
        }
      });
    },
    methods: {
      getOptions() {
        return this.$authDataProvider.get('customers', { size: 999, nonDeleted: true })
            .then(({ content = [] }) => {
              const contexts = content
                  .filter(customer => !customer.deletedAt)
                  .map(context => ({
                    ...this.profile,
                    customerId: context.customerId,
                    customerName: context.name,
                  }));
              this.loadedOptions = sortBy(contexts, ['customerName']);
            })
            .catch((e) => {
              console.error(e);
              this.notifyError('Failed to fetch data for customer context select');
            });
      },
      updateCustomer(customer) {
          this.customer = customer?.customerId ? customer : null;
      },
    },
    beforeUnmount() {
      clearInterval(this.optionsPollingIntervalId);
    }
  }
</script>
