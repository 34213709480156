const actions = [
    {
        routeName: 'websites.show',
        items: [
            {
                title: 'Amenities',
                routeName: 'websites.amenities',
                params: { id: '{id}' },
            },
            {
                title: 'Contacts',
                routeName: 'contacts.index',
                params: { id: '{id}' },
            },
            {
                title: 'Contact Information',
                routeName: 'websites.contact-information',
                params: { id: '{id}' },
            },
            {
                title: 'Deploy Changes',
                routeName: 'deploys.create',
                params: { id: '{id}' },
            },
            {
                title: 'Deployments',
                routeName: 'deploys.index',
                params: { id: '{id}' },
            },
            {
                title: 'Domains',
                routeName: 'websites.domains',
                params: { id: '{id}' },
            },
            {
                title: 'Embed Codes',
                routeName: 'websites.embed-codes',
                params: { id: '{id}' },
            },
            {
                title: 'External Links',
                routeName: 'websites.external-links',
                params: { id: '{id}' },
            },
            {
                title: 'Floor Plans',
                routeName: 'websites.floorplans',
                params: { id: '{id}' },
            },
            {
                title: 'Navigation Settings',
                routeName: 'websites.navigation-settings',
                params: { id: '{id}' },
            },
            {
                title: 'Points Of Interest',
                routeName: 'websites.points-of-interest',
                params: { id: '{id}' },
            },
            {
                title: 'Site Template',
                routeName: 'websites.site-templates',
                params: { id: '{id}' },
            },
            {
                title: 'Log History',
                routeName: 'websites.log-history',
                params: { id: '{id}' },
            },
        ],
    },
    {
        routeName: 'websites.floorplans',
        items: [
            {
                title: 'Add floor plan',
                routeName: 'websites.floorplans.create',
                params: { id: '{id}' },
            },
            {
                title: 'Import Availability',
                routeName: 'websites.floorplans.import',
                params: { id: '{id}' },
            },
        ],
    },
    {
        routeName: 'websites.floorplans.show',
        items: [
            {
                title: 'Delete floor plan',
                routeName: 'websites.floorplans.destroy',
                params: { id: '{id}' },
            },
        ],
    },
    {
        routeName: 'websites.points-of-interest',
        items: [
            {
                title: 'Manage Categories',
                routeName: 'websites.points-of-interest.categories',
                params: { id: '{id}' },
            },
        ],
    },
];

export default actions;
