export default {
    /* -----------------------
    Calendar Resources
    ----------------------- */

    // Get Community Resource Template Service Hours
    calendarGetCommunityResourceTemplateServiceHours: '/calendar-resources/communities/{community_id}',

    // save  Community Resource Service Hours Templates
    calendarSaveCommunityResourceTemplateServiceHours: '/calendar-resources',

    // Delete Community Resource Service Hours Templates
    calendarDeleteCommunityResourceTemplateServiceHours: '/calendar-resources/{calendar_resource_id}',

    /* -----------------------
    Time Slots
    ----------------------- */

    // Get Community Available Time Slots with appointments by start and end date
    calendarGetCommunityAvailableTimeSlots: '/time-slots/communities/{community_id}/{start_date}/{end_date}',

    // Get Community Available Time Slots by Date
    calendarGetCommunityAvailableTimeSlotsByDate: '/time-slots/communities/{community_id}/available/{start_date}/{end_date}/{appointment}',

    // Create Community Time Slots
    calendarCreateCommunityAvailableTimeSlots: '/communities/{community_id}/time-slots',

    // Update Community Time Slots
    calendarUpdateCommunityAvailableTimeSlots: '/time-slots/{time_slot_id}/communities/{community_id}/',

    // Delete Community Time Slots
    calendarDeleteCommunityAvailableTimeSlots: '/communities/{community_id}/time-slots',

    calendarAssignAppointmentToTimeSlot: '/time-slots/{time_slot_id}/assign-appointment',

    /* -----------------------
    Appointments
    ----------------------- */

    // Get Appointments
    calendarGetAppointments: '/appointments/communities/{community_id}',

    // Create Appointments
    calendarCreateAppointments: '/appointments',

    getAppointment: '/appointments/{appointment_id}',

    // Update Appointment (This will also be used to assign appointments to leasing agents)
    calendarUpdateAppointments: '/appointments/{appointment_id}',

    // Delete Appointments
    calendarDeleteAppointments: '/appointments/{appointment_id}',

    // Assign user to appointment
    calendarAssignUserToAppointment: '/appointments/assign-user',

    // Unassign user to appointment
    calendarUnassignUserToAppointment: '/appointments/unassign-user',

    /* -----------------------
    Settings
    ----------------------- */

    calendarGetCommunitySettings: '/settings/communities/{community_id}',

    calendarSaveCommunitySettings: '/settings/',
};
