<template>
  <div
    class="text-input"
    :class="{
      [$attrs.class]: $attrs.class,
      [`text-input--size-${size}`]: size,
      [`text-input--with-leading-icon`]: $slots['leading-icon'],
      [`text-input--with-trailing-icon`]: $slots['trailing-icon'],
      [`text-input--clearable`]: clearable && !readOnly,
    }"
    :style="$attrs.style"
  >
    <div
      v-if="readOnly"
      :class="textClass"
      v-bind="$attrs"
    >
      {{textValue(value)}}
    </div>

    <textarea
      ref="field"
      v-bind="attrs"
      v-if="!readOnly && multiline"
      v-on="fieldEvents"
      :value="value"
      class="form-input"
      :class="inputClass"
      :data-test="name"
    />

    <div v-if="!readOnly && !multiline" class="relative">
      <span v-if="!!$slots['leading-icon']" class="text-input__icon">
        <!-- @slot Leading icon block -->
        <slot name="leading-icon"></slot>
      </span>

      <input
        ref="field"
        v-bind="attrs"
        v-on="fieldEvents"
        :value="value"
        :type="inputType"
        class="form-input"
        :class="inputClass"
        :data-test="name"
      />

      <button
        v-if="clearable && !readOnly && value"
        class="absolute flex items-center right-0 top-0 bottom-0 p-2 text-graphite-800 outline-none focus:outline-none"
        type="button"
        @click.stop="onClear"
      >
        <icon class="w-4 h-4" name="closeCircle2"/>
      </button>

      <button
        v-if="type === 'password'"
        type="button"
        class="absolute right-0 top-0 bottom-0 p-3 outline-none"
        @click="togglePassword"
      >
        <icon v-show="showPassword" name="eyeOff" class="stroke-current eye-icon"/>
        <icon v-show="!showPassword" name="eye" class="fill-current eye-icon"/>
      </button>

      <span v-if="!!$slots['trailing-icon']" class="text-input__icon">
        <!-- @slot Trailing icon block -->
        <slot name="trailing-icon"></slot>
      </span>
    </div>
  </div>
</template>

<script>
  import Icon from "@/components/ui/Icon";
import { omit } from 'lodash-es';

  export default {
    name: 'TextInput',

    inheritAttrs: false,

    components: { Icon },

    props: {
      /**
       * Input's 'value' attribute
       */
      value: {
        type: [String, Number],
      },

      /**
       * Defines whether field should be multiline (as `<textarea />`)
       */
      multiline: {
        type: Boolean,
        required: false,
        default: false,
      },

      /**
       * Whether field should be non-editable
       */
      readOnly: {
        type: Boolean,
      },

      /**
       * Additional custom class for `<input />` / `<textarea />` in read mode
       */
      textClass: {
        type: String
      },

      /**
       * Additional custom class for `<input />` / `<textarea />` in write mode
       */
      inputClass: {
        type: String,
      },

      /**
       * Field's `type` attribute value
       */
      type: {
        type: String,
        default: 'text'
      },

      /**
       * Display clean icon button
       */
      clearable: {
        type: Boolean,
        required: false,
        default: false
      },

      /**
       * Size for the field
       */
      size: {
        type: String,
        default: '',
        validator: function (value) {
          return ['', 'small', 'xsmall'].indexOf(value) !== -1;
        },
      },
    },

    emits: ['input', 'blur', 'focus', 'clear'],

    data() {
      return {
        showPassword: false
      }
    },

    computed: {
      fieldEvents() {
        return {
          input: e => {
            /**
             * Emitted on input
             */
            this.$emit("input", e.target.value);
          },
          blur: () => {
            /**
             * Emitted on losing focus
             */
            this.$emit("blur");
          },
          focus: () => {
            /**
             * Emitted on focus
             */
            this.$emit("focus");
          },
        };
      },

      inputType() {
        return this.type === 'password' && this.showPassword ? 'text' : this.type;
      },

      attrs(){
        return omit(this.$attrs, ['class', 'style']);
      },
    },

    methods: {
      togglePassword() {
        this.showPassword = !this.showPassword;
      },

      textValue(value) {
        return value ?? '-';
      },

      focus() {
        this.$refs.field.focus();
      },

      onClear() {
        /**
         * Emitted when resetting value
         */
        this.$emit('clear');
      },
    },
  };
</script>

<style scoped>
.text-input {
  @apply w-full;
}

.text-input--size-small .form-input {
    height: 2.25rem;
    @apply px-2;
}

.text-input--size-xsmall .form-input {
    height: 1.625rem;
    @apply px-2;
}

.text-input--clearable .form-input {
  @apply pr-8;
}

.text-input__icon {
  @apply absolute;
  top: 50%;
  transform: translateY(-50%);
}

.text-input--with-leading-icon {
  .form-input {
    @apply pl-10;
  }

  .text-input__icon {
    left: 13px;
  }
}

.text-input--with-trailing-icon {
  .form-input {
    @apply pr-10;
  }

  .text-input__icon {
    right: 13px;
  }
}

.eye-icon {
  @apply w-5 h-5 text-gray-500;
}
</style>
