import { hasAnyCommunitySettingsAccess } from '@/community_settings';

export default [
    {
        path: '/community-settings',
        name: 'community.settings.index',
        component: () => import(/* webpackChunkName: "communitySettings" */ '@/views/community_settings/Index'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'community settings',
            title: 'community settings',
            flowStartsWith: 'community.settings.index',
            product: 'community.settings',
            permissionChecker: hasAnyCommunitySettingsAccess,
        },
    },
];
