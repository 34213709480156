import {createNamespacedHelpers} from 'vuex';

const {mapActions, mapGetters} = createNamespacedHelpers('auth');

export default {
  computed: {
    ...mapGetters([
      'authenticated',
      'userRole',
      'profile',
      'profiles',
      'getAppPermissions',
      'appsPermissions',
      'isQuext',
      'isQuextAdmin',
      'isQuextAdmins',
      'isCustomerAdmin',
      'isDhAdmin',
      'email',
      'community',
      'communities',
      'customerAppCodes',
      'customerCommunityAppCodes',
      'appAccesses',
    ]),

    hasAccessTo() {
      return (appCode) => {
        return this.appAccesses.includes(appCode);
      };
    },
  },
  methods: {
    ...mapActions([
      'signIn',
      'signOut',
      'setProfile',
      'setProfiles',
      'setAppPermissions',
      'clearAppPermissions',
      'setCommunity',
      'setCommunities',
      'setCustomerApps',
      'setCustomerCommunityAppCodes',
    ]),
  }
};
