import store from '@/store';

const permissionChecker = () => store.getters['auth/profile']?.appCodes?.includes('AUTH');

const isQuext = store.getters['auth/isQuext'];

const pathProps = vm => {
    const {
        profile: { customerId },
    } = vm;

    return { customerId };
};

const searchFilter = vm => vm.profile?.customerId;

export default [
    {
        path: '/company/:customerId',
        name: 'company.index',
        component: () => import(/* webpackChunkName: "CustomerDetails" */ '@/views/auth/customers/CustomerDetails'),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'company details',
            breadcrumbName: 'company',
            allowedRoles: ['SUPER_ADMIN', 'SUPER_READONLY_ADMIN', 'CUSTOMER_ADMIN', 'CUSTOMER_READONLY_ADMIN'],
            flowStartsWith: 'company.index',
            product: 'admin',
            requiresCustomer: true,
            permissionChecker,
            pathProps,
            searchFilter,
        },
    },
    // Communities
    {
        path: '/company/:customerId/communities',
        name: 'company.communities.index',
        component: () => import(/* webpackChunkName: "CommunitiesList" */ '@/views/auth/customers/communities/CommunitiesList'),
        meta: {
            loadInModal: true,
            actions: true,
            title: isQuext ? 'manage company communities' : 'manage communities',
            breadcrumbName: 'communities',
            allowedRoles: ['SUPER_ADMIN', 'SUPER_READONLY_ADMIN', 'CUSTOMER_ADMIN', 'CUSTOMER_READONLY_ADMIN'],
            flowStartsWith: 'company.communities.index',
            product: 'admin',
            requiresCustomer: true,
            permissionChecker,
            pathProps,
            searchFilter,
        },
    },
    {
        path: '/company/:customerId/communities/create',
        name: 'company.communities.create',
        component: () => import(/* webpackChunkName: "CommunitiesCreate" */ '@/views/auth/customers/communities/CommunitiesCreate'),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'add new community',
            breadcrumbName: 'add new community',
            allowedRoles: ['SUPER_ADMIN'],
            flowStartsWith: 'company.communities.create',
            requiresCustomer: true,
            permissionChecker,
            pathProps,
            searchFilter,
        },
    },
    {
        path: '/company/:customerId/communities/:communityId/details',
        name: 'company.communities.details',
        component: () => import(/* webpackChunkName: "CommunityDetails" */ '@/views/auth/customers/communities/CommunityDetails'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'community details',
            title: 'community details',
            allowedRoles: ['SUPER_ADMIN', 'SUPER_READONLY_ADMIN', 'CUSTOMER_ADMIN', 'CUSTOMER_READONLY_ADMIN'],
            hideFromSearch: true,
            requiresCustomer: true,
            permissionChecker,
        },
    },
    {
        path: '/company/:customerId/communities/:communityId',
        name: 'company.communities.edit',
        component: () => import(/* webpackChunkName: "CommunitiesEdit" */ '@/views/auth/customers/communities/CommunitiesEdit'),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'edit community',
            breadcrumbName: isQuext ? 'edit community' : 'community details',
            allowedRoles: ['SUPER_ADMIN', 'SUPER_READONLY_ADMIN', 'CUSTOMER_ADMIN', 'CUSTOMER_READONLY_ADMIN'],
            hideFromSearch: true,
            requiresCustomer: true,
            permissionChecker,
        },
    },
    // Deleted communities
    {
        path: '/company/:customerId/deleted-communities',
        name: 'company.deletedCommunities.index',
        component: () =>
            import(/* webpackChunkName: "DeletedCommunitiesList" */ '@/views/auth/customers/deletedCommunities/DeletedCommunitiesList'),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'deleted communities',
            breadcrumbName: 'deleted communities',
            allowedRoles: ['SUPER_ADMIN', 'SUPER_READONLY_ADMIN', 'CUSTOMER_ADMIN', 'CUSTOMER_READONLY_ADMIN'],
            requiresCustomer: true,
            permissionChecker,
            pathProps,
            searchFilter,
        },
    },
    {
        path: '/company/:customerId/deleted-communities/:communityId/details',
        name: 'company.deletedCommunities.details',
        component: () => import(/* webpackChunkName: "CommunityDetails" */ '@/views/auth/customers/communities/CommunityDetails'),
        meta: {
            loadInModal: true,
            actions: false,
            title: 'deleted community details',
            breadcrumbName: 'deleted community details',
            allowedRoles: ['SUPER_ADMIN', 'SUPER_READONLY_ADMIN', 'CUSTOMER_ADMIN', 'CUSTOMER_READONLY_ADMIN'],
            hideFromSearch: true,
            requiresCustomer: true,
            permissionChecker,
        },
    },
    // Users
    {
        path: '/company/:customerId/users',
        name: 'company.users.index',
        component: () => import(/* webpackChunkName: "UsersList" */ '@/views/auth/customers/users/UsersList'),
        meta: {
            loadInModal: true,
            actions: true,
            title: isQuext ? 'manage company users' : 'manage users',
            breadcrumbName: 'users',
            allowedRoles: ['SUPER_ADMIN', 'SUPER_READONLY_ADMIN', 'CUSTOMER_ADMIN', 'CUSTOMER_READONLY_ADMIN'],
            flowStartsWith: 'company.users.index',
            requiresCustomer: true,
            product: 'admin',
            permissionChecker,
            pathProps,
            searchFilter,
        },
    },
    {
        path: '/company/:customerId/users/create',
        name: 'company.users.create',
        component: () => import(/* webpackChunkName: "UsersCreate" */ '@/views/auth/customers/users/UsersCreate'),
        meta: {
            loadInModal: true,
            actions: true,
            title: isQuext ? 'add new company user' : 'add new company user',
            breadcrumbName: 'add new',
            allowedRoles: ['SUPER_ADMIN', 'CUSTOMER_ADMIN'],
            flowStartsWith: 'company.users.create',
            requiresCustomer: true,
            permissionChecker,
            pathProps,
            searchFilter,
        },
    },
    {
        path: '/company/:customerId/users/:userId/reinvite',
        name: 'company.users.invite.index',
        component: () => import(/* webpackChunkName: "UsersEdit" */ '@/views/auth/customers/users/UserReinviteView'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 're-invite',
            title: 'resend invitation',
            allowedRoles: ['SUPER_ADMIN', 'CUSTOMER_ADMIN'],
            hideFromSearch: true,
            requiresCustomer: true,
            permissionChecker,
        },
    },
    {
        path: '/company/:customerId/users/:userId',
        name: 'company.users.edit',
        component: () => import(/* webpackChunkName: "UsersEdit" */ '@/views/auth/customers/users/UsersEdit'),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'edit user',
            breadcrumbName: 'edit',
            allowedRoles: ['SUPER_ADMIN', 'CUSTOMER_ADMIN'],
            hideFromSearch: true,
            requiresCustomer: true,
            permissionChecker,
        },
    },
    {
        path: '/company/:customerId/users/:userId/details',
        name: 'company.users.details',
        component: () => import(/* webpackChunkName: "UsersEdit" */ '@/views/auth/customers/users/UserDetails'),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'user details',
            breadcrumbName: 'user details',
            allowedRoles: ['SUPER_ADMIN', 'SUPER_READONLY_ADMIN', 'CUSTOMER_ADMIN', 'CUSTOMER_READONLY_ADMIN'],
            hideFromSearch: true,
            requiresCustomer: true,
            permissionChecker,
        },
    },
    // Deleted users
    {
        path: '/company/:customerId/deleted-users',
        name: 'company.deletedUsers.index',
        component: () => import(/* webpackChunkName: "DeletedUsersList" */ '@/views/auth/customers/deletedUsers/DeletedUsersList'),
        meta: {
            loadInModal: true,
            actions: true,
            title: isQuext ? 'manage company deleted users' : 'manage deleted users',
            breadcrumbName: 'deleted users',
            allowedRoles: ['SUPER_ADMIN', 'SUPER_READONLY_ADMIN'],
            requiresCustomer: true,
            permissionChecker,
            pathProps,
            searchFilter,
        },
    },
    {
        path: '/company/:customerId/deleted-users/:userId/details',
        name: 'company.deletedUsers.details',
        component: () => import(/* webpackChunkName: "UsersEdit" */ '@/views/auth/customers/users/UserDetails'),
        meta: {
            loadInModal: true,
            actions: false,
            title: 'deleted user details',
            breadcrumbName: 'user details',
            allowedRoles: ['SUPER_ADMIN', 'SUPER_READONLY_ADMIN', 'CUSTOMER_ADMIN', 'CUSTOMER_READONLY_ADMIN'],
            hideFromSearch: true,
            requiresCustomer: true,
            permissionChecker,
        },
    },
    // Admins
    {
        path: '/company/:customerId/admins',
        name: 'company.admins.index',
        component: () => import(/* webpackChunkName: "AdminsList" */ '@/views/auth/customers/admins/AdminsList'),
        meta: {
            loadInModal: true,
            actions: true,
            title: isQuext ? 'manage company admins' : 'manage admins',
            breadcrumbName: 'admins',
            allowedRoles: ['SUPER_ADMIN', 'SUPER_READONLY_ADMIN', 'CUSTOMER_ADMIN', 'CUSTOMER_READONLY_ADMIN'],
            flowStartsWith: 'company.admins.index',
            requiresCustomer: true,
            product: 'admin',
            permissionChecker,
            pathProps,
            searchFilter,
        },
    },
    {
        path: '/company/:customerId/admins/create',
        name: 'company.admins.create',
        component: () => import(/* webpackChunkName: "AdminCreate" */ '@/views/auth/customers/users/UsersCreate'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'add new',
            title: isQuext ? 'add new company admin' : 'add new admin',
            allowedRoles: ['SUPER_ADMIN', 'CUSTOMER_ADMIN'],
            flowStartsWith: 'company.admins.create',
            requiresCustomer: true,
            permissionChecker,
            pathProps,
            searchFilter,
        },
    },
    {
        path: '/company/:customerId/admins/:userId/details',
        name: 'company.admins.details',
        component: () => import(/* webpackChunkName: "AdminDetails" */ '@/views/auth/customers/users/UserDetails'),
        meta: {
            loadInModal: true,
            title: 'admin details',
            breadcrumbName: 'admin details',
            allowedRoles: ['SUPER_ADMIN', 'SUPER_READONLY_ADMIN', 'CUSTOMER_ADMIN', 'CUSTOMER_READONLY_ADMIN'],
            hideFromSearch: true,
            requiresCustomer: true,
            permissionChecker,
        },
    },
    {
        path: '/company/:customerId/admins/:userId',
        name: 'company.admins.edit',
        component: () => import(/* webpackChunkName: "AdminEdit" */ '@/views/auth/customers/users/UsersEdit'),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'edit admin user',
            breadcrumbName: 'edit',
            allowedRoles: ['SUPER_ADMIN', 'CUSTOMER_ADMIN'],
            hideFromSearch: true,
            requiresCustomer: true,
            permissionChecker,
        },
    },
    // Deleted admins
    {
        path: '/company/:customerId/deleted-admins',
        name: 'company.deletedAdmins.index',
        component: () => import(/* webpackChunkName: "DeletedAdminsList" */ '@/views/auth/customers/admins/DeletedAdminsList'),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'company deleted admins',
            breadcrumbName: 'deleted admins',
            allowedRoles: ['SUPER_ADMIN', 'SUPER_READONLY_ADMIN'],
            requiresCustomer: true,
            permissionChecker,
            pathProps,
            searchFilter,
        },
    },
    {
        path: '/company/:customerId/deleted-admins/:userId/details',
        name: 'company.deletedAdmins.details',
        component: () => import(/* webpackChunkName: "AdminDetails" */ '@/views/auth/customers/users/UserDetails'),
        meta: {
            loadInModal: true,
            actions: false,
            title: 'deleted admin details',
            breadcrumbName: 'admin details',
            allowedRoles: ['SUPER_ADMIN', 'SUPER_READONLY_ADMIN', 'CUSTOMER_ADMIN', 'CUSTOMER_READONLY_ADMIN'],
            hideFromSearch: true,
            requiresCustomer: true,
            permissionChecker,
        },
    },
    // Roles
    {
        path: '/company/:customerId/roles',
        name: 'company.roles.index',
        component: () => import(/* webpackChunkName: "RolesList" */ '@/views/auth/customers/roles/RolesList'),
        meta: {
            loadInModal: true,
            actions: true,
            title: isQuext ? 'manage company roles' : 'manage roles',
            breadcrumbName: 'roles',
            allowedRoles: ['SUPER_ADMIN', 'SUPER_READONLY_ADMIN'],
            flowStartsWith: 'company.roles.index',
            requiresCustomer: true,
            permissionChecker,
            pathProps,
            searchFilter,
        },
    },
    {
        path: '/company/:customerId/roles/:roleId/details',
        name: 'company.roles.details',
        component: () => import(/* webpackChunkName: "RoleDetails" */ '@/views/auth/customers/roles/RoleDetails'),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'role details',
            breadcrumbName: 'role details',
            allowedRoles: ['SUPER_ADMIN', 'SUPER_READONLY_ADMIN'],
            hideFromSearch: true,
            requiresCustomer: true,
            permissionChecker,
        },
    },
    // Deleted Roles
    {
        path: '/company/:customerId/deleted-roles',
        name: 'company.deletedRoles.index',
        component: () => import(/* webpackChunkName: "DeletedRolesList" */ '@/views/auth/customers/roles/DeletedRolesList'),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'deleted company roles',
            breadcrumbName: 'deleted roles',
            allowedRoles: ['SUPER_ADMIN', 'SUPER_READONLY_ADMIN'],
            flowStartsWith: 'company.roles.index',
            requiresCustomer: true,
            permissionChecker,
            pathProps,
            searchFilter,
        },
    },
    {
        path: '/company/:customerId/deleted-roles/:roleId/details',
        name: 'company.deletedRoles.details',
        component: () => import(/* webpackChunkName: "RoleDetails" */ '@/views/auth/customers/roles/RoleDetails'),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'deleted role details',
            breadcrumbName: 'deleted role details',
            allowedRoles: ['SUPER_ADMIN', 'SUPER_READONLY_ADMIN'],
            hideFromSearch: true,
            requiresCustomer: true,
            permissionChecker,
        },
    },
    // Permissions
    {
        path: '/company/:customerId/permissions',
        name: 'company.permissions.index',
        component: () => import(/* webpackChunkName: "PermissionsList" */ '@/views/auth/customers/permissions/PermissionsList'),
        meta: {
            loadInModal: true,
            actions: true,
            title: isQuext ? 'manage company permissions' : 'manage permissions',
            breadcrumbName: 'permissions',
            allowedRoles: ['SUPER_ADMIN', 'SUPER_READONLY_ADMIN'],
            flowStartsWith: 'company.permissions.index',
            requiresCustomer: true,
            permissionChecker,
            pathProps,
            searchFilter,
        },
    },
    //  Integrations
    {
        path: '/company/:customerId/communities/:communityId/integrations',
        name: 'company.communities.integrations.index',
        component: () =>
            import(
                /* webpackChunkName: "CustomerIntegrationsList" */ '@/views/auth/customers/communities/Integrations/CustomerIntegrationsList'
            ),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'integration partners',
            breadcrumbName: 'integration partners',
            allowedRoles: ['SUPER_ADMIN', 'SUPER_READONLY_ADMIN', 'CUSTOMER_ADMIN', 'CUSTOMER_READONLY_ADMIN'],
            flowStartsWith: 'company.communities.index',
            passFlowTo: 'company.communities.integrations.index',
            requiresCustomer: true,
            pathProps,
            searchFilter,
            requiredAppAccess: ['IPS'],
        },
    },
    {
        path: '/company/:customerId/communities/:communityId/integrations/create',
        name: 'company.communities.integrations.create',
        component: () =>
            import(
                /* webpackChunkName: "CustomerIntegrationsCreate" */ '@/views/auth/customers/communities/Integrations/CustomerIntegrationsCreate'
            ),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'add integration partner',
            breadcrumbName: 'add new',
            allowedRoles: ['SUPER_ADMIN', 'SUPER_READONLY_ADMIN', 'CUSTOMER_ADMIN', 'CUSTOMER_READONLY_ADMIN'],
            flowStartsWith: 'company.communities.index',
            passFlowTo: 'company.communities.integrations.create',
            permissionChecker,
            pathProps,
            searchFilter,
            requiredAppAccess: ['IPS'],
        },
    },
    {
        path: '/company/:customerId/communities/:communityId/integrations/:partnerId',
        name: 'company.communities.integrations.edit',
        component: () =>
            import(
                /* webpackChunkName: "CustomerIntegrationsEdit" */ '@/views/auth/customers/communities/Integrations/CustomerIntegrationsEdit'
            ),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'edit integration partner',
            breadcrumbName: 'edit integration partner',
            allowedRoles: ['SUPER_ADMIN', 'SUPER_READONLY_ADMIN', 'CUSTOMER_ADMIN', 'CUSTOMER_READONLY_ADMIN'],
            hideFromSearch: true,
            permissionChecker,
            requiredAppAccess: ['IPS'],
        },
    },
];
