const routes = [
    {
        name: 'deploys.index',
        path: '/websites/:id/deploys',
        component: () => {
            return import('@/views/cms/deploys/Index');
        },
        meta: {
            loadInModal: true,
            actions: true,
            title: 'deploy history',
            breadcrumbName: 'deploy history',
            flowStartsWith: 'websites.index',
            passFlowTo: 'deploys.index',
            product: 'cms',
            requiresCustomer: true,
            requiresCommunity: true,
            requiredAppAccess: ['CMS'],
        },
    },
    {
        name: 'deploys.create',
        path: '/websites/:id/deploys/create',
        component: () => {
            return import('@/views/cms/deploys/Create');
        },
        meta: {
            loadInModal: true,
            actions: true,
            title: 'deploy website',
            breadcrumbName: 'deploy website',
            flowStartsWith: 'websites.index',
            passFlowTo: 'deploys.create',
            product: 'cms',
            requiresCustomer: true,
            requiresCommunity: true,
            requiredAppAccess: ['CMS'],
        },
    },
];

export default routes;
