import store from '@/store';

const createPermissionChecker = permissionId => () => store.getters['amnt/hasPermission'](permissionId);

export default [
    {
        routeName: 'amenities.enterprise.individual.index',
        items: [
            {
                title: 'add new amenity',
                routeName: 'amenities.enterprise.individual.create',
                params: {},
                allowed: createPermissionChecker('EL_AM'),
            },
            {
                title: 'assign communities',
                routeName: 'amenities.communities-assignment',
                params: {},
                allowed: createPermissionChecker('EL_CA'),
            },
        ],
    },
    {
        routeName: 'amenities.enterprise.individual.edit',
        items: [],
    },
    {
        routeName: 'amenities.enterprise.groups.index',
        items: [
            {
                title: 'add new group',
                routeName: 'amenities.enterprise.groups.create',
                params: {},
                allowed: createPermissionChecker('EL_AGM'),
            },
            {
                title: 'assign communities',
                routeName: 'amenities.communities-assignment',
                params: {},
                allowed: createPermissionChecker('EL_CA'),
            },
        ],
    },
    {
        routeName: 'amenities.enterprise.groups.edit',
        items: [],
    },
    {
        routeName: 'amenities.community.individual.index',
        items: [
            {
                title: 'units assignment',
                routeName: 'amenities.community.unitsAssignment',
                params: {},
                allowed: createPermissionChecker('CL_UA'),
            },
        ],
    },
    {
        routeName: 'amenities.community.individual.edit',
        items: [
            {
                title: 'units assignment',
                routeName: 'amenities.community.individual.unitsAssignment',
                params: {
                    amenityId: '{amenityId}',
                },
                allowed: createPermissionChecker('CL_UA'),
            },
        ],
    },
    {
        routeName: 'amenities.community.groups.index',
        items: [
            {
                title: 'units assignment',
                routeName: 'amenities.community.unitsAssignment',
                params: {},
                allowed: createPermissionChecker('CL_UA'),
            },
        ],
    },
    {
        routeName: 'amenities.community.groups.edit',
        items: [
            {
                title: 'units assignment',
                routeName: 'amenities.community.groups.unitsAssignment',
                params: {
                    groupId: '{groupId}',
                },
                allowed: createPermissionChecker('CL_UA'),
            },
        ],
    },
];
