<template>
    <transition name="modal-fade" @after-enter="showContent = true">
        <div v-show="showNav" class="nav-wrapper">
            <div class="nav-overlay" @click="closeNav"></div>
            <transition name="nav-slide">
                <nav v-if="showContent" :class="{ open: showNav === true }">
                    <div class="nav-products">
                        <div>
                            <icon class="logo" name="logo"></icon>
                        </div>
                        <ul class="products-list">
                            <li
                                v-for="(product, idx) in sortedProducts"
                                :key="idx"
                                :class="{ selected: current == product }"
                                :data-product="product"
                                :data-val="idx"
                                @click="setCurrent()"
                            >
                                {{ productNames[product] ? productNames[product] : '' }}
                            </li>
                            <span :class="current" class="indicator"></span>
                        </ul>
                    </div>
                    <div class="nav-links">
                        <div class="close" @click="closeNav()">
                            <icon name="close"></icon>
                        </div>
                        <ul>
                            <li @click="closeNav()" v-for="link in productLinks" :key="link.key">
                                <modal-link :route="link.route" :params="link.params">
                                    {{ link.title }}
                                </modal-link>
                            </li>
                        </ul>
                    </div>
                </nav>
            </transition>
        </div>
    </transition>
</template>

<script>
import { intersection } from "lodash-es";
import Icon from "@/components/ui/Icon";
import EventBus from "@/utils/EventBus";
import AuthMixin from "@/components/auth/AuthMixin";
import ModalLink from "@/components/ui/modals/ModalLink";
import {mapGetters} from "vuex";

export default {
    mixins: [AuthMixin],
    components: { Icon, ModalLink },
    data: () => {
        return {
            showNav: false,
            showContent: false,
            current: null,
            productNames: {
                'cms' : 'websites',
                'dh' : 'digital human',
                'sreq' : 'service requests',
                'msgs' : 'messaging',
                'loc' : 'locations',
                'cbr': 'property management',
                'admin' : 'admin',
                'qxt-admin' : 'quext admin',
                'marketing': 'marketing',
                'iot': 'iot',
            }
        };
    },
    computed: {
        ...mapGetters({
            allSearchableRoutes: 'search_routes/getSearchableRoutes',
        }),
        sortedProducts() {
            return intersection(Object.keys(this.products), Object.keys(this.productNames)).sort();
        },
        products() {
            return this.links.reduce((r, a) => {
                r[a.meta.product] = [...r[a.meta.product] || [], a];
                return r;
            }, {})
        },
        links() {
            return this.allSearchableRoutes
                .filter(route => (
                    (!route.meta.allowedRoles ||
                    route.meta.allowedRoles.includes(this.profile.role)) &&
                    (!route.meta.searchFilter ||
                    route.meta.searchFilter(this)) &&
                    (!route.meta?.requiredAppAccess ||
                        intersection(route.meta?.requiredAppAccess, this.appAccesses).length > 0)
                ))
        },
        productLinks() {
            return this.links
                .filter(link => link.meta.product === this.current)
                .map(link => ({
                    key: link.name,
                    route: link.meta.flowStartsWith || link.name,
                    params: {
                        ...link.meta?.pathProps?.(this),
                        passFlowTo: link.meta.passFlowTo || null,
                    },
                    title: link.meta.title.toLowerCase(),
                }))
                .sort((a, b) => a.title.localeCompare(b.title));
        },
    },
    mounted() {
        this.current = this.sortedProducts[0]
        this.getProductLength();

        EventBus.on("open-nav-menu", () => {
            this.showNav = true;
        });
    },
    methods: {
        getProductLength() {
            //gets the current number of products
            var l = this.products.length;
            //gets the height for the selected line. ie if 4 items: 1 / 4 = .25 * 100 = 25%;
            var newL = (1 / l) * 100;

            var wrapper = document.querySelector(".nav-wrapper");

            //sets the css custom property with the line height from above
            wrapper.style.setProperty("--productNo", newL + "%");
        },
        setCurrent() {
            //gets the product name of the clicked on product -- needed to update the class	of the selected item which sets the color
            var product = event.target.getAttribute("data-product");
            //sets the data-val attribute as the index of the item
            var newVal = event.target.getAttribute("data-val");
            this.current = product;
            this.moveLine(newVal);
        },

        moveLine(newVal) {
            //   moves the select bar to the right place
            var newLocation = newVal;
            var y = newLocation * 100;
            var wrapper = document.querySelector(".nav-wrapper");
            wrapper.style.setProperty("--linePosition", y + "%");
        },

        closeNav() {
            this.showNav = false;
            this.showContent = false;
            var wrapper = document.querySelector(".nav-wrapper");

            //sets the css custom property with the line height from above
            wrapper.style.setProperty("--linePosition", "0%");
        },
    },
};
</script>
<style scoped>
.modal-fade-enter-from,
.modal-fade-leave-active {
    opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
    transition: opacity 0.15s ease;
}

.nav-slide-enter-from {
    transform: translateX(105%);
}

.nav-slide-leave-active {
    opacity: 0;
}

.nav-slide-enter-active {
    transition: 0.3s cubic-bezier(0.17, 0.67, 0.25, 1);
}

.nav-slide-leave-active {
    transition: 0.1s ease-out;
}

.nav-wrapper {
    --default: #3498db;
    --cms: #d8b316;
    --dh: #e561a3;
    --linePosition: 0%;
    --productNo: 4;
}

.nav-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
}

.nav-overlay {
    background: rgba(0, 0, 0, 0.9);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(2px);
}

nav {
    background: #fff;
    position: absolute;
    height: 100%;
    right: 0;
    top: 0;
    display: grid;
    grid-template-columns: auto 450px;
    /* transform: translateX(105%); */
    transition: 0.3s cubic-bezier(0.17, 0.67, 0.25, 1);
    width: min-content;
}

.logo {
    width: 35%;
    margin-left: 4rem;
    margin-bottom: 4rem;
    color: #fff;
}

.nav-products {
    background: #000;
    padding: 2.5rem 0;
}

.products-list {
    position: relative;
}

.products-list span {
    position: absolute;
    width: 6px;
    height: var(--productNo);
    background: var(--default);
    right: 0;
    top: 0;
    transform: translateY(var(--linePosition));
    transition: 0.15s ease-in;
}

.nav-products ul li {
    color: #fff;
    font-family: "FrankNew", sans-serif;
    font-weight: 600;
    font-size: 1.25rem;
    padding: 0.5rem 4rem;
    cursor: pointer;
    white-space: nowrap;
}

.nav-products ul li.selected {
    color: var(--default);
    text-decoration: underline;
}

.nav-products ul li:hover {
    color: var(--default);
}

.nav-products ul li.selected[data-product="cms"],
.nav-products ul li[data-product="cms"]:hover {
    color: var(--cms);
}

.nav-products ul li.selected[data-product="dh"],
.nav-products ul li[data-product="dh"]:hover {
    color: var(--dh);
}

.products-list .indicator.cms {
    background: var(--cms);
}

.products-list .indicator.dh {
    background: var(--dh);
}

.nav-links .close {
    cursor: pointer;
    position: absolute;
    right: 2rem;
    top: 2rem;
}

.nav-links .close svg {
    width: 1.25rem;
}

.nav-links ul {
    margin-top: 4.5rem;
    /* 4.5rem + 3rem (bottom) = 7.5rem */
    max-height: calc(100vh - 7.5rem);
    overflow-y: auto;
}

.nav-links ul li a {
    padding: 0.625rem 5rem;
    display: block;
    font-weight: 500;
    color: #000;
}

.nav-links ul li:hover {
    text-decoration: underline;
}
</style>
