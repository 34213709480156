<template>
    <transition name="down-in">
        <div v-if="alert.message" class="alert" :class="'alert_' + alert.type">
            <span v-html="alert.message"></span>
            <div @click="resetAlert" class="absolute top-2 right-2 w-2 h-2 cursor-pointer">
                <icon name="close" class="h-3 w-3"></icon>
            </div>
        </div>
    </transition>
</template>

<script>
    import Icon from "../ui/Icon";
    import {mapActions, mapGetters} from "vuex";

    export default {
        components: { Icon },
        data: () => {
            return {
                autoCloseAfter: {
                  success: 2500,
                  error: 5*60*1000,
                }
            }
        },
        computed: {
            ...mapGetters({
                alert: 'alerts/getAlert'
            }),
        },
        methods: {
            ...mapActions({
                resetAlert: 'alerts/reset',
            }),
        },
        watch: {
            alert: {
                handler: function() {
                    if(this.alert.message) {
                        setTimeout(
                            () => { this.resetAlert(); },
                            this.autoCloseAfter[this.alert.type] || this.autoCloseAfter.success,
                        );
                    }
                    if(this.alert.back) {
                        this.$router.go(-1);
                    }
                },
                immediate: true
            }
        },
    }
</script>
<style scoped>
.alert {
    @apply fixed top-3 right-3 p-8 text-sm font-500 font-frank;
    background: transparent;    
    max-width: 35ch;
    border: 1px solid transparent;
    z-index: 1000000;
    /* transform: translateY(-130%); */
}
.alert_success{
    background-color: #d2e8db;
    border-color:#3bab66;
    color: #005821;
}
.alert_error {
    background: #ffe3e5;
    border-color: #e6b5ba;
    color: #a00011;
}

.down-in-enter-active {
    transition: all .35s cubic-bezier(.71,.67,.5,1.35);
}
.down-in-leave-active {
    transition: all .15s;
}
.down-in-enter-from {
    opacity: 0;
    transform: translateY(-50%);        
}
.down-in-leave-to{    
    opacity: 0;
}
</style>