import { createI18n } from 'vue-i18n';
import store from './store';
import EventBus from '@/utils/EventBus';

export const i18n = createI18n({
    legacy: true,
    silentTranslationWarn: true,
    silentFallbackWarn: true,
});

export const setLocale = async locale => {
    const messages = await import(`@/locales/${locale}`);
    i18n.global.silentTranslationWarn = false;
    i18n.global.setLocaleMessage(locale, messages.default);
    i18n.global.locale = locale;

    const pluralRules = new Intl.PluralRules(locale);
    const { pluralCategories } = pluralRules.resolvedOptions();
    i18n.global.pluralizationRules[locale] = value => pluralCategories.indexOf(pluralRules.select(value));

    EventBus.emit('locale-changed', locale);
};

setLocale(store.state.settings.locale);
store.watch(
    state => state.settings.locale,
    locale => {
        setLocale(locale);
    }
);

window.setLocale = locale => store.commit('settings/SET_LOCALE', locale);
