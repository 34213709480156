import { intersection } from 'lodash-es';

const hasPermission = (state) => permissionId => {
    const hasAuthAccess = profileAppCodes(state).includes('AUTH');

    if (!hasAuthAccess) {
        return false;
    }

    const quextAdmin = isQuextAdmins(state);
    const customerAdmin = isCustomerAdmin(state);
    const selectedCustomerId = customerId(state);

    // TODO: will be updated with migration to permission-based model
    const allPermissions = [
        ...(quextAdmin ? ['QUEXT_SETUP'] : []),
        ...(customerAdmin || (quextAdmin && selectedCustomerId) ? ['ADMIN'] : []),
    ];

    return allPermissions.includes(permissionId);
};

const userRole = state => state.user.activeProfile?.role;

const authenticated = state => state.user.authenticated;

const profile = state => state.user.activeProfile;

const profiles = state => state.user.profiles;

const getAppPermissions = state => appId => state.user.appsPermissions[appId];

const appsPermissions = state => state.user.appsPermissions;

const isQuext = state => ['SUPER_ADMIN', 'SUPER_READONLY_ADMIN', 'DIGITAL_HUMAN_WRITER'].includes(state.user.activeProfile?.role);

const isQuextAdmins = state => ['SUPER_ADMIN', 'SUPER_READONLY_ADMIN'].includes(state.user.activeProfile?.role);

const isQuextAdmin = state => ['SUPER_ADMIN'].includes(state.user.activeProfile?.role);

const isCustomerAdmin = state => ['CUSTOMER_ADMIN', 'CUSTOMER_READONLY_ADMIN'].includes(state.user.activeProfile?.role);

const isDhAdmin = state => ['DIGITAL_HUMAN_WRITER'].includes(state.user.activeProfile?.role);

const email = state => state.user.email;

const customerId = state => state.user.activeProfile?.customerId;

const cognitoUserId = state => state.user.username;

const community = state => state.user.activeCommunity;

const profileAppCodes = state => state.user.activeProfile?.appCodes || [];

const customerApps = state => state.user.activeCustomerApps;

const customerAppCodes = state => customerApps(state).map(app => app.appCode);

const customerCommunityAppCodes = state => state.user.activeCustomerCommunityAppCodes;

const communities = state => state.communities;

const appAccesses = state => {
    let initCodes = profileAppCodes(state);

    // TODO: remove it after full IoT integration into Quext SPA
    const betaIot = customerApps(state).find(({appCode, appId}) => (appCode === 'IOT' && !appId));
    if (!betaIot) {
        initCodes = initCodes.filter(code => code !== 'IOT');
    }

    const customerAccess = isQuext(state) && customerId(state)
        ? intersection(initCodes, customerAppCodes(state))
        : initCodes;

    return community(state) ? intersection(customerAccess, customerCommunityAppCodes(state)) : customerAccess;
};

export default {
    userRole,
    authenticated,
    profile,
    profiles,
    getAppPermissions,
    appsPermissions,
    isQuext,
    isQuextAdmins,
    isQuextAdmin,
    isCustomerAdmin,
    isDhAdmin,
    email,
    customerId,
    cognitoUserId,
    community,
    communities,
    customerAppCodes,
    customerCommunityAppCodes,
    appAccesses,
    hasPermission,
};
