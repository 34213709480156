const routes = [
    {
        name: 'manage_residents.index',
        path: '/manage-residents',
        component: () => {
            return import('@/views/resident_lifecycle/manage_residents/Index');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'resident details',
            title: 'resident details',
            size: 'normal',
            flowStartsWith: 'manage_residents.index',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'move-in',
        },
    },
    {
        name: 'manage_residents.resident-details',
        path: '/resident-details',
        component: () => {
            return import('@/views/resident_lifecycle/manage_residents/ResidentDetails');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'resident details',
            title: 'resident details',
            size: 'normal',
            flowStartsWith: 'manage_residents.resident-details',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'move-in',
        },
    },
];

export default routes;
