<template>
    <div id="notification-popup">
        <span @click="isOpen = !isOpen" :class="{ 'text-white' : $route.meta && $route.meta.loadInModal }">
            <icon name="notification"></icon>
            <span class="notification-count">{{ notifications.length }}</span>
        </span>
        <div
            class="absolute z-50 bg-white right-0 border shadow mt-2 w-64"
            v-if="isOpen"
        >
            <h3 class="text-highlight-base px-4 py-2 border-b">Notifications</h3>
            <ul class="text-xs">
                <li
                    v-for="(notification, index) in notifications"
                    :key="index"
                    class="notification-item border-b p-4 cursor-pointer hover:bg-blue-50"
                >
                    <div class="flex justify-between mb-2">
                        <span class="text-black font-bold capitalize">{{ notification.type}}</span>
                        <span class="text-gray-400">{{ notification.date }}</span>
                    </div>
                    <p class="mb-2">{{ notification.text}}</p>
                    <router-link
                        :to="{ name: notification.route }"
                        class="text-blue-500 font-semibold"
                        @click="isOpen=false"
                    >
                        Setup now
                    </router-link>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import Icon from "../ui/Icon";

    export default {
        components: { Icon },
        props: {
            notifications: {
                type: Array,
                required: true
            }
        },
        data: () => {
            return {
                isOpen: false
            }
        },
        methods: {
            handleOutsideClickEvent(e) {
                if(this.isOpen) {
                    let el = document.getElementById('notification-popup');
                    if( el && !el.contains(e.target)) {
                        this.isOpen = false;
                    }
                }
            },
        },
        created() {
            window.addEventListener('click', this.handleOutsideClickEvent);
        }
    }
</script>

<style scoped>
    .notification-item:last-child {
        border-bottom: 0; /** last: tailwind variant somehow not working :( */
    }
    .notification-count {
        position: absolute;
        background: var(--highlightColorBase);
        border-radius: 50%;
        width: 15px;
        height: 15px;
        font-family: 'Frank New', sans-serif;
        font-weight: 900;
        font-size: 10px;
        color: #fff;
        text-align: center;
        top: -3px;
        right: -5px;
    }
</style>