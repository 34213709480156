const LOAD_PAGES_WIDGETS = (state, payload) => {
    state.pages_widgets = payload;
};

const ADD_PAGE_WIDGET = (state, payload) => {
    state.pages_widgets.push(payload);
};

const UPDATE_PAGE_WIDGET_VALUES = (state, payload) => {
    let pageWidget = state.pages_widgets.find(item => item.id === payload.id);
    pageWidget.values = payload.values;
};

const UPDATE_PAGE_WIDGET_ORDER = (state, payload) => {
    let pageWidget = state.pages_widgets.find(item => item.id === payload.id);
    pageWidget.order_on_page = payload.order_on_page;
};

const DELETE_PAGE_WIDGET = (state, pageWidgetId) => {
    state.pages_widgets = state.pages_widgets.filter(function (value) {
        return value.id !== pageWidgetId;
    });
};

export default {
    LOAD_PAGES_WIDGETS,
    ADD_PAGE_WIDGET,
    UPDATE_PAGE_WIDGET_VALUES,
    UPDATE_PAGE_WIDGET_ORDER,
    DELETE_PAGE_WIDGET,
};
