const NOTIFY_SUCCESS = (state, payload) => {
    state.alert = {
        type: 'success',
        message: payload,
        back: false,
    };
};

const NOTIFY_SUCCESS_BACK = (state, payload) => {
    state.alert = {
        type: 'success',
        message: payload,
        back: true,
    };
};

const NOTIFY_ERROR = (state, payload) => {
    state.alert = {
        type: 'error',
        message: payload,
        back: false,
    };
};

const RESET_ALERT = state => {
    state.alert = {
        type: null,
        message: null,
        back: false,
    };
};

export default {
    NOTIFY_SUCCESS,
    NOTIFY_SUCCESS_BACK,
    NOTIFY_ERROR,
    RESET_ALERT,
};
