const getGuestCards = state => {
    return state.guestCards;
};
/* const getCommunityId = (state) =>{
    return state.guestCards;
} */
const getGuestCardById = state => {
    return id => state.guestCards.find(guestCard => guestCard.id === id);
};
const getGuestCardUUID = state => {
    return state.guestCardId;
};

const getGuestCardDetail = state => {
    return state.guestCardDetails;
};

const getCommOptions = state => {
    return state.commOptions;
};
const getUnits = state => {
    return state.units;
};

export default {
    getGuestCardDetail,
    getGuestCards,
    getGuestCardById,
    getGuestCardUUID,
    getCommOptions,
    getUnits,
};
