import getEnvVariable from '@/utils/getEnvVariable';

export default {
    Auth: {
        mandatorySignIn: true,
        region: getEnvVariable('VUE_APP_COGNITO_REGION'),
        userPoolId: getEnvVariable('VUE_APP_COGNITO_USERPOOL_ID'),
        userPoolWebClientId: getEnvVariable('VUE_APP_COGNITO_USERPOOL_WEBCLIENT_ID'),
        // oauth: {
        //   domain: getEnvVariable('VUE_APP_COGNITO_OAUTH_DOMAIN'),
        //   scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        //   redirectSignIn: getEnvVariable('VUE_APP_COGNITO_OAUTH_REDIRECT_URL'),
        //   redirectSignOut: `${getEnvVariable('VUE_APP_COGNITO_OAUTH_REDIRECT_URL')}/logout`,
        //   responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
        // }
    },
};
