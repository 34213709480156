export default {
    profile: '/my/profile',
    floorPlans: '/floor-plans',
    unitTypes: '/unit-types',
    buildingStructureTypes: '/living/buildings/structure-types',
    unitStructureTypes: '/living/buildings/{buildingId}/unit-structure-types',
    livingBuildings: {
        getList: ['GET', '/living/buildings'],
        create: ['POST', '/living/buildings'],
        batchUpdateUnitType: ['PUT', '/living/buildings/{id}/units/all/metadata/unit-type'],
    },
    livingUnits: {
        getAll: ['GET', '/living/units'],
        getList: ['GET', '/living/buildings/{buildingId}/units'],
        create: ['POST', '/living/buildings/{buildingId}/units'],
        getOne: ['GET', '/living/units/{id}'],
        update: ['PUT', '/living/units/{id}'],
        updateUnitType: ['PUT', '/living/units/{unitId}/metadata/unit-type'],
        availableParkingSpaces: ['GET', '/living/units/{unitId}/available-parking-spaces'],
        getUnitLeaseInfo: ['GET', '/living/units/{unitId}/lease'],
    },
    locations: {
        getList: ['GET', '/locations'],
        getOne: ['GET', '/locations/{id}'],
        update: ['PUT', '/locations/{id}'],
        applyPurposeForAll: ['PUT', '/locations/all-with-the-same-type-as/{id}/metadata/businessPurpose'],
        getChildStructureTypes: ['GET', 'locations/{parentId}/child-structure-types/all'],
        create: ['POST', '/locations'],
        delete: ['DELETE', '/locations/{id}'],
    },
    parkingSpaces: {
        getOne: ['GET', '/parking-spaces/{id}'],
        update: ['PUT', '/parking-spaces/{id}'],
    },
    businessPurposes: '/locations/business-purposes',
};
