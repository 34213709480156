const hasPermission = (state, getters, rootState, rootGetters) => {
    return rootGetters['auth/isQuextAdmins'] && +process.env.VUE_APP_DEFAULT_LEASES;
};

const getLease = state => state.lease;

export default {
    hasPermission,
    getLease,
};
