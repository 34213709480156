import store from '@/store';

const permissionChecker = () => store.getters['auth/profile']?.appCodes?.includes('RAA');

export default [
    {
        path: '/raa-tools/locations',
        name: 'raaTools.locations.index',
        component: () => import(/* webpackChunkName: "LocationsList" */ '@/views/auth/raa-devtools/locations/LocationsList'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'locations',
            title: 'locations',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'admin',
            hideFromSearch: true,
            permissionChecker,
        },
    },
    {
        path: '/raa-tools/locations/create',
        name: 'raaTools.locations.create',
        component: () => import(/* webpackChunkName: "LocationCreate" */ '@/views/auth/raa-devtools/locations/LocationCreate'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'add new',
            title: 'add new location',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'admin',
            hideFromSearch: true,
            permissionChecker,
        },
    },
    {
        path: '/raa-tools/locations/:locationId',
        name: 'raaTools.locations.edit',
        component: () => import(/* webpackChunkName: "LocationEdit" */ '@/views/auth/raa-devtools/locations/LocationEdit'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'edit',
            title: 'edit location',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'admin',
            hideFromSearch: true,
            permissionChecker,
        },
    },
    {
        path: '/raa-tools/leases',
        name: 'raaTools.leases.index',
        component: () => import(/* webpackChunkName: "LeasesList" */ '@/views/auth/raa-devtools/leases/LeasesList'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'leases',
            title: 'leases',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'admin',
            hideFromSearch: true,
            permissionChecker,
        },
    },
    {
        path: '/raa-tools/leases/create',
        name: 'raaTools.leases.create',
        component: () => import(/* webpackChunkName: "LeaseCreate" */ '@/views/auth/raa-devtools/leases/LeaseCreate'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'add new',
            title: 'add new lease',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'admin',
            hideFromSearch: true,
            permissionChecker,
        },
    },
    {
        path: '/raa-tools/leases/:id',
        name: 'raaTools.leases.edit',
        component: () => import(/* webpackChunkName: "LeaseEdit" */ '@/views/auth/raa-devtools/leases/LeaseEdit'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'edit',
            title: 'edit lease',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'admin',
            hideFromSearch: true,
            permissionChecker,
        },
    },
    {
        path: '/raa-tools/persons',
        name: 'raaTools.persons.index',
        component: () => import(/* webpackChunkName: "PersonsList" */ '@/views/auth/raa-devtools/persons/PersonsList'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'persons',
            title: 'persons',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'admin',
            hideFromSearch: true,
            permissionChecker,
        },
    },
    {
        path: '/raa-tools/persons/create',
        name: 'raaTools.persons.create',
        component: () => import(/* webpackChunkName: "PersonCreate" */ '@/views/auth/raa-devtools/persons/PersonCreate'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'add new',
            title: 'add new person',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'admin',
            hideFromSearch: true,
            permissionChecker,
        },
    },
    {
        path: '/raa-tools/persons/:personId/reinvite',
        name: 'raaTools.persons.invite.index',
        component: () => import(/* webpackChunkName: "PersonReinvite" */ '@/views/auth/raa-devtools/persons/PersonReinviteView'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 're-invite',
            title: 'resend invitation',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'admin',
            hideFromSearch: true,
            permissionChecker,
        },
    },
    {
        path: '/raa-tools/persons/:personId',
        name: 'raaTools.persons.edit',
        component: () => import(/* webpackChunkName: "PersonEdit" */ '@/views/auth/raa-devtools/persons/PersonEdit'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'edit',
            title: 'edit person',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'admin',
            hideFromSearch: true,
            permissionChecker,
        },
    },
    {
        path: '/raa-tools/memberships',
        name: 'raaTools.memberships.index',
        component: () => import(/* webpackChunkName: "MembershipsList" */ '@/views/auth/raa-devtools/memberships/MembershipsList.vue'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'memberships',
            title: 'memberships',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'admin',
            hideFromSearch: true,
            permissionChecker,
        },
    },
    {
        path: '/raa-tools/memberships/create',
        name: 'raaTools.memberships.create',
        component: () => import(/* webpackChunkName: "MembershipCreate" */ '@/views/auth/raa-devtools/memberships/MembershipCreate'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'add new',
            title: 'add new membership',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'admin',
            hideFromSearch: true,
            permissionChecker,
        },
    },
    {
        path: '/raa-tools/memberships/:membershipId',
        name: 'raaTools.memberships.edit',
        component: () => import(/* webpackChunkName: "MembershipEdit" */ '@/views/auth/raa-devtools/memberships/MembershipEdit'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'edit',
            title: 'edit membership',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'admin',
            hideFromSearch: true,
            permissionChecker,
        },
    },
    {
        path: '/raa-tools/parties',
        name: 'raaTools.parties.index',
        component: () => import(/* webpackChunkName: "PartiesList.vue" */ '@/views/auth/raa-devtools/parties/PartiesList'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'parties',
            title: 'parties',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'admin',
            hideFromSearch: true,
            permissionChecker,
        },
    },
    {
        path: '/raa-tools/parties/create',
        name: 'raaTools.parties.create',
        component: () => import(/* webpackChunkName: "PartyCreate.vue" */ '@/views/auth/raa-devtools/parties/PartyCreate'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'add new',
            title: 'add new party',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'admin',
            hideFromSearch: true,
            permissionChecker,
        },
    },
    {
        path: '/raa-tools/parties/:partyId',
        name: 'raaTools.parties.edit',
        component: () => import(/* webpackChunkName: "PartyEdit.vue" */ '@/views/auth/raa-devtools/parties/PartyEdit'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'edit',
            title: 'edit party',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'admin',
            hideFromSearch: true,
            permissionChecker,
        },
    },
    {
        path: '/raa-tools/applications',
        name: 'raaTools.applications.index',
        component: () => import(/* webpackChunkName: "ApplicationsList" */ '@/views/auth/raa-devtools/applications/ApplicationsList'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'applications',
            title: 'applications',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'admin',
            hideFromSearch: true,
            permissionChecker,
        },
    },
    {
        path: '/raa-tools/applications/:code/details',
        name: 'raaTools.applications.details',
        component: () => import(/* webpackChunkName: "ApplicationDetails" */ '@/views/auth/raa-devtools/applications/ApplicationDetails'),
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'application details',
            title: 'application',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'admin',
            hideFromSearch: true,
            permissionChecker,
        },
    },
    {
        path: '/raa-tools/applications/create',
        name: 'raaTools.applications.create',
        component: () => import(/* webpackChunkName: "ApplicationCreate" */ '@/views/auth/raa-devtools/applications/ApplicationCreate'),
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'add new',
            title: 'add new application',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'admin',
            hideFromSearch: true,
            permissionChecker,
        },
    },
    {
        path: '/raa-tools/applications/:code',
        name: 'raaTools.applications.edit',
        component: () => import(/* webpackChunkName: "ApplicationEdit" */ '@/views/auth/raa-devtools/applications/ApplicationEdit'),
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'edit',
            title: 'edit application',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'admin',
            hideFromSearch: true,
            permissionChecker,
        },
    },
    {
        path: '/raa-tools/profiles',
        name: 'raaTools.profiles.index',
        component: () => import(/* webpackChunkName: "ProfilesList" */ '@/views/auth/raa-devtools/profiles/ProfilesList'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'profiles',
            title: 'profiles',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'admin',
        },
    },
    {
        path: '/raa-tools/profiles/templates',
        name: 'raaTools.profiles.templates.index',
        component: () =>
            import(/* webpackChunkName: "ProfileTemplatesList" */ '@/views/auth/raa-devtools/profiles/templates/ProfileTemplatesList'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'templates',
            title: 'profile templates',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'admin',
            hideFromSearch: true,
            permissionChecker,
        },
    },
    {
        path: '/raa-tools/profiles/templates/create',
        name: 'raaTools.profiles.templates.create',
        component: () =>
            import(/* webpackChunkName: "ProfileTemplateCreate" */ '@/views/auth/raa-devtools/profiles/templates/ProfileTemplateCreate'),
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'add new',
            title: 'add new template',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'admin',
            hideFromSearch: true,
            permissionChecker,
        },
    },
    {
        path: '/raa-tools/profiles/templates/:id',
        name: 'raaTools.profiles.templates.edit',
        component: () =>
            import(/* webpackChunkName: "ProfileTemplateEdit" */ '@/views/auth/raa-devtools/profiles/templates/ProfileTemplateEdit'),
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'edit',
            title: 'edit template',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'admin',
            hideFromSearch: true,
            permissionChecker,
        },
    },
    {
        path: '/raa-tools/profiles/:profileId/shared-data-blocks',
        name: 'raaTools.profiles.dataBlocks.create',
        component: () =>
            import(
                /* webpackChunkName: "SharedProfileDataCreate" */ '@/views/auth/raa-devtools/profiles/authorization-data/SharedProfileDataCreate'
            ),
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'add data block',
            title: 'add new data block',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'admin',
            hideFromSearch: true,
            permissionChecker,
        },
    },
    {
        path: '/raa-tools/profiles/:profileId/shared-data-blocks/:name/version/:version/clone',
        name: 'raaTools.profiles.dataBlocks.clone',
        component: () =>
            import(
                /* webpackChunkName: "SharedProfileDataCreate" */ '@/views/auth/raa-devtools/profiles/authorization-data/SharedProfileDataCreate'
            ),
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'duplicate',
            title: 'duplicate data block',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'admin',
            hideFromSearch: true,
            permissionChecker,
        },
    },
    {
        path: '/raa-tools/profiles/:profileId/shared-data-blocks/:name/version/:version',
        name: 'raaTools.profiles.dataBlocks.edit',
        component: () =>
            import(
                /* webpackChunkName: "SharedProfileDataEdit" */ '@/views/auth/raa-devtools/profiles/authorization-data/SharedProfileDataEdit'
            ),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'edit data block',
            title: 'edit data block',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'admin',
            hideFromSearch: true,
            permissionChecker,
        },
    },
    {
        path: '/raa-tools/profiles/:profileId/details',
        name: 'raaTools.profiles.details',
        component: () => import(/* webpackChunkName: "ProfileDetails" */ '@/views/auth/raa-devtools/profiles/ProfileDetails'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'profile details',
            title: 'profile details',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'admin',
            hideFromSearch: true,
            permissionChecker,
        },
    },
    {
        path: '/raa-tools/profiles/create',
        name: 'raaTools.profiles.create',
        component: () => import(/* webpackChunkName: "ProfileCreate" */ '@/views/auth/raa-devtools/profiles/ProfileCreate'),
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'add new',
            title: 'add new profile',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'admin',
            permissionChecker,
        },
    },
    {
        path: '/raa-tools/profiles/:profileId',
        name: 'raaTools.profiles.edit',
        component: () => import(/* webpackChunkName: "ProfileEdit" */ '@/views/auth/raa-devtools/profiles/ProfileEdit'),
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'edit',
            title: 'edit profile',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'admin',
            hideFromSearch: true,
            permissionChecker,
        },
    },
    {
        path: '/raa-tools/shared-data-keys',
        name: 'raaTools.sharedDataKeys.index',
        component: () =>
            import(/* webpackChunkName: "SharedDataKeysList" */ '@/views/auth/raa-devtools/shared-data-keys/SharedDataKeysList'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'shared data keys',
            title: 'shared data keys',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'admin',
            permissionChecker,
        },
    },
    {
        path: '/raa-tools/shared-data-keys/create',
        name: 'raaTools.sharedDataKeys.create',
        component: () =>
            import(/* webpackChunkName: "SharedDataKeyCreate" */ '@/views/auth/raa-devtools/shared-data-keys/SharedDataKeyCreate'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'add new',
            title: 'add new shared data key',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'admin',
            permissionChecker,
        },
    },
    {
        path: '/raa-tools/shared-data-keys/:id',
        name: 'raaTools.sharedDataKeys.edit',
        component: () => import(/* webpackChunkName: "SharedDataKeyEdit" */ '@/views/auth/raa-devtools/shared-data-keys/SharedDataKeyEdit'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'edit',
            title: 'edit shared data key',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'admin',
            hideFromSearch: true,
            permissionChecker,
        },
    },
    {
        path: '/raa-tools/relationship-types',
        name: 'raaTools.relationshipTypes.index',
        component: () =>
            import(/* webpackChunkName: "RelationshipTypesList" */ '@/views/auth/raa-devtools/relationship-types/RelationshipTypesList'),
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'relationship types',
            title: 'relationship types',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'admin',
            permissionChecker,
        },
    },
    {
        path: '/raa-tools/relationship-types/:type/details',
        name: 'raaTools.relationshipTypes.details',
        component: () =>
            import(
                /* webpackChunkName: "RelationshipTypeDetails" */ '@/views/auth/raa-devtools/relationship-types/RelationshipTypeDetails'
            ),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'details',
            title: 'relationship type',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'admin',
            hideFromSearch: true,
            permissionChecker,
        },
    },
    {
        path: '/raa-tools/relationship-types/:type/details/data-block/create',
        name: 'raaTools.relationshipTypes.dataBlock.create',
        component: () =>
            import(/* webpackChunkName: "DataBlockCreate" */ '@/views/auth/raa-devtools/relationship-types/data-block/DataBlockCreate'),
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'add new',
            title: 'add new data block',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'admin',
            hideFromSearch: true,
            permissionChecker,
        },
    },
    {
        path: '/raa-tools/relationship-types/:type/details/data-block/:name/version/:version/clone',
        name: 'raaTools.relationshipTypes.dataBlock.clone',
        component: () =>
            import(/* webpackChunkName: "DataBlockCreate" */ '@/views/auth/raa-devtools/relationship-types/data-block/DataBlockCreate'),
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'duplicate',
            title: 'duplicate data block',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'admin',
            hideFromSearch: true,
            permissionChecker,
        },
    },
    {
        path: '/raa-tools/relationship-types/:type/details/data-block/:name/version/:version',
        name: 'raaTools.relationshipTypes.dataBlock.edit',
        component: () =>
            import(/* webpackChunkName: "DataBlockEdit" */ '@/views/auth/raa-devtools/relationship-types/data-block/DataBlockEdit'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'edit',
            title: 'edit data block',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'admin',
            hideFromSearch: true,
            permissionChecker,
        },
    },
];
