//import store from '@/store';

//const createPermissionChecker = (permissionId) => () => store.getters['iot/hasPermission'](permissionId);

export default [
    {
        routeName: 'iot.users.index',
        items: [
            {
                title: 'add new user',
                routeName: 'iot.users.create',
                params: {},
            },
        ],
    },
];
