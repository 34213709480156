<template>
  <FinalField :name="name" :validate="validate" ref="field">
    <template v-slot="props">
      <div class="form-col">

        <label v-if="label" :for="name">{{label}}</label>

        <TextInput
          ref="input"
          v-if="editMode"
          v-bind="$attrs"
          :value="props.value"
          :name="props.name"
          :class="[inputClass, {error: props.meta.error && props.meta.touched}]"
          v-on="{...props.events, input: val => props.change(val)}"
          @clear="clearValue"
        >
          <template v-if="!!$slots['leading-icon']" v-slot:leading-icon>
            <slot name="leading-icon" />
          </template>

          <template v-if="!!$slots['trailing-icon']" v-slot:trailing-icon>
            <slot name="trailing-icon" />
          </template>
        </TextInput>

        <TextInput
          v-if="!editMode"
          v-bind="$attrs"
          :value="props.value"
          :class="inputClass"
          readOnly
        />

        <span class="form-hint" v-if="hint">{{ hint }}</span>
        <FieldError v-if="!suppressError" :name="name" />
      </div>
    </template>
  </FinalField>
</template>

<script>
import {FinalField} from 'vue-final-form';
import TextInput from '@/components/ui/TextInput';
import FieldError from "@/components/form/FieldError";

  export default {
    name: 'TextField',

    inheritAttrs: false,

    components: {
      FinalField,
      TextInput,
      FieldError,
    },

    props: {
      name: {
        type: String,
        required: true,
      },

      label: {
        type: String,
      },

      inputClass: {
        type: String,
      },

      validate: {
        type: [Function, Array],
        required: false,
      },

      editMode: {
        type: Boolean,
        default: true,
      },

      suppressError: {
        type: Boolean,
        required: false,
        default: false
      },

      hint: {
        type: String,
        required: false,
      },
    },

    emits: ['clear'],

    methods: {
      clearValue() {
        this.$refs.field.fieldState.change('');
        this.$emit('clear');
      },

      focus() {
        this.$refs.input.focus();
      },
    },
  };
</script>
