export default [
    {
        routeName: 'integrations.edit',
        items: [
            {
                title: 'manage purposes',
                routeName: 'integrations.purposes',
                params: {
                    partnerId: '{partnerId}',
                },
            },
        ],
    },
];
