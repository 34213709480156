export default [
    {
        routeName: 'msgs.index',
        items: [
            {
                title: 'add new message',
                routeName: 'msgs.create',
                params: {},
            },
        ],
    },
    {
        routeName: 'msgs.details',
        items: [
            {
                title: 'duplicate',
                routeName: 'msgs.duplicate',
                params: {
                    messagingId: '{messagingId}',
                },
            },
        ],
    },
];
