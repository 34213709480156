import DataProvider from '@/api/DataProvider.js';
import HttpAdapter from '@/api/adapters/HttpAdapter';
import resources from './resources';
import mocks from './mocks';
import httpClient from '@/api/httpClient';
import getEnvVariable from '@/utils/getEnvVariable';
import MockAdapter from '@/api/adapters/MockAdapter';

const baseURL = getEnvVariable('VUE_APP_RAA_API_URL');

const httpAdapter = new HttpAdapter(httpClient(baseURL), resources);
const mockAdapter = new MockAdapter(mocks);

export default new DataProvider({
    handle: (action, resource, params) => {
        switch (resource) {
        case '':
            return mockAdapter.handle(action, resource, params);
        default:
            return httpAdapter.handle(action, resource, params);
        }
    },
});
