import { v4 as uuid } from 'uuid';

const tree = [
    {
        name: 'Building 1',
        type: 'mixed use building',
        category: 'building',
        children: [
            {
                name: '101',
                type: 'private apartment',
                category: 'apartment',
                children: [
                    {
                        name: 'Kitchen',
                        type: 'residential kitchen',
                        category: 'room',
                    },
                    {
                        name: 'Bathroom',
                        type: 'residential bathroom',
                        category: 'room',
                    },
                    {
                        name: 'Living',
                        type: 'living',
                        category: 'room',
                    },
                    {
                        name: 'Bedroom 1',
                        type: 'bedroom',
                        category: 'room',
                    },
                    {
                        name: 'Bedroom 2',
                        type: 'bedroom',
                        category: 'room',
                    },
                ],
            },
            {
                name: '102',
                type: 'private apartment',
                category: 'apartment',
                children: [
                    {
                        name: 'Kitchen',
                        type: 'residential kitchen',
                        category: 'room',
                    },
                    {
                        name: 'Bathroom',
                        type: 'residential bathroom',
                        category: 'room',
                    },
                    {
                        name: 'Living',
                        type: 'living',
                        category: 'room',
                    },
                    {
                        name: 'Bedroom 1',
                        type: 'bedroom',
                        category: 'room',
                    },
                    {
                        name: 'Bedroom 2',
                        type: 'bedroom',
                        category: 'room',
                    },
                ],
            },
            {
                name: '103',
                type: 'private apartment',
                category: 'apartment',
            },
            {
                name: '104',
                type: 'private apartment',
                category: 'apartment',
            },
        ],
    },
    {
        name: 'Building 2',
        type: 'mixed use building',
        category: 'building',
        children: [
            {
                name: '101',
                type: 'private apartment',
                category: 'apartment',
                children: [
                    {
                        name: 'Kitchen',
                        type: 'residential kitchen',
                        category: 'room',
                    },
                    {
                        name: 'Bathroom',
                        type: 'residential bathroom',
                        category: 'room',
                    },
                    {
                        name: 'Living',
                        type: 'living',
                        category: 'room',
                    },
                    {
                        name: 'Bedroom 1',
                        type: 'bedroom',
                        category: 'room',
                    },
                    {
                        name: 'Bedroom 2',
                        type: 'bedroom',
                        category: 'room',
                    },
                ],
            },
            {
                name: '102',
                type: 'private apartment',
                category: 'apartment',
            },
        ],
    },
];

const transform = (subtree, parentId, entities = []) => {
    subtree.forEach(item => {
        const id = uuid();
        const children = item.children || [];

        transform(children, id, entities);

        const entity = {
            id,
            ...item,
            parentId,
            children: null,
            hasChildren: children.length > 0,
        };

        entities.push(entity);
    });

    return entities;
};

const floorPlans = [
    {
        id: 'f6fa4386-d0a3-4a68-862a-881eb76e82c3',
        name: 'The Albany',
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aut est et laborum recusandae repudiandae vitae.',
        virtualTour: {
            url: 'https://example.com/some/tour-url',
        },
        image: {
            id: '92f33d74-714f-4cbf-be9e-4407db965c96',
        },
    },
    {
        id: '80e906cc-c1a4-44b2-9902-9aa462de47bc',
        name: 'The Lexington',
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aut est et laborum recusandae repudiandae vitae.',
        virtualTour: {
            url: 'https://example.com/some/tour-url',
        },
        image: {
            id: '3a09d376-3b8b-4266-bf56-e8acf14df2a0',
        },
    },
    {
        id: '968baf00-fc77-419d-92e0-ee00aba17dd1',
        name: 'The Cambridge',
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aut est et laborum recusandae repudiandae vitae.',
        virtualTour: {
            url: 'https://example.com/some/tour-url',
        },
        image: {
            id: 'd6607a29-b1f6-4854-8fe9-02a99a474221',
        },
    },
];

const unitTypes = [
    {
        id: '942a4406-704d-4a18-9a79-d64a70d7bbb3',
        name: 'A2',
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aut est et laborum recusandae repudiandae vitae.',
        floorPlan: floorPlans[0],
        sqft: 715,
        bedrooms: 1,
        bathrooms: 1,
        marketRentPrice: 1159,
        rentPrice: 1200,
        averageRent: 1880,
        occupiedCount: 16,
        totalCount: 32,
        averageAmenity: 300,
    },
    {
        id: 'f50930b8-887a-447d-af38-43f19ac0a69a',
        name: 'A4',
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aut est et laborum recusandae repudiandae vitae.',
        floorPlan: floorPlans[1],
        sqft: 715,
        bedrooms: 1,
        bathrooms: 1,
        marketRentPrice: 1159,
        rentPrice: 1200,
        averageRent: 1880,
        occupiedCount: 16,
        totalCount: 32,
        averageAmenity: 100,
    },
    {
        id: '46c55cce-3def-4aa3-ad5d-bbe6fd070686',
        name: 'C1',
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aut est et laborum recusandae repudiandae vitae.',
        floorPlan: floorPlans[2],
        sqft: 715,
        bedrooms: 1,
        bathrooms: 1,
        marketRentPrice: 1159,
        rentPrice: 1200,
        averageRent: 1880,
        occupiedCount: 16,
        totalCount: 32,
        averageAmenity: 200,
    },
];

const residents = [
    { id: 1, name: 'Dan Dyson' },
    { id: 2, name: 'Denice Williams' },
    { id: 3, name: 'Tiffany Garcia' },
    { id: 4, name: 'Matt Garcia' },
];

const livingBuildings = Array.from({ length: 4 }, (_, index) => {
    return {
        id: uuid(),
        name: `Building ${index + 1}`,
        totalUnits: Math.floor(Math.random() * 4 + 12),
        leasedUnits: Math.floor(Math.random() * 4 + 8),
        type: 'building',
    };
});

const livingUnits = livingBuildings.flatMap(building => {
    return Array.from({ length: building.totalUnits }, (_, index) => {
        return {
            id: uuid(),
            name: (100 + index + 1).toString(),
            type: unitTypes[Math.floor(Math.random() * (unitTypes.length + 3))]?.name,
            buildingId: building.id,
            rentPrice: '780',
            marketRentPrice: '780',
            status: 'rented',
            availabilityDate: '2021-12-20T12:00:00Z',
            residents: residents.slice(0, Math.random() * residents.length + 1),
        };
    });
});

export default {
    locations: transform(tree, '00000000-0000-0000-0000-000000000000'),

    livingBuildings,
    allLivingBuildings: livingBuildings,

    livingUnits,
    allLivingUnits: livingUnits,

    unitTypes,
    allUnitTypes: unitTypes,

    floorPlans: floorPlans.map(floorPlan => ({
        ...floorPlan,
        unitTypes: unitTypes
            .filter(unitType => unitType.floorPlan.id === floorPlan.id)
            .map(unitType => ({
                id: unitType.id,
                name: unitType.name,
            })),
    })),

    leaseHistory: [
        {
            token: '111',
            value: {
                id: 'aaa',
                startDate: '06/20/2019',
                expirationDate: '06/20/2021',
                residents: ['John Doe', 'Jane Doe', 'Clark Kent', 'Arthur Carry', 'John Smith'],
                rentPrice: 750,
            },
        },
        {
            token: '222',
            value: {
                id: 'bbb',
                startDate: '06/20/2019',
                expirationDate: '06/20/2021',
                residents: ['John Doe', 'Jane Doe'],
                rentPrice: 750,
            },
        },
        {
            token: '333',
            value: {
                id: 'ccc',
                startDate: '06/20/2019',
                expirationDate: '06/20/2021',
                residents: ['John Doe', 'Jane Doe'],
                rentPrice: 750,
            },
        },
    ],
};
