const routes = [
    {
        name: 'manage_application.index',
        path: '/applications-list',
        component: () => {
            return import('@/views/resident_lifecycle/application_service/Index.vue');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'applications',
            title: 'applications',
            size: 'large',
            flowStartsWith: 'manage_application.index',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'application_service',
        },
    },
    {
        name: 'manage_application.details',
        path: '/applicantion/details',
        component: () => {
            return import('@/views/resident_lifecycle/application_service/Application.vue');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'application details',
            title: 'application details',
            size: 'large',
            flowStartsWith: 'manage_application.index',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'application_service',
        },
    },
    {
        name: 'manage_application.add_person',
        path: '/applicantion/guarantor/add-person',
        component: () => {
            return import('@/views/resident_lifecycle/application_service/Guarantor/AddPerson.vue');
        },
        meta: {
            loadInModal: true,
            breadcrumbName: 'add person',
            title: 'add person',
            size: 'normal',
            flowStartsWith: 'manage_application.details',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'application_service',
        },
    },
    {
        name: 'manage_application.create_profile',
        path: '/applicantion/guarantor/create-profile',
        component: () => {
            return import('@/views/resident_lifecycle/application_service/Guarantor/CreateProfile.vue');
        },
        meta: {
            loadInModal: true,
            breadcrumbName: 'create profile',
            title: 'create profile',
            size: 'normal',
            flowStartsWith: 'manage_application.details',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'application_service',
        },
    },
];

export default routes;
