const actions = [
    {
        routeName: 'guest-cards.index',
        items: [
            {
                title: 'Add new',
                routeName: 'guest-cards.create',
            },
            {
                title: 'reports',
                routerName: '#',
            },
            {
                title: 'settings',
                routeName: 'guest-cards.settings',
            },
        ],
    },
    {
        routeName: 'guest-cards.show',
        items: [
            // TODO: update to proper route
            {
                title: 'calculate move in',
                routeName: 'guest-cards.show',
            },
            // TODO: update to proper route
            {
                title: 'convert to applicant',
                routeName: 'guest-cards.show',
            },
            // TODO: update to proper route
            {
                title: 'change status',
                routeName: 'guest-cards.show',
            },
            // TODO: update to proper route
            {
                title: 'archive',
                routeName: 'guest-cards.show',
            },
        ],
    },
    {
        routeName: 'guest-cards.show',
        items: [
            {
                id: 'guest-card-archive',
                title: 'archive',
                showConfirmation: true,
                confirmationMessage: 'what is the reason for archiving?',
                confirmBtnText: 'archive',
                confirmationType: 'warning',
                confirmationCustomComponent: () => {
                    return import('@/components/guest_cards/actions/Archive');
                },
            },
        ],
    },
];

export default actions;
