<template>
    <div class="h-full">
        <loader :loading="loading" :backdrop="true" />

        <file-manager-title>
            <span class="text-highlight-textSmall">edit image</span>
        </file-manager-title>

        <image-editor
            :image="file.base64"
            :aspect-ratio="manager.uploader.cropperOptions.aspectRatio"
            :crop-box-resizable="manager.uploader.cropperOptions.cropBoxResizable"
            :zoom-on-wheel="manager.uploader.cropperOptions.zoomOnWheel"
            @image-cropped="cropImage"
            v-if="!loading && file && file.base64"
        >
        </image-editor>

        <div class="absolute bottom-0 left-0 w-full px-8 py-6 flex justify-between bg-gray-200">
            <button
                class="btn-primary btn-transparent"
                :disabled="loading"
                @click="close"
            >
                cancel
            </button>
            <button
                class="btn-primary btn-solid"
                @click="edit"
                :disabled="loading"
            >
                edit
            </button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import Loader from "@/components/ui/Loader";
import NotifyMixin from "@/mixins/NotifyMixin";
import ImageEditor from "@/components/ui/filemanager/ImageEditor";
import FileManagerTitle from "@/components/ui/filemanager/FileManagerTitle";

export default {
    mixins: [ NotifyMixin ],
    props: {
        managerId: {
            type: String,
            required: true
        },
        fileUuid: {
            type: String,
            required: true,
        }
    },
    emits: ['close'],
    components: { Loader, FileManagerTitle, ImageEditor },
    data: () => {
        return {
            loading: true,
            manager: {},
            file: {
                base64: null
            },
        }
    },
    computed: {
        ...mapGetters({
            getManagerById: 'files/getManagerById',
        }),
    },
    methods: {
        cropImage(payload) {
            this.file.base64 = payload.image;
        },
        edit() {
            this.loading = true;

            this.$filemanagerDataProvider.update('updateFile', {
                id: this.fileUuid,
                data: { file: this.file.base64 }
            }).then( (response) => {
                window.console.log(response);
            }).catch( () => {
                this.notifyError('api error updating file.');
            })
            .finally( () => {
                this.loading = false;
            });
        },
        close() {
            this.$emit('close');
        }
    },
    created() {
        this.manager = this.getManagerById(this.managerId);
    },
    mounted() {
        this.loading = true;
        this.$filemanagerDataProvider.get("fileInfo", { fileId: this.fileUuid })
            .then( (response) => {
                if(!response.base64) {
                    this.notifyError('api did not return base64 image.');
                }
                this.file = response;
            }).catch( () => {
                this.notifyError('api error fetching file.');
            })
            .finally( () => {
                this.loading = false;
            });
    }
}
</script>
