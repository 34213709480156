const routes = [
    {
        name: 'renewals.index',
        path: '/renewals',
        component: () => {
            return import('@/views/resident_lifecycle/renewals/Index');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'renewal offers',
            title: 'renewal offers',
            size: 'normal',
            flowStartsWith: 'renewals.index',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'move-in',
        },
    },
    {
        name: 'renewals.editRenewalOffer',
        path: '/edit-renewal-offer',
        component: () => {
            return import('@/views/resident_lifecycle/renewals/EditRenewalOffer');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'edit renewal offer',
            title: 'edit renewal offer',
            size: 'normal',
            flowStartsWith: 'renewals.editRenewalOffer',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'move-in',
        },
    },
    {
        name: 'renewals.editOfferLetter',
        path: '/edit-offer-letter',
        component: () => {
            return import('@/views/resident_lifecycle/renewals/EditOfferLetter');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'edit offer letter',
            title: 'edit offer letter',
            size: 'small',
            flowStartsWith: 'renewals.editOfferLetter',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'move-in',
        },
    },
    {
        name: 'renewals.chat',
        path: '/renewal-chat',
        component: () => {
            return import('@/views/resident_lifecycle/renewals/Chat');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'renewal offer chat',
            title: 'renewal offer chat',
            size: 'small',
            flowStartsWith: 'renewals.editOfferLetter',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'move-in',
        },
    },
];

export default routes;
