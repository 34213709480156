const routes = [
    {
        name: 'guest_contacts.index',
        path: '/guest-contacts',
        component: () => {
            return import('@/views/digital_human/guest_contacts/Index');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'contacts',
            title: 'contacts',
            size: 'normal',
            flowStartsWith: 'guest_contacts.index',
            allowedRoles: ['CUSTOMER_ADMIN', 'SUPER_ADMIN', 'USER'],
            requiredAppAccess: ['MIA'],
            product: 'dh',
        },
    },
    {
        name: 'guest_contacts.details',
        path: '/guest-contacts/details/:id',
        component: () => {
            return import('@/views/digital_human/guest_contacts/Details');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'contact details',
            size: 'normal',
            flowStartsWith: 'guest_contacts.details',
            allowedRoles: ['CUSTOMER_ADMIN', 'SUPER_ADMIN', 'USER'],
            requiredAppAccess: ['MIA'],
            product: 'dh',
        },
    },
];

export default routes;
