export default {
    amenities: {
        get: ['GET', '/amenities'],
        getList: ['GET', '/amenities'],
        getOne: ['GET', '/amenities/{id}'],
        create: ['POST', '/amenities'],
        update: ['PATCH', '/amenities/{id}'],
        delete: ['DELETE', '/amenities/{id}'],
    },
    communityAmenities: {
        get: ['GET', '/communities/amenities'],
        getList: ['GET', '/communities/amenities'],
        getOne: ['GET', '/communities/amenities/{id}'],
        update: ['PATCH', '/communities/amenities/{id}'],
    },

    amenityCommunities: '/amenities/{amenityId}/communities/locs-count',
    amenityLocations: '/amenities/{amenityId}/communities/{communityId}/locations',
    communityAmenityLocations: '/communities/amenities/{amenityId}/locations',

    amenityMembership: '/amenities/{id}/groups',
    communityAmenityMembership: '/communities/amenities/{id}/groups',

    communityChargeCodes: '/communities/charge-codes',

    groups: '/groups',
    communityGroups: {
        get: ['GET', '/communities/groups'],
        getList: ['GET', '/communities/groups'],
        getOne: ['GET', '/communities/groups/{id}'],
        update: ['PATCH', '/communities/groups/{id}'],
    },

    groupCommunities: '/groups/{groupId}/communities/locs-count',
    groupLocations: '/groups/{groupId}/communities/{communityId}/locations',
    communityGroupLocations: '/communities/groups/{groupId}/locations',

    pricingUnits: '/amenities/pricing-units',

    communities: '/communities',
    communitiesAssignments: '/communities/assignments',
    communitiesAssignmentsGroupItems: '/communities/assignments/groups/{groupId}/items',
    communityAssignments: {
        getList: ['GET', '/communities/{communityId}/assignments'],
        update: ['PUT', '/communities/{communityId}/assignments'],
    },

    locations: {
        search: ['GET', '/locs2/search'],
    },
    /**
     * community amenities
     */
    locationsAssignments: '/locations/assignments',
    /**
     * community amenities items
     */
    locationsAssignmentsGroupItems: '/locations/assignments/groups/{groupId}/items',
    /**
     * location amenities
     */
    locationAssignments: {
        getList: ['GET', '/locations/{locationId}/assignments'],
        update: ['PUT', '/locations/{locationId}/assignments'],
        getOne: ['GET', '/locations/{locationId}/{type}/{locationAmenityId}'],
        delete: ['DELETE', '/locations/{locationId}/{type}/{locationAmenityId}'],
    },
    /**
     * community amenities ids, assigned to the unit
     */
    locationAssignmentsIds: '/locations/{locationId}/assignments/id',

    permissions: '/my/permissions',
};
