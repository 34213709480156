import { parsePhoneNumberFromString } from "libphonenumber-js";

export {
  dispatchAuthStateChangeEvent,
  dispatchToastHubEvent
} from '@aws-amplify/ui-components/dist/collection/common/helpers';

export {handleSignIn, checkContact} from '@aws-amplify/ui-components/dist/collection/common/auth-helpers';

export const prepareCognitoUsername = (value) => {
  if (!value) {
    return '';
  }

  // test US phone number
  const usPhoneNumber = parsePhoneNumberFromString(value, 'US');
  if (usPhoneNumber?.isPossible()) {
    return usPhoneNumber.number;
  }

  // test other phone numbers
  const valueToTest = value[0] === '+' ? value : `+${value}`;
  const phoneNumber = parsePhoneNumberFromString(valueToTest);
  if (phoneNumber?.isPossible()) {
    return phoneNumber.number;
  }

  return value.trim();
}