import store from '@/store';

const createPermissionChecker = permissionId => () => store.getters['ri/hasPermission'](permissionId);

export default [
    {
        routeName: 'ri.index',
        items: [
            {
                title: 'add new rentable item',
                routeName: 'ri.create',
                params: {},
                allowed: createPermissionChecker('RI_EDIT'),
            },
        ],
    },
    // {
    //   routeName: 'ri.assets',
    //   items: [
    //     {
    //       title: 'deactivated assets',
    //       routeName: 'ri.deactivatedAssets',
    //       params: {},
    //       allowed: createPermissionChecker('RI_VIEW'),
    //     },
    //   ],
    // },
];
