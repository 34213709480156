const getDashboard = state => state.dashboard;
const getDashboardPreferences = state => state.dashboard.preferences;
const getDashboardGraphColors = state => state.dashboard.graphColors;
const getDashboardGraphColor = state => key => state.dashboard.graphColors ? state.dashboard.graphColors[key] : null;

export default {
    getDashboard,
    getDashboardPreferences,
    getDashboardGraphColors,
    getDashboardGraphColor,
};
