const pushModal = ({ commit }, payload) => {
    commit('PUSH_MODAL', {
        template: payload.template,
        routeName: payload.routeName,
        params: payload.params,
        breadcrumbName: payload.breadcrumbName,
        title: payload.title,
    });
};

const removeModalByRouteName = ({ commit }, routeName) => {
    commit('REMOVE_MODAL_BY_ROUTE_NAME', routeName);
};

const popAllModals = ({ commit }) => {
    commit('POP_ALL_MODALS');
};

const setActiveModalTitle = ({ commit }, title) => {
    commit('SET_ACTIVE_MODAL_TITLE', title);
};

const setActiveModalBreadcrumbName = ({ commit }, name) => {
    commit('SET_ACTIVE_MODAL_BREADCRUMB_NAME', name);
};

export default {
    pushModal,
    popAllModals,
    removeModalByRouteName,
    setActiveModalTitle,
    setActiveModalBreadcrumbName,
};
