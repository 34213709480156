import store from '@/store';

const permissionChecker = () => store.getters['auth/profile']?.appCodes?.includes('AUTH');

const routes = [
    {
        name: 'quextUsers.index',
        path: '/quext-users',
        component: () => import(/* webpackChunkName: "quextUsers" */ '@/views/auth/quextUsers/QuextUsersList'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'quext users',
            title: 'quext users',
            flowStartsWith: 'quextUsers.index',
            allowedRoles: ['SUPER_ADMIN', 'SUPER_READONLY_ADMIN'],
            product: 'qxt-admin',
            permissionChecker,
        },
    },
    {
        name: 'quextUsers.create',
        path: '/quext-users/create',
        component: () => import(/* webpackChunkName: "quextUsersCreate" */ '@/views/auth/quextUsers/QuextUsersCreate'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'add new',
            title: 'add new quext user',
            flowStartsWith: 'quextUsers.create',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'qxt-admin',
            permissionChecker,
        },
    },
    {
        path: '/quext-users/:id/reinvite',
        name: 'quextUsers.invite.index',
        component: () => import(/* webpackChunkName: "quextUsersEdit" */ '@/views/auth/quextUsers/QuextUserReinviteView'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 're-invite',
            title: 'resend invitation',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'qxt-admin',
            hideFromSearch: true,
            permissionChecker,
        },
    },
    {
        name: 'quextUsers.edit',
        path: '/quext-users/:id',
        component: () => import(/* webpackChunkName: "quextUsersEdit" */ '@/views/auth/quextUsers/QuextUsersEdit'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'edit',
            title: 'edit quext user',
            flowStartsWith: 'quextUsers.index',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'qxt-admin',
            hideFromSearch: true,
            permissionChecker,
        },
    },
    {
        path: '/deleted-quext-users',
        name: 'quextUsers.deletedQuextUsers.index',
        component: () => import(/* webpackChunkName: "deletedQuextUsers" */ '@/views/auth/quextUsers/DeletedQuextUsersList'),
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'deleted quext users',
            title: 'deleted quext users',
            flowStartsWith: 'quextUsers.index',
            allowedRoles: ['SUPER_ADMIN', 'SUPER_READONLY_ADMIN'],
            product: 'qxt-admin',
            hideFromSearch: true,
            permissionChecker,
        },
    },
    {
        path: '/deleted-quext-users/:id',
        name: 'quextUsers.deletedQuextUsers.details',
        component: () => import(/* webpackChunkName: "quextUsersEdit" */ '@/views/auth/quextUsers/QuextUsersEdit'),
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'view',
            title: 'view quext user',
            flowStartsWith: 'quextUsers.index',
            allowedRoles: ['SUPER_ADMIN', 'SUPER_READONLY_ADMIN'],
            product: 'qxt-admin',
            hideFromSearch: true,
            permissionChecker,
        },
    },
];

export default routes;
