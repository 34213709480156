const all = state => {
    return state.modals;
};

const getFirstModal = state => {
    if (!state.modals.length) return null;

    return state.modals[0];
};

const getLastModal = state => {
    if (!state.modals.length) return null;

    return state.modals[state.modals.length - 1];
};

const getModalByRouteName = state => name => {
    if (!state.modals.length) return null;

    return state.modals.find(modal => modal.routeName === name);
};

const getActiveModalTitle = state => {
    return state.activeModalTitle;
};

const getActiveBreadcrumbName = state => state.activeBreadcrumbName;

export default {
    all,
    getFirstModal,
    getLastModal,
    getModalByRouteName,
    getActiveModalTitle,
    getActiveBreadcrumbName,
};
