import store from '@/store';

const createPermissionChecker = permissionId => () => store.getters['cbr/hasPermission'](permissionId);

export default [
    {
        routeName: 'cbr.unittypes',
        items: [
            {
                title: 'add new unit type',
                routeName: 'cbr.unittypes.create',
                params: {},
                allowed: createPermissionChecker('UTC'),
            },
        ],
    },
    {
        routeName: 'cbr.unittypes.details',
        items: [],
    },
];
