import { hasAccessTo, isQuextAdmin } from '@/utils/actionAllowedRules';

export default [
    {
        routeName: 'customers.index',
        items: [
            {
                title: 'add new customer',
                routeName: 'customers.create',
                params: {},
                allowed: isQuextAdmin,
            },
            {
                title: 'deleted customers',
                routeName: 'customers.deletedCustomers.index',
                params: {},
                allowed: isQuextAdmin,
            },
        ],
    },
    {
        routeName: 'customers.create',
        items: [],
    },
    {
        routeName: 'customers.edit',
        items: [
            {
                title: 'manage communities',
                routeName: 'customer.communities.index',
                params: {
                    customerId: '{customerId}',
                },
            },
            {
                title: 'manage users',
                routeName: 'customer.users.index',
                params: {
                    customerId: '{customerId}',
                },
            },
            {
                title: 'manage admins',
                routeName: 'customer.admins.index',
                params: {
                    customerId: '{customerId}',
                },
            },
            {
                title: 'manage roles',
                routeName: 'customer.roles.index',
                params: {
                    customerId: '{customerId}',
                },
                allowed: isQuextAdmin,
            },
            {
                title: 'manage permissions',
                routeName: 'customer.permissions.index',
                params: {
                    customerId: '{customerId}',
                },
                allowed: isQuextAdmin,
            },
        ],
    },
    // Communities
    {
        routeName: 'customer.communities.index',
        items: [
            {
                title: 'add new community',
                routeName: 'customer.communities.create',
                params: {
                    customerId: '{customerId}',
                },
            },
            {
                title: 'archived communities',
                routeName: 'customer.deletedCommunities.index',
                params: {
                    customerId: '{customerId}',
                },
            },
            {
                title: 'import communities',
                routeName: 'customer.communities.import',
                params: {
                    customerId: '{customerId}',
                },
            },
        ],
    },
    {
        routeName: 'customer.communities.edit',
        items: [
            {
                title: 'manage integration partners',
                routeName: 'customer.communities.integrations.index',
                params: {
                    customerId: '{customerId}',
                    communityId: '{communityId}',
                },
                allowed: hasAccessTo('IPS'),
            },
        ],
    },
    {
        routeName: 'customer.communities.details',
        items: [],
    },
    // Community Integrations
    {
        routeName: 'customer.communities.integrations.index',
        items: [
            {
                title: 'add new partner',
                routeName: 'customer.communities.integrations.create',
                params: {
                    customerId: '{customerId}',
                    communityId: '{communityId}',
                },
            },
        ],
    },
    // Users
    {
        routeName: 'customer.users.index',
        items: [
            {
                title: 'add user',
                routeName: 'customer.users.create',
                params: {
                    customerId: '{customerId}',
                },
            },
            {
                title: 'deleted users',
                routeName: 'customer.deletedUsers.index',
                params: {
                    customerId: '{customerId}',
                },
            },
        ],
    },
    {
        routeName: 'customer.users.details',
        items: [],
    },
    {
        routeName: 'customer.users.create',
        items: [],
    },
    {
        routeName: 'customer.deletedUsers.details',
        items: [],
    },
    // Admins
    {
        routeName: 'customer.admins.index',
        items: [
            {
                title: 'add new admin',
                routeName: 'customer.admins.create',
                params: {
                    customerId: '{customerId}',
                },
            },
            {
                title: 'deleted admins',
                routeName: 'customer.deletedAdmins.index',
                params: {
                    customerId: '{customerId}',
                },
            },
        ],
    },
    {
        routeName: 'customer.admins.details',
        items: [],
    },
    {
        routeName: 'customer.admins.create',
        items: [],
    },
    // Roles
    {
        routeName: 'customer.roles.index',
        items: [
            {
                title: 'deleted roles',
                routeName: 'customer.deletedRoles.index',
                params: {
                    customerId: '{customerId}',
                },
            },
        ],
    },
    // Permissions
    {
        routeName: 'customer.permissions.index',
        items: [
            // {
            //   title: "deleted permissions",
            //   routeName: "customer.deletedPermissions.index",
            //   params: {
            //     customerId: '{customerId}'
            //   },
            // },
        ],
    },
    //  Integrations
    {
        routeName: 'company.integrations.index',
        items: [
            {
                title: 'add new partner',
                routeName: 'company.integrations.create',
                params: {
                    customerId: '{customerId}',
                },
            },
        ],
    },
];
