const routes = [
    {
        name: 'home',
        path: '/',
        component: () => {
            return import('@/views/homepage/home/Index');
        },
    },
];

export default routes;
