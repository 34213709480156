import { Auth } from 'aws-amplify';
import getEnvVariable from "@/utils/getEnvVariable";

export default {
    name: 'ForgetDeviceMixin',
    computed: {
        mfaTrustTime() {
            return +getEnvVariable('VUE_APP_MFA_TRUST_TIME');
        },
    },
    methods: {
        forgetDevice() {
            try {
                Auth.forgetDevice();
            } catch (e) {
                console.error(e);
            }
        },
        async processUserDevice() {
            if (!this.mfaTrustTime) {
                return;
            }

            const user = await Auth.currentUserPoolUser({ bypassCache: true });

            if (!user.preferredMFA || user.preferredMFA === 'NOMFA' || !user.deviceKey) {
                return;
            }

            const devices = await Auth.fetchDevices();

            if (devices.length === 0 || !devices.find(({ id }) => id === user.deviceKey)) {
                return;
            }

            user.getDevice({
                onSuccess: (device) => {
                    const { Device: { DeviceCreateDate, DeviceLastAuthenticatedDate } = {} } = device || {};
                    const shouldForgetDevice = Math.floor(DeviceLastAuthenticatedDate - DeviceCreateDate) * 1000 > this.mfaTrustTime;
                    if (shouldForgetDevice) {
                        this.forgetDevice();
                    }
                },
                onFailure: (err) => {
                    console.error(err);
                },
            });
        },
    },
};

