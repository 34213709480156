export default [
    {
        name: 'iot.users.index',
        path: '/iot-users',
        component: () => import(/* webpackChunkName: "iotUsers" */ '@/views/iot/users/UsersList'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'iot users',
            title: 'iot users',
            flowStartsWith: 'iot.users.index',
            product: 'iot',
            requiredAppAccess: ['IOT'],
        },
    },
    {
        name: 'iot.users.create',
        path: '/iot-users/create',
        component: () => import(/* webpackChunkName: "iotUsers" */ '@/views/iot/users/UsersCreate'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'add new',
            title: 'add new iot user',
            flowStartsWith: 'iot.users.create',
            product: 'iot',
            requiredAppAccess: ['IOT'],
        },
    },
];
