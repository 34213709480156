const hasPermission = (state, getters, rootState, rootGetters) => {
    return rootGetters['auth/appAccesses']?.includes('MSGS') && rootGetters['auth/community'];
};

const tmpBuffer = state => state.tmpBuffer;

const listFilterValues = state => state.list.filterValues;

export default {
    hasPermission,
    tmpBuffer,
    listFilterValues,
};
