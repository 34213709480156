import { kebabCase } from 'lodash-es';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

export function slugify(str) {
    if (!str) return null;

    return kebabCase(str);
}

export function resolveUrl(str) {
    if (!str) return null;

    return str
        .toLowerCase()
        .trim()
        .replace(/^(?!(?:\w+:)?\/\/)/, 'http://');
}

// Search for anything that is surrounded by the brackets, and replaces it with the values from data object
// @example: renderTemplate('{name} details', {name: 'madera'}) will return 'madera details'
export function renderTemplate(str, data) {
    return str.replace(/{(.*?)}/g, match => data[match.split(/[{}]/).filter(Boolean)[0]]);
}

export function formatPhone(phone) {
    return parsePhoneNumberFromString(phone)?.formatNational() ?? '';
}

export function formatCurrency(number, withDollarSign = true) {
    if (withDollarSign && number === 0) {
        return '-';
    }

    const result = number.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    return withDollarSign ? result : result.substring(1);
}
