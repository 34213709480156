<template>
    <transition name="slide-in">
        <div class="modal-confirmation p-10 absolute bottom-0 left-0  text-center w-full z-50" :class="classList" v-if="show">
            <h5 class="modal-confirmation__message">
                {{ message }}
            </h5>

            <p class="modal-confirmation__note">
              {{ note }}
            </p>

            <div class="mt-6">
                <button
                  class="btn-transparent mx-2"
                  :class="buttonClass"
                  @click="cancel"
                >{{ cancelBtnText }}</button>
                <button
                  class="btn-solid mx-2"
                  :class="buttonClass"
                  @click="confirm"
                >{{ confirmBtnText }}</button>
            </div>
        </div>
    </transition>
</template>

<script>
import EventBus from "@/utils/EventBus";
import {slugify} from "@/utils/String";

export default {
    data: () => {
        return {
            show: false,
            action: null,
            message: null,
            type: null,
            confirmBtnText: null,
            cancelBtnText: null,
        }
    },
    computed: {
        classList() {
            let warning = [ 'warning'];
            let success = [ 'success' ];

            return this.type === 'warning' ? warning.join(' ') : success.join(' ');
        },
        confirmationId() {
            if (!this.action.id) {
                console.error('No unique action id for confirmation slide-up');
            }
            return this.action.id ?? slugify(this.action.title);
        },
        buttonClass() {
          return this.type === 'warning' ? 'btn-danger' : 'btn-primary';
        }
    },
    methods: {
        cancel() {
            EventBus.emit(`cancel-${this.confirmationId}`, this.action);
            this.show = false;
            if (!this.action.id) {
                console.error('No unique action id for confirmation slide-up');
            }
            let id = this.action.id ?? slugify(this.action.title);

            EventBus.emit(`cancel-confirmation-${id}`);
        },
        confirm() {
            EventBus.emit(`confirm-${this.confirmationId}`, this.action);
            this.show = false;
        },
        showModalConfirmation(action) {
            this.action = action;
            this.show = true;
            this.message = this.action.confirmationMessage ?? 'Are you sure you want to perform this action?';
            this.note = this.action.confirmationNote ?? null;
            this.confirmBtnText = this.action.confirmBtnText ?? 'confirm';
            this.cancelBtnText = this.action.cancelBtnText ?? 'cancel';
            this.type = this.action.confirmationType ?? 'success';
        },
        hideModalConfirmation() {
          if (this.show) {
            this.cancel();
          }
        },
    },
    mounted() {
        EventBus.on('show-modal-confirmation', this.showModalConfirmation);
        EventBus.on('hide-modal-confirmation', this.hideModalConfirmation);
    },
    unmounted() {
        EventBus.off('show-modal-confirmation', this.showModalConfirmation);
        EventBus.off('hide-modal-confirmation', this.hideModalConfirmation);
    },
}
</script>

<style scoped>
.modal-confirmation.success{
    @apply bg-blue-100 text-blue-900
}
.modal-confirmation.warning{
    @apply bg-red-200 text-red-900
}

.warning .btn-primary{
    @apply text-white font-inter font-semibold py-3 px-6 border;
    background-color: #c75053;
    border-color: #c75053;
    letter-spacing: .5px;
}

.slide-in-enter-active {
    transition: all .3s ease;
}
.slide-in-leave-active {
    transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-in-enter-from, .slide-in-leave-to{
    transform: translateY(100%);
    opacity: 0;
}

.modal-confirmation__message {
  @apply font-medium font-frank text-lg mx-auto w-2/3;
}

.modal-confirmation__note {
  @apply font-inter font-normal text-2sm;
}
</style>
