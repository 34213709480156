<template>
  <div v-if="isError" class="form-error">
    {{ message }}
  </div>
  <div v-else-if="hint" class="form-hint">
    {{ hint }}
  </div>
  <div v-else-if="preserveSpace" class="h-8" />
</template>

<script>
import {computed, onUnmounted} from "vue";
import {useField} from "vue-final-form";

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    validate: {
      type: [Function, Array],
      required: false,
    },
    hint: {
      type: String,
    },
    preserveSpace: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  setup(props) {
    const {fieldState, unregister} = useField(props);

    onUnmounted(unregister);

    const isError = computed(() =>
      (fieldState.value.error && fieldState.value.touched) ||
      (fieldState.value.submitError && !fieldState.value.dirtySinceLastSubmit)
    );

    const message = computed(() => fieldState.value.error || fieldState.value.submitError);

    return {
      isError,
      message,
    };
  },
};
</script>
