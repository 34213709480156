import { SET_APPLICATION, SET_CURRENT_APPLICANT, SET_APPLICATIONS_LIST, SET_FUTURE_GUARANTOR, SET_FUTURE_APPLICANT } from './mutations';

const setApplication = ({ commit }, payload) => commit(SET_APPLICATION, payload);
const setFutureGuarantor = ({ commit }, payload) => commit(SET_FUTURE_GUARANTOR, payload);
const setFutureApplicant = ({ commit }, payload) => commit(SET_FUTURE_APPLICANT, payload);
const setCurrentApplicant = ({ commit }, payload) => commit(SET_CURRENT_APPLICANT, payload);
const setApplicationsList = ({ commit }, payload) => commit(SET_APPLICATIONS_LIST, payload);

export default {
    setApplication,
    setCurrentApplicant,
    setApplicationsList,
    setFutureGuarantor,
    setFutureApplicant,
};
