<template>
  <div>
    <ContextSelect
        v-model="profileContext"
        ref="profileSelector"
        :options="options"
        trackBy="customerId"
        label="customerName"
        placeholder="select profile"
        no-clear
    />
  </div>
</template>

<script>
import { sortBy } from 'lodash-es';
import AuthMixin from '@/components/auth/AuthMixin';
import NotifyMixin from '@/mixins/NotifyMixin';
import ContextSelect from '@/components/auth/context_select/ContextSelect';

  export default {
    name: 'ProfileContextSelect',
    components: {
      ContextSelect,
    },
    mixins: [AuthMixin, NotifyMixin],
    props: {
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        options: [],
        profileContext: null,
      }
    },
    computed: {
      quextProfile: function () {
        return this.profiles[0];
      }
    },
    watch: {
      profileContext(value) {
        this.setProfile(value);
        this.$refs.profileSelector.search = null;
      },
    },
    methods: {
      fetchProfiles() {
        return this.$authDataProvider.get('profile', {})
            .then(({ customerUsers = [] }) => {
              this.options = sortBy(customerUsers, ['customerName']);
              this.profileContext = this.quextProfile;
            })
            .catch((e) => {
              console.error(e);
              this.notifyError('Failed to fetch data for profile context selector');
            });
      },
    },
    mounted() {
      this.fetchProfiles();
    },
  }
</script>
