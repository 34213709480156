export const ROOT_LOCATION_ID = '00000000-0000-0000-0000-000000000000';

export const TREE_MODE_L1 = 'L1_NAME';
export const TREE_MODE_L2 = 'L2_NAME';

export const DEFAULT_TREE_MODES = [
    { value: TREE_MODE_L1, label: 'physical data' },
    { value: TREE_MODE_L2, label: 'business data' },
];

export const INIT_LEVEL = 0;
