<template>
  <div
    ref="tooltipWrapper"
    class="tooltip-wrapper"
    @mouseenter="show"
    @mouseleave="hide"
  >
    <icon
      v-if="icon && iconPosition === 'leading'"
      v-bind="iconProps"
    />

    <!-- @slot Label block -->
    <slot name="label">{{label}}</slot>

    <icon
      v-if="icon && iconPosition === 'trailing'"
      v-bind="iconProps"
    />

    <Teleport to="body" v-if="initVisible">
      <div
        ref="tooltip"
        class="tooltip"
        :class="css.tooltipPopup"
        :style="positionStyle"
        @mouseleave="hide"
      >
        <div class="tooltip__popup">
          <!-- @slot Tooltip content -->
          <slot name="default">{{text}}</slot>
        </div>
      </div>
    </Teleport>
  </div>
</template>


<script>
  import Icon from "@/components/ui/Icon";

  const css = {
    tooltipPopup: 'w-64',
  }

  export default {
    components: {
      Icon,
    },

    data() {
      return {
        // changed on tooltip visibility initiation
        initVisible: false,
        // changed after all neccessary DOM updates
        readyVisible: false,
      };
    },

    props: {
      /**
       * Label icon
       */
      icon: {
        type: String,
        required: false,
      },

      /**
       * Icon position
       */
      iconPosition: {
        type: String,
        default: "leading",
        validator: function(value){
          return ["leading", "trailing"].indexOf(value) !== -1;
        },
      },

      /**
       * Label text
       */
      label: {
        type: String,
        required: false,
      },

      /**
       * Tooltip content text
       */
      text: {
        type: String,
        required: false,
      },

      /**
       * Show tooltip only on content overflow
       */
      showOnOverflow: {
        type: Boolean,
        required: false,
        default: false,
      },

      /**
       * Hint's horizontal position
       */
      positionX: {
        type: String,
        default: 'right',
        validator: function (value) {
          return ['left', 'right', 'middle'].indexOf(value) !== -1;
        },
      },

      /**
       * Hint's vertical position
       */
      positionY: {
        type: String,
        default: 'auto',
        validator: function (value) {
          return ['auto', 'bottom', 'top'].indexOf(value) !== -1;
        },
      },

      /**
       * Custom CSS styles
       */
      css: {
        type: Object,
        default() {
          return css;
        }
      },

      shouldPrevent: {
        type: Function,
        required: false,
      },

      disabled: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      positionStyle() {
        if (!this.readyVisible) {
          return {};
        }

        const wrapperRect = this.$refs.tooltipWrapper.getBoundingClientRect();
        const tooltipRect = this.$refs.tooltip.getBoundingClientRect();
        const padding = '0.75rem';
        const style = {};

        switch(this.positionX){
          case 'left': style.left = `${wrapperRect.right - tooltipRect.width}px`;break;
          case 'right': style.left = `${wrapperRect.left}px`;break;
          case 'middle': style.left = `${wrapperRect.right - wrapperRect.width/2 - tooltipRect.width/2}px`;break;
        }

        let positionY = this.positionY;

        if (positionY === 'auto') {
          positionY = window.innerHeight - wrapperRect.bottom < wrapperRect.top
            ? 'top'
            : 'bottom';
        }

        if (positionY === 'bottom') {
          style.top = `${wrapperRect.bottom}px`;
          style.paddingTop = padding;
        }

        if (positionY === 'top') {
          style.bottom = `${window.innerHeight - wrapperRect.top}px`;
          style.paddingBottom = padding;
        }

        return style;
      },

      hasTooltipContent() {
        return this.text != null || !!this.$slots.default;
      },

      hasLabel(){
        return !!this.$slots['label'] || this.label;
      },

      iconProps(){
        return {
          name: this.icon,
          class: [
            'icon',
            this.iconPosition === 'leading' && this.hasLabel ? 'icon--leading' : '',
            this.iconPosition === 'trailing' && this.hasLabel ? 'icon--trailing' : '',
          ],
        };
      },
    },

    watch: {
      initVisible(value) {
        this.$nextTick(() => {
          if (value) {
            this.readyVisible = true;
          }
        });
      }
    },

    methods: {
      show() {
        if (this.hasTooltipContent && (!this.showOnOverflow || this.hasOverflow()) && !this.shouldPrevent?.() && !this.disabled) {
          this.initVisible = true;
        }
      },

      hide(e) {
        if(e.relatedTarget !== this.$refs.tooltip){
          this.initVisible = false;
          this.readyVisible = false;
        }
      },

      hasOverflow() {
        const {tooltipWrapper} = this.$refs;
        return (tooltipWrapper.scrollWidth > tooltipWrapper.clientWidth) ||
          (tooltipWrapper.scrollHeight > tooltipWrapper.clientHeight);
      },
    },

    beforeUnmount() {
      if(this.$refs.tooltip){
        this.$refs.tooltip.remove();
      }
    },
  }
</script>

<style scoped>
  .tooltip-wrapper {
    @apply inline-flex items-center relative;
  }

  .icon {
    @apply inline-block w-4 h-4;

    &--leading {
      @apply mr-1;
    }

    &--trailing {
      @apply ml-1;
    }
  }

  .tooltip {
    @apply absolute z-100;
  }

  .tooltip__popup {
    @apply text-black border border-gray-300 text-2sm p-4 bg-white;
  }
</style>
