const routes = [
    {
        name: 'conversation_dashboards.index',
        path: '/conversation_dashboards',
        component: () => {
            return import('@/views/digital_human/conversation_dashboards/index');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'view conversation dashboards',
            title: 'conversation dashboards',
            size: 'large',
            flowStartsWith: 'conversation_dashboards.index',
            allowedRoles: ['CUSTOMER_ADMIN', 'SUPER_ADMIN', 'USER'],
            requiredAppAccess: ['MIA'],
            product: 'dh',
        },
    },
];

export default routes;
