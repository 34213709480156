const actions = [
    {
        routeName: 'manage_application.details',
        items: [
            {
                title: 'remove applicant',
                id: 'remove-applicant',
                showConfirmation: true,
                confirmationMessage: 'Are you sure you want to remove this applicant?',
                confirmBtnText: 'yes, remove applicant',
                confirmationType: 'warning',
            },
            {
                title: 'add new applicant',
                routeName: 'manage_application.add_person',
                params: { role: 'applicant' },
            },
        ],
    },
];

export default actions;
