import { SET_FLOORPLAN_UNIT_ID, SET_FLOORPLAN, CLEAN_STATE } from './mutations';

const setUnitId = ({ commit }, payload) => commit(SET_FLOORPLAN_UNIT_ID, payload);
const setFloorplan = ({ commit }, payload) => commit(SET_FLOORPLAN, payload);
const cleanState = ({ commit }) => commit(CLEAN_STATE);

export default {
    setUnitId,
    setFloorplan,
    cleanState,
};
