const routes = [
    {
        name: 'contacts.index',
        path: '/websites/:id/contacts',
        component: () => {
            return import('@/views/cms/contacts/Index');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'contacts',
            title: 'contacts',
            flowStartsWith: 'websites.index',
            passFlowTo: 'contacts.index',
            product: 'cms',
            requiresCustomer: true,
            requiresCommunity: true,
            requiredAppAccess: ['CMS'],
        },
    },
    {
        name: 'contacts.show',
        path: '/websites/:id/contacts/:contactId',
        component: () => {
            return import('@/views/cms/contacts/Show');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'contact details',
            flowStartsWith: 'websites.index',
            passFlowTo: 'contacts.index',
            product: 'cms',
            requiresCustomer: true,
            requiresCommunity: true,
            requiredAppAccess: ['CMS'],
        },
    },
];

export default routes;
