import store from '@/store';

const createCbrPermissionChecker = permissionId => () => store.getters['cbr/hasPermission'](permissionId);
const amntPermissionChecker = () => store.getters['amnt/hasPermission'];

export default [
    {
        path: '/spaces/living',
        name: 'cbr.living.buildings',
        component: () => import(/* webpackChunkName: "cbr" */ '@/views/cbr/LivingBuildings'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'buildings',
            title: 'buildings',
            flowStartsWith: 'cbr.living.buildings',
            product: 'cbr',
            permissionChecker: createCbrPermissionChecker('LSV'),
        },
    },
    {
        path: '/spaces/living/create',
        name: 'cbr.living.buildings.create',
        component: () => import(/* webpackChunkName: "cbr" */ '@/views/cbr/LivingBuildingCreator'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'create building',
            title: 'create building',
            flowStartsWith: 'cbr.living.buildings.create',
            product: 'cbr',
            permissionChecker: createCbrPermissionChecker('LSC'),
        },
    },
    {
        path: '/spaces/living/:buildingId',
        name: 'cbr.living.units',
        component: () => import(/* webpackChunkName: "cbr" */ '@/views/cbr/LivingUnits'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'units',
            title: 'units',
            flowStartsWith: 'cbr.living.buildings',
            product: 'cbr',
            permissionChecker: createCbrPermissionChecker('LSV'),
        },
    },
    {
        path: '/spaces/living/:buildingId/create',
        name: 'cbr.living.units.create',
        component: () => import(/* webpackChunkName: "cbr" */ '@/views/cbr/LivingUnitCreator'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'create unit',
            title: 'create unit',
            flowStartsWith: 'cbr.living.buildings',
            product: 'cbr',
            permissionChecker: createCbrPermissionChecker('LSC'),
        },
    },
    {
        path: '/spaces/living/units/:unitId',
        name: 'cbr.living.units.details',
        component: () => import(/* webpackChunkName: "cbr" */ '@/views/cbr/LivingUnitDetails'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'unit details',
            title: 'unit details',
            flowStartsWith: 'cbr.living.buildings',
            product: 'cbr',
            permissionChecker: createCbrPermissionChecker('LSV'),
        },
    },
    {
        path: '/spaces/living/units/:unitId/amenities',
        name: 'cbr.living.units.amenities',
        component: () => import(/* webpackChunkName: "cbr" */ '@/views/cbr/LivingUnitAmenities'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'amenities',
            title: 'amenities',
            flowStartsWith: 'cbr.living.buildings',
            product: 'cbr',
            permissionChecker: () => createCbrPermissionChecker('LSV')() && amntPermissionChecker()('UL_V'),
        },
    },
    {
        path: '/spaces/living/units/:unitId/amenities/:amenityId',
        name: 'cbr.living.units.amenities.details',
        component: () => import(/* webpackChunkName: "cbr" */ '@/views/cbr/LivingUnitAmenityDetails'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'amenity details',
            title: 'amenity details',
            flowStartsWith: 'cbr.living.buildings',
            product: 'cbr',
            permissionChecker: () => createCbrPermissionChecker('LSV')() && amntPermissionChecker()('UL_V'),
        },
    },
    {
        path: '/spaces/living/units/:unitId/groups/:amenityId',
        name: 'cbr.living.units.groups.details',
        component: () => import(/* webpackChunkName: "cbr" */ '@/views/cbr/LivingUnitAmenityGroupDetails'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'amenity group details',
            title: 'amenity group details',
            flowStartsWith: 'cbr.living.buildings',
            product: 'cbr',
            permissionChecker: () => createCbrPermissionChecker('LSV')() && amntPermissionChecker()('UL_V'),
        },
    },
    {
        path: '/spaces/living/units/:unitId/amenity-assignment',
        name: 'cbr.living.units.assignment',
        component: () => import(/* webpackChunkName: "cbr" */ '@/views/cbr/LivingUnitAmenitiesAssignment'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'unit assignment',
            title: 'unit assignment',
            flowStartsWith: 'cbr.living.buildings',
            product: 'cbr',
            permissionChecker: () => createCbrPermissionChecker('LSV')() && amntPermissionChecker()('CL_UA'),
        },
    },
    {
        path: '/spaces/common',
        name: 'cbr.common',
        component: () => import(/* webpackChunkName: "cbr" */ '@/views/cbr/CommonSpaces'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'common spaces',
            title: 'common spaces',
            flowStartsWith: 'cbr.common',
            product: 'cbr',
            permissionChecker: createCbrPermissionChecker('CSV'),
        },
    },
    {
        path: '/spaces/maintenance',
        name: 'cbr.maintenance',
        component: () => import(/* webpackChunkName: "cbr" */ '@/views/cbr/CommonSpaces'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'maintenance spaces',
            title: 'maintenance spaces',
            flowStartsWith: 'cbr.maintenance',
            product: 'cbr',
            permissionChecker: createCbrPermissionChecker('MSV'),
        },
    },
    {
        path: '/spaces/designation',
        name: 'cbr.designation',
        component: () => import(/* webpackChunkName: "cbr" */ '@/views/cbr/DesignationEditor'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'space designation',
            title: 'space designation',
            size: 'large',
            flowStartsWith: 'cbr.designation',
            product: 'cbr',
            permissionChecker: createCbrPermissionChecker('LV'),
        },
    },
    {
        path: '/spaces/unit-types',
        name: 'cbr.unittypes',
        component: () => import(/* webpackChunkName: "cbr" */ '@/views/cbr/UnitTypes'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'unit types',
            title: 'unit types',
            flowStartsWith: 'cbr.unittypes',
            product: 'cbr',
            permissionChecker: createCbrPermissionChecker('UTV'),
        },
    },
    {
        path: '/spaces/unit-types/create',
        name: 'cbr.unittypes.create',
        component: () => import(/* webpackChunkName: "cbr" */ '@/views/cbr/UnitTypeDetails'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'add new',
            title: 'add new unit type',
            flowStartsWith: 'cbr.unittypes',
            product: 'cbr',
            permissionChecker: createCbrPermissionChecker('UTC'),
        },
    },
    {
        path: '/spaces/unit-types/assign',
        name: 'cbr.unittypes.assign',
        component: () => import(/* webpackChunkName: "cbr" */ '@/views/cbr/UnitTypesAssignment'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'unit type assignment',
            title: 'unit type assignment',
            flowStartsWith: 'cbr.unittypes.assign',
            product: 'cbr',
            permissionChecker: createCbrPermissionChecker('UTA'),
        },
    },
    {
        path: '/spaces/unit-types/:typeId',
        name: 'cbr.unittypes.details',
        component: () => import(/* webpackChunkName: "cbr" */ '@/views/cbr/UnitTypeDetails'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'unit type details',
            title: 'unit type details',
            flowStartsWith: 'cbr.unittypes',
            product: 'cbr',
            permissionChecker: createCbrPermissionChecker('UTV'),
        },
    },
    {
        path: '/spaces/floor-plans',
        name: 'cbr.floorplans',
        component: () => import(/* webpackChunkName: "cbr" */ '@/views/cbr/floor_plans/Index'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'floor plans',
            title: 'floor plans',
            flowStartsWith: 'cbr.floorplans',
            product: 'cbr',
            permissionChecker: createCbrPermissionChecker('FPV'),
        },
    },
    {
        path: '/spaces/floor-plans/create',
        name: 'cbr.floorplans.create',
        component: () => import(/* webpackChunkName: "cbr" */ '@/views/cbr/floor_plans/Details'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'add new',
            title: 'add new floor plan',
            flowStartsWith: 'cbr.floorplans',
            product: 'cbr',
            permissionChecker: createCbrPermissionChecker('FPC'),
        },
    },
    {
        path: '/spaces/floor-plans/:planId',
        name: 'cbr.floorplans.details',
        component: () => import(/* webpackChunkName: "cbr" */ '@/views/cbr/floor_plans/Details'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'floor plans details',
            title: 'floor plans details',
            flowStartsWith: 'cbr.floorplans',
            product: 'cbr',
            permissionChecker: createCbrPermissionChecker('FPV'),
        },
    },
];
