export default {
    userPermissions: {
        getList: ['GET', '/v1/user/permissions'],
    },
    journalEntries: {
        getList: ['GET', '/v1/ledger-entry'],
        getOne: ['GET', '/journal-entries/journal_entry_details/{journal_entries_id}'],
        getAccountGLDetails: ['GET', '/journal-entries/gl_account_details_by_id/{id}'],
        create: ['POST', '/journal-entries'],
        import: ['POST', '/journal-entries/import'],
        delete: ['DELETE', `/journal-entries/{id}`],
    },
    quextUser: {
        getOne: ['GET', '/quext-user/{user_id}'],
    },
    reconcile: {
        getList: ['GET', '/reconcile'],
        getOne: ['GET', '/reconcile/import/{id}'],
    },
    bankAccount: {
        getList: ['GET', '/bank-account'],
        getOne: ['GET', '/bank-account/{bank_account_id}'],
        create: ['POST', '/bank-account'],
        update: ['PATCH', '/bank-account/{bank_account_id}'],
        check: ['POST', '/bank-account/check_account_number'],
        checkPost: ['GET', '/bank-account/checkPost'],
    },
    bankAccounts: {
        getList: ['GET', '/v1/bank-accounts/list'],
        activate: ['PUT', '/v1/bank-accounts/{id}/active'],
        getOne: ['GET', '/v1/bank-accounts/{id}'],
        create: ['POST', '/v1/bank-accounts/'],
        update: ['PUT', '/v1/bank-accounts/{id}'],
    },
    glAccounts: {
        getList: ['GET', '/v1/chart-accounts/balance'],
    },
    glAccount: {
        getList: ['GET', '/v1/chart-accounts/{chartAccountId}/balance'],
    },
    gltree: {
        getList: ['GET', '/gltree'],
        getAll: ['GET', '/gltree/all'],
        getOne: ['GET', '/gltree/{id}'],
        getType: ['GET', '/gltree/type'],
        getAccountGL: ['GET', '/gltree/glaccount'],
        getAccountGLType: ['GET', '/gltree/glaccount-gltype/{gl_account_type_id}'],
        getAccountGLDetailList: ['GET', '/gltree/glaccount-detail'],
        getAccountGLDetail: ['GET', '/gltree/glaccount-detail/{gl_account_id}'],
        getBankAccountGL: ['GET', '/gltree/bank-accountGL/{gl_account_id}'],
        updateAccountGLDetail: ['PATCH', '/gltree/glaccount-detail/{gl_account_id}'],
        getIncome: ['GET', '/gltree/income/statement'],
        getChild: ['GET', '/gltree/child'],
        getSetup: ['GET', '/gltree/setup'],
        postSetup: ['POST', '/gltree/setup'],
        create: ['POST', '/gltree'],
        check: ['POST', '/gltree/check_account_number'],
        checkDelete: ['GET', '/gltree/check-delete/{gl_account_id}'],
        close: ['POST', '/gltree/closeDate'],
        delete: ['DELETE', '/gltree/{gl_account_id}'],
    },
    ledgerEntry: {
        get: ['GET', '/ledger-entry/balanceSheet'],
        getList: ['POST', '/ledger-entry/filter'],
        getBalance: ['POST', '/ledger-entry/trialBalance'],
    },
    fileUpload: {
        getOne: ['GET', '/fileUpload/{attachment_id}'],
        create: ['POST', '/fileupload'],
    },
    entities: {
        getList: ['GET', '/entities/unit'],
    },
    entity: {
        getList: ['GET', '/v1/entity/{businessUnitId}'],
    },
    codeCategory: {
        getList: ['GET', '/codecategory'],
        check: ['POST', '/codecategory/check_code_category_name'],
        create: ['POST', '/codecategory'],
        delete: ['DELETE', '/codecategory/{id}'],
    },
    code: {
        getOne: ['GET', '/code/{id}'],
        getCategory: ['GET', '/code/code_category/{id}'],
        create: ['POST', '/code'],
        update: ['PATCH', '/code/{id}'],
        delete: ['DELETE', '/code/{id}'],
    },
    invoiceRepeatType: {
        getList: ['GET', '/invoice-repeat-type'],
    },
    deposit: {
        getList: ['GET', '/v1/deposits/{accountId}'],
        getOne: ['GET', '/deposit/{deposit_id}'],
        getType: ['GET', '/deposit/type'],
        getBill: ['GET', '/bill/resident/{payerId}'],
        getEntry: ['GET', '/deposit/entries/{id}'],
        getNsfSetting: ['GET', `/nsf-setting/{communityId}/{payerId}/'quext'`],
        getReports: ['POST', '/deposit/reports'],
        create: ['POST', '/v1/deposits'],
        createPayment: ['POST', '/deposit/addPayment'],
        update: ['POST', '/v1/deposits/revert/{depositId}'],
        delete: ['DELETE', '/deposit/{deposit_id}'],
    },
    charges: {
        getList: ['GET', 'v1/charges'],
        getFinancialDetails: ['GET', 'receivable/v1/totals/{accountId}'],
        getOne: ['GET', 'v1/charges/{id}'],
        delete: ['DELETE', 'v1/charges/{id}'],
        create: ['POST', 'v1/charges'],
    },
    codes: {
        getList: ['GET', '/v1/codes'],
        getOne: ['GET', '/v1/codes/{id}'],
        create: ['POST', '/v1/codes'],
        update: ['PUT', '/v1/codes/{id}'],
        delete: ['DELETE', '/v1/codes/{id}'],
    },
    codesCategories: {
        getList: ['GET', '/v1/code-categories'],
        create: ['POST', '/v1/code-categories'],
        delete: ['DELETE', '/v1/code-categories/{id}'],
    },
    chartAccounts: {
        getList: ['GET', '/v1/chart-accounts'],
        getCategories: ['GET', '/v1/chart-account-category'],
        create: ['POST', '/v1/chart-accounts'],
        update: ['PUT', '/v1/chart-accounts/{id}'],
    },
    chartAccountCategory: {
        getList: ['GET', '/v1/chart-account-category'],
    },
    lateFeeSchemas: {
        getList: ['GET', '/v1/late-fee-schemas'],
        getOne: ['GET', '/v1/late-fee-schemas/{id}'],
        delete: ['DELETE', '/v1/late-fee-schemas/{id}'],
        create: ['POST', '/v1/late-fee-schemas'],
    },
    reversalReasons: {
        getList: ['GET', '/v1/reversal-reasons'],
        update: ['PUT', '/v1/reversal-reasons'],
    },
    chargePriority: {
        getList: ['GET', '/v1/codes/priority'],
        update: ['PUT', '/v1/codes/priority'],
    },
    states: {
        getList: ['GET', '/v1/states'],
    },
    glSetup: {
        getList: ['GET', '/v1/chart-account-settings'],
        update: ['PUT', '/v1/chart-account-settings'],
    },
    glClosingPeriod: {
        getOne: ['GET', '/v1/business-unit-setting'],
        create: ['POST', '/v1/business-unit-setting'],
        update: ['PUT', '/v1/business-unit-setting'],
    },
    temporaryLateFeeChanges: {
        getOne: ['GET', '/v1/temporary-late-fees'],
        create: ['POST', '/v1/temporary-late-fees'],
        restore: ['PUT', '/v1/temporary-late-fees/{id}'],
    },
    invoices: {
        getList: ['GET', '/v1/invoices'],
        getOne: ['GET', '/v1/invoices/{id}'],
        create: ['POST', '/v1/invoices'],
        update: ['PUT', '/v1/invoices/{id}'],
        postInvoice: ['PATCH', '/v1/invoices/{id}'],
    },
    invoiceAttachments: {
        getList: ['GET', '/v1/invoices/{invoiceId}'],
        download: ['GET', '/v1/invoices/file/{id}'],
        delete: ['DELETE', '/v1/invoices/file/{id}'],
    },
    vendors: {
        getList: ['GET', 'v1/vendors'],
        getOne: ['GET', 'v1/vendors/{id}'],
        create: ['POST', 'v1/vendors'],
        update: ['PUT', 'v1/vendors/{id}'],
        updateSettings: ['PATCH', 'v1/vendors/{id}/settings'],
        archive: ['PATCH', '/v1/vendors/{id}/archiving'],
        delete: ['DELETE', '/v1/vendors/{id}'],
        getListContacts: ['GET', '/v1/vendors/{id}/contacts'],
        updateContacts: ['PUT', '/v1/vendors/{id}/contacts'],
        deleteContact: ['DELETE', '/v1/vendors/{id}/contacts/{contactId}'],
    },
    industries: {
        getList: ['GET', 'v1/industries'],
    },
};
