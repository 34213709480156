<template>
    <dropdown
        v-bind="$attrs"
        ref="selector"
        class="context-selector"
        :disabled="disabled"
        non-clearable-value
        object-mode
    >
        <template v-slot:tags="{ controlText, isDisabled }">
            <input
                type="text"
                class="context-selector__input"
                v-model="search"
                :placeholder="controlText"
                :disabled="disabled || isDisabled"
            />
        </template>
        <template v-slot:empty-result>
            <slot name="empty-result">
                No results found matching your search
            </slot>
        </template>
    </dropdown>
</template>

<script>
import { debounce } from 'lodash-es';
import Dropdown from '@/components/ui/Dropdown';

  export default {
    name: 'ContextSelect',
    components: {
      Dropdown,
    },
    props: {
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        search: null,
      }
    },
    watch: {
      search: debounce(function (value) {
        this.$refs.selector.searchQuery = value;
      }, 500),
    },
  }
</script>

<style scoped>
.context-selector {
  @apply bg-transparent border-none border-b p-0;
  width: auto;
  height: 1.75rem;
  min-height: 1.75rem;

  &__input {
     @apply outline-none font-inter bg-transparent text-gray-700 font-500 text-2sm cursor-pointer;
     margin: 0;
     height: 1.75rem;
     flex: 1 1 0;

    .dropdown--disabled & {
      @apply cursor-default;
    }

    &::placeholder {
       @apply text-gray-700 font-500 text-2sm;
     }
  }

  &:deep(.dropdown__buttons){
     height: 1.75rem;
  }

  &:deep(.dropdown__button){
    @apply text-gray-700;
    height: 1.75rem;
  }

  &:deep(.dropdown__menu--below) {
      border-top-width: 1px;
  }
}
</style>