const routes = [
    {
        name: 'mvp_lease_editor.index',
        path: '/lease-editor',
        component: () => {
            return import('@/views/mvp_lease_service/Index.vue');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'lease editor',
            title: 'lease editor',
            size: 'normal',
            flowStartsWith: 'mvp_lease_editor.index',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'lease-service',
        },
    },
];

export default routes;
