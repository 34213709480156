import store from '@/store';

const permissionChecker = () => store.getters['auth/profile']?.appCodes?.includes('AUTH');

export default [
    {
        path: '/applications',
        name: 'applications.index',
        component: () => import(/* webpackChunkName: "applications" */ '@/views/auth/applications/ApplicationsList'),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'applications',
            breadcrumbName: 'applications',
            allowedRoles: ['SUPER_ADMIN', 'SUPER_READONLY_ADMIN'],
            flowStartsWith: 'applications.index',
            product: 'qxt-admin',
            permissionChecker,
        },
    },
    // Bundles
    {
        path: '/applications/bundles',
        name: 'applications.bundles.index',
        component: () => import(/* webpackChunkName: "applicationBundles" */ '@/views/auth/applications/bundles/BundlesList.vue'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'bundles',
            title: 'bundles management',
            allowedRoles: ['SUPER_ADMIN', 'SUPER_READONLY_ADMIN'],
            product: 'qxt-admin',
            permissionChecker,
        },
    },
    {
        path: '/applications/bundles/create',
        name: 'applications.bundles.create',
        component: () => import(/* webpackChunkName: "applicationBundleCreate" */ '@/views/auth/applications/bundles/BundleCreate'),
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'add new',
            title: 'add new bundle',
            allowedRoles: ['SUPER_ADMIN'],
            hideFromSearch: true,
            product: 'qxt-admin',
            permissionChecker,
        },
    },
    {
        path: '/applications/bundles/:bundleId/metaroles/:metaroleId/profiles',
        name: 'applications.bundles.metarole.index',
        component: () => import(/* webpackChunkName: "applicationBundleMetarole" */ '@/views/auth/applications/bundles/MetaroleUsersList'),
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'metarole usage',
            title: 'metarole usage',
            allowedRoles: ['SUPER_ADMIN', 'SUPER_READONLY_ADMIN'],
            hideFromSearch: true,
            permissionChecker,
        },
    },
    {
        path: '/applications/bundles/:id',
        name: 'applications.bundles.edit',
        component: () => import(/* webpackChunkName: "applicationBundleEdit" */ '@/views/auth/applications/bundles/BundleEdit'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'edit',
            title: 'edit bundle',
            allowedRoles: ['SUPER_ADMIN', 'SUPER_READONLY_ADMIN'],
            hideFromSearch: true,
            permissionChecker,
        },
    },
    // Application
    {
        path: '/applications/create',
        name: 'applications.create',
        component: () => import(/* webpackChunkName: "createApplication" */ '@/views/auth/applications/ApplicationsCreate'),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'add new application',
            breadcrumbName: 'add new',
            allowedRoles: ['SUPER_ADMIN', 'SUPER_READONLY_ADMIN'],
            flowStartsWith: 'applications.create',
            product: 'qxt-admin',
            permissionChecker,
        },
    },
    {
        path: '/applications/:appId',
        name: 'applications.edit',
        component: () => import(/* webpackChunkName: "applicationsEdit" */ '@/views/auth/applications/ApplicationsEdit'),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'edit application',
            breadcrumbName: 'edit',
            allowedRoles: ['SUPER_ADMIN', 'SUPER_READONLY_ADMIN'],
            hideFromSearch: true,
            permissionChecker,
        },
    },
    {
        path: '/applications/:appId/customers',
        name: 'applications.customers.index',
        component: () => import(/* webpackChunkName: "AppCustomersList" */ '@/views/auth/applications/customers/AppCustomersList'),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'customer access',
            breadcrumbName: 'customer access',
            allowedRoles: ['SUPER_ADMIN', 'SUPER_READONLY_ADMIN'],
            hideFromSearch: true,
            permissionChecker,
        },
    },
    {
        path: '/applications/:appId/customers/:customerId/:appAccessId/users',
        name: 'applications.customers.users',
        component: () => import(/* webpackChunkName: "AppCustomerUsersList" */ '@/views/auth/applications/customers/AppCustomerUsersList'),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'users',
            breadcrumbName: 'users',
            allowedRoles: ['SUPER_ADMIN', 'SUPER_READONLY_ADMIN'],
            hideFromSearch: true,
            permissionChecker,
        },
    },
    {
        path: '/applications/:appId/customers/:customerId/:appAccessId/users/:userId',
        name: 'applications.customers.users.details',
        component: () => import(/* webpackChunkName: "UsersEdit" */ '@/views/auth/customers/users/UserDetails'),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'user',
            breadcrumbName: 'user details',
            allowedRoles: ['SUPER_ADMIN', 'SUPER_READONLY_ADMIN'],
            hideFromSearch: true,
            permissionChecker,
        },
    },
    {
        path: '/applications/:appId/customers/:customerId/:appAccessId/users/:userId/edit',
        name: 'applications.customers.users.edit',
        component: () => import(/* webpackChunkName: "UsersEdit" */ '@/views/auth/customers/users/UsersEdit'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'edit',
            title: 'edit user',
            allowedRoles: ['SUPER_ADMIN', 'SUPER_READONLY_ADMIN'],
            hideFromSearch: true,
            permissionChecker,
        },
    },
    //  Roles
    {
        path: '/applications/:appId/roles',
        name: 'applications.roles.index',
        component: () => import(/* webpackChunkName: "applicationRoles" */ '@/views/auth/applications/roles/RolesList.vue'),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'roles',
            breadcrumbName: 'roles',
            allowedRoles: ['SUPER_ADMIN', 'SUPER_READONLY_ADMIN'],
            hideFromSearch: true,
            permissionChecker,
        },
    },
    {
        path: '/applications/:appId/roles/:roleId/details',
        name: 'applications.roles.details',
        component: () => import(/* webpackChunkName: "appRoleDetails" */ '@/views/auth/applications/roles/RoleDetails'),
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'role details',
            title: 'role details',
            allowedRoles: ['SUPER_ADMIN', 'SUPER_READONLY_ADMIN'],
            hideFromSearch: true,
            permissionChecker,
        },
    },
    {
        path: '/applications/:appId/deleted-roles',
        name: 'applications.deletedRoles.index',
        component: () => import(/* webpackChunkName: "applicationDeletedRoles" */ '@/views/auth/applications/roles/DeletedRolesList.vue'),
        meta: {
            loadInModal: true,
            title: 'deleted roles',
            breadcrumbName: 'deleted roles',
            allowedRoles: ['SUPER_ADMIN', 'SUPER_READONLY_ADMIN'],
            hideFromSearch: true,
            permissionChecker,
        },
    },
    {
        path: '/applications/:appId/deleted-roles/:roleId/details',
        name: 'applications.deletedRoles.details',
        component: () => import(/* webpackChunkName: "appRoleDetails" */ '@/views/auth/applications/roles/RoleDetails'),
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'deleted role details',
            title: 'deleted role details',
            allowedRoles: ['SUPER_ADMIN', 'SUPER_READONLY_ADMIN'],
            hideFromSearch: true,
            permissionChecker,
        },
    },
    //  Permissions
    {
        path: '/applications/:appId/permissions',
        name: 'applications.permissions.index',
        component: () =>
            import(/* webpackChunkName: "applicationPermissions" */ '@/views/auth/applications/permissions/PermissionsList.vue'),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'permissions',
            breadcrumbName: 'permissions',
            allowedRoles: ['SUPER_ADMIN', 'SUPER_READONLY_ADMIN'],
            hideFromSearch: true,
            permissionChecker,
        },
    },
    {
        path: '/applications/:appId/deleted-permissions',
        name: 'applications.deletedPermissions.index',
        component: () =>
            import(
                /* webpackChunkName: "applicationDeletedPermissions" */ '@/views/auth/applications/permissions/DeletedPermissionsList.vue'
            ),
        meta: {
            loadInModal: true,
            title: 'deleted permissions',
            breadcrumbName: 'deleted permissions',
            allowedRoles: ['SUPER_ADMIN', 'SUPER_READONLY_ADMIN'],
            hideFromSearch: true,
            permissionChecker,
        },
    },
    // Deleted applications
    {
        path: '/deleted-applications',
        name: 'applications.deletedApplications.index',
        component: () => import(/* webpackChunkName: "DeletedApplicationsList" */ '@/views/auth/applications/DeletedApplicationsList'),
        meta: {
            loadInModal: true,
            actions: false,
            title: 'deleted applications',
            breadcrumbName: 'deleted applications',
            allowedRoles: ['SUPER_ADMIN', 'SUPER_READONLY_ADMIN'],
            permissionChecker,
        },
    },
    {
        path: '/deleted-applications/:appId',
        name: 'applications.deletedApplications.details',
        component: () => import(/* webpackChunkName: "applicationsEdit" */ '@/views/auth/applications/ApplicationsEdit'),
        meta: {
            loadInModal: true,
            actions: false,
            title: 'view application',
            breadcrumbName: 'view',
            allowedRoles: ['SUPER_ADMIN', 'SUPER_READONLY_ADMIN'],
            hideFromSearch: true,
            permissionChecker,
        },
    },
];
