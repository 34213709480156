export default [
    {
        path: '/integrations',
        name: 'integrations.index',
        component: () => import(/* webpackChunkName: "IntegrationsList" */ '@/views/auth/integrations/IntegrationsList'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'integration partners',
            title: 'integration partners',
            allowedRoles: ['SUPER_ADMIN', 'SUPER_READONLY_ADMIN'],
            flowStartsWith: 'integrations.index',
            product: 'qxt-admin',
            requiredAppAccess: ['IPS'],
        },
    },
    {
        path: '/integrations/create',
        name: 'integrations.create',
        component: () => import(/* webpackChunkName: "IntegrationsCreate" */ '@/views/auth/integrations/IntegrationsCreate'),
        meta: {
            title: 'add new integration partner',
            loadInModal: true,
            actions: true,
            breadcrumbName: 'add new partner',
            allowedRoles: ['SUPER_ADMIN', 'SUPER_READONLY_ADMIN'],
            flowStartsWith: 'integrations.create',
            product: 'qxt-admin',
            requiredAppAccess: ['IPS'],
        },
    },
    {
        path: '/integrations/:partnerId',
        name: 'integrations.edit',
        component: () => import(/* webpackChunkName: "IntegrationsEdit" */ '@/views/auth/integrations/IntegrationsEdit'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'edit partner',
            title: 'edit integration partner',
            allowedRoles: ['SUPER_ADMIN', 'SUPER_READONLY_ADMIN'],
            flowStartsWith: 'integrations.index',
            passFlowTo: 'integrations.edit',
            product: 'qxt-admin',
            requiredAppAccess: ['IPS'],
            hideFromSearch: true,
        },
    },
    {
        path: '/integrations/:partnerId/purposes',
        name: 'integrations.purposes',
        component: () => import(/* webpackChunkName: "IntegrationPurposes" */ '@/views/auth/integrations/IntegrationPurposes'),
        meta: {
            loadInModal: true,
            breadcrumbName: 'manage purposes',
            title: 'manage purposes',
            allowedRoles: ['SUPER_ADMIN', 'SUPER_READONLY_ADMIN'],
            flowStartsWith: 'integrations.index',
            passFlowTo: 'integrations.purposes',
            product: 'qxt-admin',
            requiredAppAccess: ['IPS'],
            hideFromSearch: true,
        },
    },
];
