import { hasAccessTo, isCustomerAdmin, isQuextAdmin } from '@/utils/actionAllowedRules';

export default [
    {
        routeName: 'company.index',
        items: [
            {
                title: 'manage communities',
                routeName: 'company.communities.index',
                params: {
                    customerId: '{customerId}',
                },
            },
            {
                title: 'manage users',
                routeName: 'company.users.index',
                params: {
                    customerId: '{customerId}',
                },
            },
            {
                title: 'manage admins',
                routeName: 'company.admins.index',
                params: {
                    customerId: '{customerId}',
                },
            },
            {
                title: 'manage roles',
                routeName: 'company.roles.index',
                params: {
                    customerId: '{customerId}',
                },
                allowed: isQuextAdmin,
            },
            {
                title: 'manage permissions',
                routeName: 'company.permissions.index',
                params: {
                    customerId: '{customerId}',
                },
                allowed: isQuextAdmin,
            },
        ],
    },
    // Communities
    {
        routeName: 'company.communities.index',
        items: [
            {
                title: 'add new community',
                routeName: 'company.communities.create',
                params: {
                    customerId: '{customerId}',
                },
            },
            {
                title: 'deleted communities',
                routeName: 'company.deletedCommunities.index',
                params: {
                    customerId: '{customerId}',
                },
            },
        ],
    },
    {
        routeName: 'company.community.create',
        items: [],
    },
    {
        routeName: 'company.communities.edit',
        items: [
            {
                title: 'manage integration partners',
                routeName: 'company.communities.integrations.index',
                params: {
                    customerId: '{customerId}',
                    communityId: '{communityId}',
                },
                allowed: hasAccessTo('IPS'),
            },
        ],
    },
    // Integrations
    {
        routeName: 'company.communities.integrations.index',
        items: [
            {
                title: 'add new partner',
                routeName: 'company.communities.integrations.create',
                params: {
                    customerId: '{customerId}',
                    communityId: '{communityId}',
                },
            },
        ],
    },
    // Users
    {
        routeName: 'company.users.index',
        items: [
            {
                title: 'add new user',
                routeName: 'company.users.create',
                params: {
                    customerId: '{customerId}',
                },
            },
            {
                title: 'deleted users',
                routeName: 'company.deletedUsers.index',
                params: {
                    customerId: '{customerId}',
                },
            },
        ],
    },
    {
        routeName: 'company.users.create',
        items: [],
    },
    {
        routeName: 'company.users.edit',
        items: [],
    },
    {
        routeName: 'company.users.details',
        items: [
            {
                title: 'edit user',
                routeName: 'company.users.edit',
                params: {
                    customerId: '{customerId}',
                    userId: '{userId}',
                },
            },
        ],
    },
    // Admins
    {
        routeName: 'company.admins.index',
        items: [
            {
                title: 'add admin',
                routeName: 'company.admins.create',
                params: {
                    customerId: '{customerId}',
                },
                allowed: isCustomerAdmin,
            },
            {
                title: 'deleted admins',
                routeName: 'company.deletedAdmins.index',
                params: {
                    customerId: '{customerId}',
                },
            },
        ],
    },
    {
        routeName: 'company.admins.details',
        items: [],
    },
    // Roles
    {
        routeName: 'company.roles.index',
        items: [
            {
                title: 'deleted roles',
                routeName: 'company.deletedRoles.index',
                params: {
                    customerId: '{customerId}',
                },
            },
        ],
    },
    // Permissions
    {
        routeName: 'company.permissions.index',
        items: [],
    },
];
