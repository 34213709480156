import store from '@/store';

const createPermissionChecker = permissionId => () => store.getters['ri/hasPermission'](permissionId);

export default [
    {
        path: '/ri',
        name: 'ri.index',
        component: () => import(/* webpackChunkName: "ri" */ '@/views/ri/RIList'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'rentable items',
            title: 'rentable items',
            flowStartsWith: 'ri.index',
            product: 'ri',
            permissionChecker: createPermissionChecker('RI_VIEW'),
        },
    },
    {
        path: '/ri/create',
        name: 'ri.create',
        component: () => import(/* webpackChunkName: "ri" */ '@/views/ri/RIDetails'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'add new',
            title: 'add new rentable item',
            flowStartsWith: 'ri.index',
            product: 'ri',
            permissionChecker: createPermissionChecker('RI_EDIT'),
        },
    },
    {
        path: '/ri/:rentableItemId/edit',
        name: 'ri.edit',
        component: () => import(/* webpackChunkName: "ri" */ '@/views/ri/RIDetails'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'edit',
            title: 'edit rentable item',
            flowStartsWith: 'ri.index',
            product: 'ri',
            permissionChecker: createPermissionChecker('RI_EDIT'),
        },
    },
    {
        path: '/ri/:rentableItemId',
        name: 'ri.reservations',
        component: () => import(/* webpackChunkName: "ri" */ '@/views/ri/RIReservations'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'rentable item reservations',
            title: 'rentable item reservations',
            flowStartsWith: 'ri.index',
            product: 'ri',
            permissionChecker: createPermissionChecker('RI_VIEW'),
        },
    },
    {
        path: '/ri/:rentableItemId/assets',
        name: 'ri.assets',
        component: () => import(/* webpackChunkName: "ri" */ '@/views/ri/RIAssets'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'assets',
            title: 'assets',
            flowStartsWith: 'ri.index',
            product: 'ri',
            permissionChecker: createPermissionChecker('RI_VIEW'),
        },
    },
    {
        path: '/ri/:rentableItemId/assets/deactivated',
        name: 'ri.deactivatedAssets',
        component: () => import(/* webpackChunkName: "ri" */ '@/views/ri/RIAssets'),
        props: {
            status: 'DEACTIVATED',
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'deactivated assets',
            title: 'deactivated assets',
            flowStartsWith: 'ri.index',
            product: 'ri',
            permissionChecker: createPermissionChecker('RI_VIEW'),
        },
    },
    {
        path: '/ri/:rentableItemId/reservation/create',
        name: 'ri.createReservation',
        component: () => import(/* webpackChunkName: "ri" */ '@/views/ri/RICreateReservation'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'new reservation',
            title: 'new reservation',
            flowStartsWith: 'ri.index',
            product: 'ri',
            permissionChecker: createPermissionChecker('RESERVATIONS_EDIT'),
        },
    },
    {
        path: '/ri/:rentableItemId/reservations/:reservationId',
        name: 'ri.reservation.details',
        component: () => import(/* webpackChunkName: "ri" */ '@/views/ri/RIReservationDetails'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'reservation details',
            title: 'reservation details',
            flowStartsWith: 'ri.index',
            product: 'ri',
            permissionChecker: createPermissionChecker('RESERVATIONS_VIEW'),
        },
    },
];
