class HttpAdapter {
    httpClient = undefined;
    resources = undefined;
    beforeRequestInterceptor = undefined;
    afterRequestInterceptor = undefined;

    constructor(
        httpClient,
        resourcesConfig,
        beforeRequestInterceptor = ({ requestConfig }) => requestConfig,
        afterRequestInterceptor = ({ response }) => response?.data
    ) {
        if (!httpClient || typeof httpClient !== 'function') {
            throw new Error('httpClient must be promise returning function');
        }

        if (!resourcesConfig || typeof resourcesConfig !== 'object') {
            throw new Error('resourcesConfig must be an object');
        }

        this.resources = this.prepareResources(resourcesConfig);
        this.httpClient = httpClient;
        this.beforeRequestInterceptor = beforeRequestInterceptor;
        this.afterRequestInterceptor = afterRequestInterceptor;
    }

    async handle(action, resource, params = {}) {
        const [method, urlTemplate] = this.getRoute(this.resources, resource, action);
        const { data, headers = {}, signal, ...urlParams } = params;
        const { url, query } = this.buildUrl(urlTemplate, urlParams);

        let requestConfig = {
            url,
            method,
            data,
            params: query,
            headers,
            signal,
        };

        requestConfig = this.beforeRequestInterceptor({ requestConfig, action, resource });

        return this.httpClient(requestConfig).then(response => this.afterRequestInterceptor({ response, requestConfig, action, resource }));
    }

    prepareResources(resourcesConfig) {
        return Object.keys(resourcesConfig).reduce((acc, resource) => {
            const decl = resourcesConfig[resource];
            acc[resource] = typeof decl === 'string' ? this.buildRestRoutes(decl) : decl;
            return acc;
        }, {});
    }

    getRoute(resources, resource, action) {
        const routes = resources[resource];

        if (!routes) {
            throw Error(`Bad resource '${resource}'`);
        }

        const route = routes[action];

        if (!route) {
            throw Error(`Bad action ${resource}:${action}`);
        }

        return route;
    }

    buildRestRoutes(resourcePath) {
        return {
            get: ['GET', `${resourcePath}`],
            getList: ['GET', `${resourcePath}`],
            getOne: ['GET', `${resourcePath}/{id}`],
            create: ['POST', `${resourcePath}`],
            update: ['PUT', `${resourcePath}/{id}`],
            patch: ['PATCH', `${resourcePath}/{id}`],
            delete: ['DELETE', `${resourcePath}/{id}`],
        };
    }

    buildUrl(urlTemplate, params) {
        if (!params) {
            return {
                url: urlTemplate,
                query: null,
            };
        }

        const query = { ...params };

        const url = urlTemplate.replace(/{(\w+)}/g, (match, key) => {
            delete query[key];
            return params[key];
        });

        return {
            url,
            query,
        };
    }
}

export default HttpAdapter;
