import { cloneDeep } from 'lodash-es';
import { ROOT_LOCATION_ID } from '@/utils/constants/location-tree';

/**
 * Update locations tree partly or fully
 * @param {Object} locationTree Location tree to add
 * @param {Object} currentLocations Current location tree (optional)
 * @returns {Object} Updated location tree
 */
export const updateLocationTree = function (locationTree, currentLocations) {
    const resultLocations = currentLocations
        ? cloneDeep(currentLocations)
        : {
            [ROOT_LOCATION_ID]: {
                id: ROOT_LOCATION_ID,
            },
        };

    const flattenLocationTree = locations => {
        locations.forEach(location => {
            const parent = resultLocations[location.parentId];
            const hasChildNodes = location.children && location.children[0]?.id;

            if (parent && !parent.children) {
                parent.children = locations.filter(loc => loc.parentId === location.parentId).map(loc => loc.id);
            }

            const children = hasChildNodes ? location.children.map(location => location.id) : location.children;

            resultLocations[location.id] = {
                ...location,
                children,
            };

            if (hasChildNodes) {
                flattenLocationTree(
                    location.children.map(loc => ({
                        ...loc,
                        parentId: location.id,
                    }))
                );
            }
        });
    };

    const preparedLocationsTree = locationTree.map(location => ({
        ...location,
        parentId: location.parentId || ROOT_LOCATION_ID,
    }));

    flattenLocationTree(preparedLocationsTree);

    return resultLocations;
};
