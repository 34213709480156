<template>
  <!--  TODO: move SREQ settings here-->
  <router-link :to="{name: 'sreq.settings.community'}" class="text-link">service requests settings</router-link>
</template>

<script>
export default {
}
</script>

<style scoped>

</style>
