export const SET_AMENITY_TYPE = 'SET_AMENITY_TYPE';
export const SET_AMENITIES = 'SET_AMENITIES';
export const SET_AMENITIES_DETAILS = 'SET_AMENITIES_DETAILS';
export const SET_CHANGED_STATE = 'SET_CHANGED_STATE';
export const SET_AMENITY = 'SET_AMENITY';
export const SET_SETUP_COMPLETED = 'SET_SETUP_COMPLETED';
export const SET_LOADING = 'SET_LOADING';
export const CLEAN_STATE = 'CLEAN_STATE';

export default {
    [SET_AMENITY_TYPE]: (state, payload) => (state.amenityType = payload),
    [SET_AMENITIES]: (state, payload) => (state.amenities = payload),
    [SET_AMENITIES_DETAILS]: (state, payload) => (state.amenityDetails = payload),
    [SET_CHANGED_STATE]: (state, payload) => (state.changedState = payload),
    [SET_AMENITY]: (state, payload) => (state.amenity = payload),
    [SET_AMENITY]: (state, payload) => (state.amenityDetail = payload),
    [SET_SETUP_COMPLETED]: (state, payload) => (state.isSetupCompleted = payload),
    [SET_LOADING]: (state, payload) => (state.loading = payload),
    [CLEAN_STATE]: (state, payload) => {
        switch (payload) {
        case 'amenityType':
            state.amenityType = null;
            state.amenity = null;
            state.amenityDetail = null;
            state.amenities = [];
            state.amenityDetails = [];
            state.changedState = [];
            break;
        case 'amenities':
            state.amenities = [];
            break;
        case 'amenityDetails':
            state.amenityDetails = [];
            break;
        case 'changedState':
            state.changedState = [];
            break;
        case 'amenity':
            state.amenity = null;
            break;
        default:
            state.amenityType = null;
        }
    },
};
