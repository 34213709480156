import amntFetcher from './amnt';
import cbrFetcher from './cbr';
import iotFetcher from './iot';
import riFetcher from './ri';
import sreqFetcher from './sreq';

export default {
    AMNT: amntFetcher,
    CBR: cbrFetcher,
    IOT: iotFetcher,
    RI: riFetcher,
    SREQ: sreqFetcher,
};
