import { updateLocationTree } from '@/utils/location-tree';
import { ROOT_LOCATION_ID } from '@/utils/constants/location-tree';

export const ADD_COMMUNITY_LOCATIONS = 'ADD_COMMUNITY_LOCATIONS';

export const ADD_SINGLE_LOCATION = 'ADD_SINGLE_LOCATION';

export const UPDATE_SINGLE_LOCATION = 'UPDATE_SINGLE_LOCATION';

export const DELETE_COMMUNITY_LOCATION = 'DELETE_COMMUNITY_LOCATION';

export const CLEAR_COMMUNITY_LOCATIONS = 'CLEAR_COMMUNITY_LOCATIONS';

export default {
    [ADD_COMMUNITY_LOCATIONS]: (state, payload) => {
        state.locationTree = updateLocationTree(payload, state.locationTree);
    },

    [ADD_SINGLE_LOCATION]: (state, payload) => {
        state.locationTree[payload.parentId].children = [...state.locationTree[payload.parentId].children, payload.id];
        state.locationTree[payload.id] = payload;
    },

    [UPDATE_SINGLE_LOCATION]: (state, payload) => {
        state.locationTree[payload.id] = payload;
    },

    [DELETE_COMMUNITY_LOCATION]: (state, payload) => {
        state.locationTree[payload.parentId].children = state.locationTree[payload.parentId].children.filter(id => id !== payload.id);
        delete state.locationTree[payload.id];
    },

    [CLEAR_COMMUNITY_LOCATIONS]: state => {
        state.locationTree = {
            [ROOT_LOCATION_ID]: {
                id: ROOT_LOCATION_ID,
            },
        };
    },
};
