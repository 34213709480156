<template>
    <auth-card :loading="loading">
        <template v-slot:heading>sign in to your account</template>
        <template v-slot:default>
            <div
                class="flex items-center justify-center font-semibold font-inter p-4 py-6 bg-blue-50 text-blue-700 border border-blue-300 text-xs mb-4"
                v-if="getFlash">
                <span><icon class="w-6 h-6 mr-2 text-blue-600" name="Info"></icon></span>
                <span>{{ getFlash }}</span>
            </div>

            <Form :submit="signIn" class="w-full" ref="form">
                <div class="mb-4">
                    <TextField name="username" label="email or phone number" :validate="[required, cognitoUsername]"/>
                </div>
                <div class="mb-4">
                    <TextField name="password" label="password" type="password" :validate="required"/>
                </div>
                <div class="mb-6 flex justify-end">
                    <button @click="forgotPassword" class="btn-link" type="button">forgot password</button>
                </div>
                <div>
                    <button :disabled="loading" type="submit" class="btn-primary btn-solid btn-auth">sign in</button>
                </div>
            </Form>
        </template>
    </auth-card>
</template>

<script>
import { AuthState } from '@aws-amplify/ui-components';
import {
    dispatchAuthStateChangeEvent,
    handleSignIn,
    prepareCognitoUsername,
} from '@/components/auth/helpers';
import { mapGetters } from "vuex";
import AuthCard from "@/components/auth/AuthCard";
import Icon from '@/components/ui/Icon';
import TextField from "@/components/form/TextField";
import Form from "@/components/form/Form";
import ValidatorMixin from "@/components/form/ValidatorMixin";

export default {
    name: 'SignIn',
    mixins: [ValidatorMixin],
    components: {
        AuthCard,
        Icon,
        Form,
        TextField,
    },
    data() {
        return {
            loading: false,
            user: {},
        }
    },
    computed: {
        ...mapGetters({
            getFlash: 'flashes/getFlash',
        }),
    },
    methods: {
        async signIn({username, password}) {
            this.loading = true;

            if (this.$route.path === "/reset-password") {
                this.$router.replace({ name: 'home' });
            }

            await handleSignIn(prepareCognitoUsername(username), password, dispatchAuthStateChangeEvent);

            this.$nextTick(() => {
                this.$refs.form.$refs.form.finalForm.change('password', '');
                this.$refs.form.$refs.form.finalForm.resetFieldState('password');
                this.loading = false;
            });
        },
        forgotPassword() {
            dispatchAuthStateChangeEvent(AuthState.ForgotPassword);
        },
    },
};
</script>

