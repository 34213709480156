const routes = [
    {
        name: 'websites.index',
        path: '/websites',
        component: () => {
            return import('@/views/cms/websites/Index');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'websites',
            title: 'select a website',
            flowStartsWith: 'websites.index',
            product: 'cms',
            requiresCustomer: true,
            requiresCommunity: true,
            requiredAppAccess: ['CMS'],
        },
    },
    {
        name: 'websites.show',
        path: '/websites/:id',
        component: () => {
            return import('@/views/cms/websites/Show');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'view website',
            flowStartsWith: 'websites.index',
            passFlowTo: 'websites.show',
            product: 'cms',
            requiresCustomer: true,
            requiresCommunity: true,
            requiredAppAccess: ['CMS'],
        },
    },
    {
        name: 'websites.select-property',
        path: '/websites/select-property/new',
        component: () => {
            return import('@/views/cms/websites/SelectProperty');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'choose property',
            title: 'choose property',
            hideFromSearch: true,
            requiresCustomer: true,
            requiresCommunity: true,
            requiredAppAccess: ['CMS'],
        },
    },
    {
        name: 'websites.create',
        path: '/websites/create/new',
        component: () => {
            return import('@/views/cms/websites/Create');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'add website',
            title: 'add website',
            flowStartsWith: 'websites.create',
            product: 'cms',
            requiresCustomer: true,
            requiresCommunity: true,
            requiredAppAccess: ['CMS'],
        },
    },
    {
        name: 'websites.integration-partners',
        path: '/websites/:id/integration-partners',
        component: () => {
            return import('@/views/cms/websites/IntegrationPartners');
        },
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'integration partners',
            title: 'integration partners',
            flowStartsWith: 'websites.index',
            passFlowTo: 'websites.integration-partners',
            product: 'cms',
            requiresCustomer: true,
            requiresCommunity: true,
            requiredAppAccess: ['CMS'],
        },
    },
    {
        name: 'websites.contact-information',
        path: '/websites/:id/contact-information',
        component: () => {
            return import('@/views/cms/websites/ContactInformation');
        },
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'contact information',
            title: 'edit contact information',
            flowStartsWith: 'websites.index',
            passFlowTo: 'websites.contact-information',
            product: 'cms',
            requiresCustomer: true,
            requiresCommunity: true,
            requiredAppAccess: ['CMS'],
        },
    },
    {
        name: 'websites.domains',
        path: '/websites/:id/domains',
        component: () => {
            return import('@/views/cms/websites/Domains');
        },
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'domains',
            title: 'Domains',
            flowStartsWith: 'websites.index',
            passFlowTo: 'websites.domains',
            product: 'cms',
            requiresCustomer: true,
            requiresCommunity: true,
            requiredAppAccess: ['CMS'],
        },
    },
    {
        name: 'websites.external-links',
        path: '/websites/:id/external-links',
        component: () => {
            return import('@/views/cms/websites/ExternalLinks');
        },
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'external links',
            title: 'edit external links',
            flowStartsWith: 'websites.index',
            passFlowTo: 'websites.external-links',
            product: 'cms',
            requiresCustomer: true,
            requiresCommunity: true,
            requiredAppAccess: ['CMS'],
        },
    },
    {
        name: 'websites.site-templates',
        path: '/websites/:id/site-templates',
        component: () => {
            return import('@/views/cms/websites/SiteTemplates');
        },
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'templates',
            title: 'website template',
            flowStartsWith: 'websites.index',
            passFlowTo: 'websites.site-templates',
            product: 'cms',
            requiresCustomer: true,
            requiresCommunity: true,
            requiredAppAccess: ['CMS'],
        },
    },
    {
        name: 'websites.amenities',
        path: '/websites/:id/amenities',
        component: () => {
            return import('@/views/cms/websites/Amenities');
        },
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'amenities',
            title: 'amenities',
            flowStartsWith: 'websites.index',
            passFlowTo: 'websites.amenities',
            product: 'cms',
            requiresCustomer: true,
            requiresCommunity: true,
            requiredAppAccess: ['CMS'],
        },
    },
    {
        name: 'websites.amenities.edit',
        path: '/websites/:id/amenities/:amenityName/edit',
        component: () => {
            return import('@/views/cms/websites/EditAmenity');
        },
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'edit amenity',
            title: 'edit amenity',
            flowStartsWith: 'websites.index',
            passFlowTo: 'websites.amenities',
            product: 'cms',
            requiresCustomer: true,
            requiresCommunity: true,
            requiredAppAccess: ['CMS'],
        },
    },
    {
        name: 'websites.floorplans',
        path: '/websites/:id/floorplans',
        component: () => {
            return import('@/views/cms/floorplans/Index');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'floor plans',
            title: 'floor plans',
            flowStartsWith: 'websites.index',
            passFlowTo: 'websites.floorplans',
            product: 'cms',
            requiresCustomer: true,
            requiresCommunity: true,
            requiredAppAccess: ['CMS'],
        },
    },
    {
        name: 'websites.floorplans.import',
        path: '/websites/:id/floor-plans-import',
        component: () => {
            return import('@/views/cms/floorplans/import/Index');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'import floor plan availability',
            title: 'import floor plan availability',
            flowStartsWith: 'websites.index',
            passFlowTo: 'websites.floorplans.import',
            product: 'cms',
            requiresCustomer: true,
            requiresCommunity: true,
            requiredAppAccess: ['CMS'],
        },
    },
    {
        name: 'websites.floorplans.show',
        path: '/websites/:id/floorplans/:floorplanName',
        component: () => {
            return import('@/views/cms/floorplans/Show');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'view floor plan',
            title: 'view floor plan',
            flowStartsWith: 'websites.index',
            passFlowTo: 'websites.floorplans',
            product: 'cms',
            requiresCustomer: true,
            requiresCommunity: true,
            requiredAppAccess: ['CMS'],
        },
    },
    {
        name: 'websites.floorplans.create',
        path: '/websites/:id/floorplans/create/new',
        component: () => {
            return import('@/views/cms/floorplans/Create');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'create floor plan',
            title: 'create floor plan',
            flowStartsWith: 'websites.index',
            passFlowTo: 'websites.floorplans',
            product: 'cms',
            requiresCustomer: true,
            requiresCommunity: true,
            requiredAppAccess: ['CMS'],
        },
    },
    {
        name: 'websites.floorplans.destroy',
        path: '/websites/:id/floorplans/destroy/:floorplanName',
        component: () => {
            return import('@/views/cms/floorplans/Destroy');
        },
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'remove floor plan',
            title: 'remove floor plan',
            flowStartsWith: 'websites.index',
            passFlowTo: 'websites.floorplans',
            product: 'cms',
            requiresCustomer: true,
            requiresCommunity: true,
            requiredAppAccess: ['CMS'],
        },
    },
    {
        name: 'websites.points-of-interest',
        path: '/websites/:id/points-of-interest',
        component: () => {
            return import('@/views/cms/websites/PointsOfInterest');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'points of interest',
            title: 'points of interest',
            flowStartsWith: 'websites.index',
            passFlowTo: 'websites.points-of-interest',
            product: 'cms',
            requiresCustomer: true,
            requiresCommunity: true,
            requiredAppAccess: ['CMS'],
        },
    },
    {
        name: 'websites.points-of-interest.categories',
        path: '/websites/:id/points-of-interest/categories',
        component: () => {
            return import('@/views/cms/websites/PointsOfInterestCategories');
        },
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'points of interest categories',
            title: 'points of interest categories',
            flowStartsWith: 'websites.index',
            passFlowTo: 'websites.points-of-interest.categories',
            product: 'cms',
            requiresCustomer: true,
            requiresCommunity: true,
            requiredAppAccess: ['CMS'],
        },
    },
    {
        name: 'websites.navigation-settings',
        path: '/websites/:id/navigation-settings',
        component: () => {
            return import('@/views/cms/websites/NavigationSettings');
        },
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'navigation settings',
            title: 'navigation settings',
            flowStartsWith: 'websites.index',
            passFlowTo: 'websites.navigation-settings',
            product: 'cms',
            requiresCustomer: true,
            requiresCommunity: true,
            requiredAppAccess: ['CMS'],
            size: 'small',
        },
    },
    {
        name: 'websites.embed-codes',
        path: '/websites/:id/embed-codes',
        component: () => {
            return import('@/views/cms/websites/EmbeddedCodes');
        },
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'embed codes',
            title: 'manage website embed codes',
            flowStartsWith: 'websites.index',
            passFlowTo: 'websites.codes',
            product: 'cms',
            requiresCustomer: true,
            requiresCommunity: true,
            requiredAppAccess: ['CMS'],
        },
    },
    {
        name: 'websites.log-history',
        path: '/websites/:id/log-history',
        component: () => {
            return import('@/views/cms/websites/LogHistory');
        },
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'log history',
            title: 'log history',
            flowStartsWith: 'websites.index',
            passFlowTo: 'websites.log-history',
            product: 'cms',
            requiresCustomer: true,
            requiresCommunity: true,
            requiredAppAccess: ['CMS'],
        },
    },
];

export default routes;
