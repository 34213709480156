export default {
    applications: '/apps',
    leases: '/leases',
    locations: '/locations',
    memberships: '/memberships',
    parties: '/parties',
    persons: '/persons',
    identities: '/identities',
    personIdentities: '/person-identities',
    unlinkIdentity: '/persons/{personId}/identities/{identityId}/unlink',
    reInviteIdentity: '/identities/{identityId}/re-invite',
    profiles: '/profiles',
    profileTemplates: '/data-block-templates',
    profileDataBlocks: {
        getList: ['GET', '/profiles/{profileId}/shared-data-blocks'],
        create: ['POST', '/profiles/{profileId}/shared-data-blocks/batch'],
    },
    profileDataBlockVersioned: {
        get: ['GET', '/profiles/{profileId}/shared-data-blocks/{name}/versions/{version}'],
        update: ['PUT', '/profiles/{profileId}/shared-data-blocks/{name}/versions/{version}'],
        delete: ['DELETE', '/profiles/{profileId}/shared-data-blocks/{name}/versions/{version}'],
    },
    appProfileDataBlocks: '/apps/{appCode}/profiles/{profileId}/app-data-blocks',
    relationshipTypes: '/relationship-types',
    specifications: '/specifications',
    dataKeys: '/data-keys',
    sharedDataBlocks: '/relationship-types/{type}/shared-data-blocks',
    sharedDataBlockVersioned: {
        get: ['GET', '/relationship-types/{type}/shared-data-blocks/{name}/versions/{version}'],
        update: ['PUT', '/relationship-types/{type}/shared-data-blocks/{name}/versions/{version}'],
        delete: ['DELETE', '/relationship-types/{type}/shared-data-blocks/{name}/versions/{version}'],
    },
    appDataBlocks: '/apps/{appCode}/relationship-types/{type}/app-data-blocks',
    appDataBlockVersioned: {
        update: ['PUT', '/apps/{appCode}/relationship-types/{type}/app-data-blocks/versions/{version}'],
        delete: ['DELETE', '/apps/{appCode}/relationship-types/{type}/app-data-blocks/versions/{version}'],
    },
};
