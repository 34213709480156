import store from '@/store';

const permissionChecker = () => store.getters['dh/hasPermission'] || store.getters['cms/hasPermission'];

const routes = [
    {
        name: 'calendar_events.index',
        path: '/calendar-events',
        component: () => {
            return import('@/views/calendar/events/Index');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'Calendar Events',
            title: 'Calendar Events',
            size: 'large',
            flowStartsWith: 'calendar_events.index',
            allowedRoles: ['CUSTOMER_ADMIN', 'SUPER_ADMIN', 'USER'],
            // requiredAppAccess: ['MIA'],
            product: 'marketing',
            permissionChecker,
        },
    },
    {
        name: 'calendar_events.create',
        path: '/calendar-events/create',
        component: () => {
            return import('@/views/calendar/events/Create');
        },
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'create event',
            title: 'Create Event',
            flowStartsWith: 'calendar_events.index',
            allowedRoles: ['CUSTOMER_ADMIN', 'SUPER_ADMIN', 'USER'],
            product: 'marketing',
            permissionChecker,
        },
    },
    {
        name: 'calendar_events.detail',
        path: '/calendar-events/detail/:appointment_id/:slot_id',
        component: () => {
            return import('@/views/calendar/events/Detail');
        },
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'Event Detail',
            title: 'Event Detail',
            flowStartsWith: 'calendar_events.index',
            allowedRoles: ['CUSTOMER_ADMIN', 'SUPER_ADMIN', 'USER'],
            product: 'marketing',
            size: 'small',
            permissionChecker,
        },
    },
];

export default routes;
