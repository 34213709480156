<template>
  <FinalForm
    ref="form"
    :submit="submit"
    :initialValues="initialValues"
    @change.self="$emit('change', $event)"
  >
    <template v-slot="props">
      <form class="w-full" @submit.prevent="(e) => handleSubmit(e, props.handleSubmit)">
        <slot name="default" v-bind="props"></slot>
      </form>
    </template>
  </FinalForm>
</template>

<script>
  import {FinalForm} from 'vue-final-form';
  import InitializeFormMixin from "@/components/form/InitializeFormMixin";
  import EventBus from "@/utils/EventBus";

  export default {
    name: "Form",
    components: {
      FinalForm
    },
    mixins: [
      InitializeFormMixin,
    ],
    props: {
      submit: {
        type: Function,
        required: true,
      },
      initialValues: {
        type: Object,
        default: () => ({})
      },
    },
    emits: ['change'],
    computed: {
      formState() {
        return this.$refs.form.formState;
      },
      formApi() {
        return this.$refs.form.finalForm;
      },
    },
    methods: {
      async handleSubmit(e, submit) {
        await submit();

        const el = document.querySelector('.form-error');

        if (el) {
          el.scrollIntoView({block: 'center', behavior: 'smooth'})
        }
      },
      restart() {
        this.$refs.form.finalForm.restart();
      },
    },
    mounted() {
      EventBus.on('locale-changed', this.restart);
    },
    beforeUnmount() {
      EventBus.off('locale-changed', this.restart);
    },
  }
</script>
