import { isQuextAdmin } from '@/utils/actionAllowedRules';

export default [
    {
        routeName: 'applications.index',
        items: [
            {
                title: 'add new application',
                routeName: 'applications.create',
                params: {},
            },
            {
                title: 'deleted applications',
                routeName: 'applications.deletedApplications.index',
                params: {},
                allowed: isQuextAdmin,
            },
            {
                title: 'manage bundles',
                routeName: 'applications.bundles.index',
                params: {},
            },
        ],
    },
    {
        routeName: 'applications.create',
        items: [],
    },
    {
        routeName: 'applications.edit',
        items: [
            {
                title: 'manage roles',
                routeName: 'applications.roles.index',
                params: {
                    appId: '{appId}',
                },
            },
            {
                title: 'manage permissions',
                routeName: 'applications.permissions.index',
                params: {
                    appId: '{appId}',
                },
            },
            {
                title: 'customer access',
                routeName: 'applications.customers.index',
                params: {},
            },
        ],
    },
    {
        routeName: 'applications.roles.index',
        items: [
            {
                title: 'deleted roles',
                routeName: 'applications.deletedRoles.index',
                params: {},
                allowed: isQuextAdmin,
            },
        ],
    },
    {
        routeName: 'applications.permissions.index',
        items: [
            // {
            //   title: "deleted permissions",
            //   routeName: "applications.deletedPermissions.index",
            //   params: {},
            //   allowed: isQuextAdmin,
            // },
        ],
    },
    {
        routeName: 'applications.customers.users.details',
        items: [],
    },
    {
        routeName: 'applications.bundles.index',
        items: [
            {
                title: 'add new bundle',
                routeName: 'applications.bundles.create',
                params: {},
            },
        ],
    },
    {
        routeName: 'applications.bundles.edit',
        items: [],
    },
];
