import mutations from './mutations';
import actions from './actions';
import getters from './getters';

const state = {
    application: null,
    currentApplicant: null,
    future_guarantor: null,
    future_applicant: null,
    /* Replace this array with real data once the endpoint is ready */
    applications: [
        {
            active: true,
            applicantionId: 'application-1',
            leaseId: 'b6136d31-652d-480d-a0f4-082ca7991c67',
            application_status: 'in progress',
            record_created: '2022-06-08T09:54:16.168Z',
            move_in: '2022-08-06T09:54:16.168Z',
            balance: '$50.00',
            community: 'e8848f98-f3df-4d3e-88ce-ecd10f6fccd6',
            rent_amount: '$895.00',
            lease_term: '2 months',
            application_submited: '2022-07-08T09:54:16.168Z',
            unit: '432',
            amenities: '',
            layout: '',
            application_fee: '$50',
            applicants_global_status: 'pending guarantor',
            notes: [
                {
                    date: '2022-06-20T09:54:16.168Z',
                    author: 'Jan Baker',
                    note: 'Et omnia in potestate nostra esse natura liber, libera, libere valeant; sed illis non est in nostra potestate sunt infirmi, servilis, licet, lex pertinet.',
                },
            ],
            records: [
                {
                    date: '2022-06-08T09:54:16.168Z',
                    activity: 'system login',
                    user: 'John Doe',
                },
                {
                    date: '2022-06-08T11:54:16.168Z',
                    activity: 'system logout',
                    user: 'John Doe',
                },
                {
                    date: '2022-06-08T09:54:16.168Z',
                    activity: 'system login',
                    user: 'Tiffany Garcia',
                },
                {
                    date: '2022-06-08T11:54:16.168Z',
                    activity: 'system logout',
                    user: 'Tiffany Garcia',
                },
            ],
            payments: [
                {
                    payment_status: 'successful',
                    payment_method: 'credit card',
                    posted: '2022-06-08T09:54:16.168Z',
                    due: '2022-06-08T09:54:16.168Z',
                    charge_code: 'application payment',
                    ref_number: '21223157',
                    last_modified: 'system',
                    payment: '$50.00',
                    charge: '',
                    balance: '$50.00',
                },
                {
                    payment_status: 'successful',
                    payment_method: 'credit card',
                    posted: '2022-06-12T09:54:16.168Z',
                    due: '2022-06-12T09:54:16.168Z',
                    charge_code: 'application payment',
                    ref_number: '21223156',
                    last_modified: 'system',
                    payment: '$50.00',
                    charge: '',
                    balance: '$150.00',
                },
            ],
            applicants: [
                {
                    id: '1',
                    firstName: 'John',
                    lastName: 'Doe',
                    fullName: 'John Doe',
                    phone: '(568)776-6785',
                    email: 'john.doe@gmail.com',
                    applicant_status: 'submitted',
                    guarantor: null,
                    is_guarantor_needed: false,
                    role: 'applicant',
                    occupants: [],
                    pets: [
                        {
                            name: 'Noah',
                            breed: 'English Bulldog',
                            age: '1 year old',
                            weight: '20 pounds',
                            species: 'dog',
                            type: 'service animal',
                        },
                    ],
                    vehicles: [
                        {
                            make: 'Toyota',
                            model: 'Rav4',
                            year: '2019',
                            color: 'White',
                        },
                    ],
                },
                {
                    id: '2',
                    firstName: 'Tiffany',
                    lastName: 'Garcia',
                    fullName: 'Tiffany Garcia',
                    phone: '(568)776-6785',
                    email: 'tiffany.garcia@gmail.com',
                    pets: [],
                    applicant_status: 'submitted',
                    guarantor: null,
                    is_guarantor_needed: false,
                    role: 'applicant',
                    occupants: [
                        {
                            id: '1',
                            firstName: 'Ashley',
                            lastName: 'Garcia',
                            fullName: 'Diana Garcia',
                            phone: '(123)486-7920',
                            email: 'ahsley.garcia@gmail.com',
                            relationship: 'Daughter',
                            role: 'occupant',
                        },
                    ],
                    vehicles: [
                        {
                            make: 'BWM',
                            model: 'Serie 3',
                            year: '2018',
                            color: 'Blue',
                        },
                    ],
                },
                {
                    id: '3',
                    firstName: 'Dan',
                    lastName: 'Rogers',
                    fullName: 'Dan Rogers',
                    phone: '(568)776-6785',
                    email: 'dan.rogers@gmail.com',
                    applicant_status: 'pending guarantor',
                    is_guarantor_needed: true,
                    guarantor: {
                        id: '1',
                        firstName: 'Homer',
                        lastName: 'Berns',
                        fullName: 'Homer Berns',
                        phone: '(568)776-6785',
                        email: 'homer.berns@gmail.com',
                        applicant_status: 'submitted',
                        role: 'guarantor',
                    },
                    role: 'applicant',
                    pets: [
                        {
                            name: 'Zeus',
                            breed: 'German Sheperd',
                            age: '3 year old',
                            weight: '50 pounds',
                            species: 'dog',
                            type: 'service animal',
                        },
                    ],
                    vehicles: [],
                    occupants: [],
                },
            ],
        },
        {
            active: true,
            leaseId: 'b6136d31-652d-480d-a0f4-082ca7991c67',
            applicantionId: 'application-2',
            application_status: 'submitted',
            record_created: '2022-06-08T09:54:16.168Z',
            move_in: '2022-08-06T09:54:16.168Z',
            balance: '$0.00',
            application_submited: '2022-07-09T09:54:16.168Z',
            community: 'e8848f98-f3df-4d3e-88ce-ecd10f6fccd6',
            rent_amount: '$895.00',
            lease_term: '2 months',
            unit: '329',
            amenities: '',
            layout: '',
            application_fee: '$50',
            applicants_global_status: 'submitted',
            notes: [],
            records: [
                {
                    date: '2022-06-08T07:54:16.168Z',
                    activity: 'system login',
                    user: 'Geoffrey Stoner',
                },
                {
                    date: '2022-06-08T08:30:16.168Z',
                    activity: 'system login',
                    user: 'Andres Stoner',
                },
                {
                    date: '2022-06-08T11:54:16.168Z',
                    activity: 'system logout',
                    user: 'Andres Stoner',
                },
                {
                    date: '2022-06-08T13:54:16.168Z',
                    activity: 'system logout',
                    user: 'Geoffrey Stoner',
                },
            ],
            payments: [
                {
                    payment_status: 'successful',
                    payment_method: 'credit card',
                    posted: '2022-06-08T09:54:16.168Z',
                    due: '2022-06-08T09:54:16.168Z',
                    charge_code: 'application payment',
                    ref_number: '21223157',
                    last_modified: 'system',
                    payment: '$50.00',
                    charge: '',
                    balance: '$0.00',
                },
                {
                    payment_status: 'successful',
                    payment_method: 'credit card',
                    posted: '2022-06-12T09:54:16.168Z',
                    due: '2022-06-12T09:54:16.168Z',
                    charge_code: 'application payment',
                    ref_number: '21223156',
                    last_modified: 'system',
                    payment: '$50.00',
                    charge: '',
                    balance: '$50.00',
                },
            ],
            applicants: [
                {
                    id: '4',
                    firstName: 'Geoffrey',
                    lastName: 'Stoner',
                    fullName: 'Geoffrey Stoner',
                    phone: '(123)456-7890',
                    email: 'geoff.stoner@gmail.com',
                    applicant_status: 'submitted',
                    occupants: [],
                    guarantor: null,
                    is_guarantor_needed: false,
                    role: 'applicant',
                    pets: [
                        {
                            name: 'Katrina',
                            breed: 'Doberman',
                            age: '2 year old',
                            weight: '50 pounds',
                            species: 'dog',
                            type: 'pet',
                        },
                        {
                            name: 'Cliford',
                            breed: 'Doberman',
                            age: '3 year old',
                            weight: '60 pounds',
                            species: 'dog',
                            type: 'pet',
                        },
                    ],
                    vehicles: [
                        {
                            make: 'Honda',
                            model: 'Accord',
                            year: '2020',
                            color: 'Gray',
                        },
                    ],
                },
                {
                    id: '5',
                    firstName: 'Andres',
                    lastName: 'Stoner',
                    fullName: 'Andres Stoner',
                    phone: '(123)456-7891',
                    email: 'andres.stoner@gmail.com',
                    applicant_status: 'submitted',
                    occupants: [],
                    pets: [],
                    guarantor: null,
                    is_guarantor_needed: false,
                    role: 'applicant',
                    vehicles: [
                        {
                            make: 'Honda',
                            model: 'Pilot',
                            year: '2021',
                            color: 'Blue',
                        },
                    ],
                },
            ],
        },
        {
            active: true,
            leaseId: 'b6136d31-652d-480d-a0f4-082ca7991c67',
            applicantionId: 'application-3',
            application_status: 'in progress',
            record_created: '2021-06-08T09:54:16.168Z',
            move_in: '2021-08-06T09:54:16.168Z',
            balance: '$50.00',
            application_submited: '2021-07-09T09:54:16.168Z',
            community: 'e8848f98-f3df-4d3e-88ce-ecd10f6fccd6',
            rent_amount: '$895.00',
            lease_term: '2 months',
            notes: [],
            amenities: '',
            layout: '',
            unit: '322',
            application_fee: '$50',
            applicants_global_status: 'in progress',
            records: [
                {
                    date: '2021-06-08T09:54:16.168Z',
                    activity: 'system login',
                    user: 'Phil Dawson',
                },
                {
                    date: '2021-06-08T12:54:16.168Z',
                    activity: 'system logout',
                    user: 'Phil Dawson',
                },
            ],
            payments: [],
            applicants: [
                {
                    id: '6',
                    firstName: 'Phil',
                    lastName: 'Dawson',
                    fullName: 'Phill Dawson',
                    phone: '(123)456-7891',
                    email: 'phil.dawson@gmail.com',
                    applicant_status: 'submitted',
                    guarantor: null,
                    is_guarantor_needed: true,
                    role: 'applicant',
                    occupants: [
                        {
                            id: '2',
                            firstName: 'Abby',
                            lastName: 'Dawson',
                            fullName: 'Abby Dawson',
                            phone: '(123)486-7920',
                            email: 'abby.dawson@gmail.com',
                            relationship: 'Wife',
                        },
                    ],
                    pets: [],
                    vehicles: [
                        {
                            make: 'Lexus',
                            model: 'RX',
                            year: '2021',
                            color: 'Black',
                        },
                    ],
                },
            ],
        },
        {
            active: true,
            leaseId: 'b6136d31-652d-480d-a0f4-082ca7991c67',
            applicantionId: 'application-4',
            application_status: 'declined',
            record_created: '2021-06-08T09:54:16.168Z',
            move_in: '2021-08-06T09:54:16.168Z',
            balance: '$0.00',
            application_submited: '2021-07-09T09:54:16.168Z',
            community: 'e8848f98-f3df-4d3e-88ce-ecd10f6fccd6',
            rent_amount: '$895.00',
            lease_term: '2 months',
            amenities: '',
            layout: '',
            unit: '129',
            application_fee: '$50',
            applicants_global_status: 'declined',
            notes: [],
            records: [
                {
                    date: '2021-06-08T09:54:16.168Z',
                    activity: 'system login',
                    user: 'Charlie Andrews',
                },
                {
                    date: '2021-06-08T10:54:16.168Z',
                    activity: 'system logout',
                    user: 'Charlie Andrews',
                },
            ],
            payments: [
                {
                    payment_status: 'successful',
                    payment_method: 'credit card',
                    posted: '2022-06-12T09:54:16.168Z',
                    due: '2022-06-12T10:40:16.168Z',
                    charge_code: 'application payment',
                    ref_number: '21223156',
                    last_modified: 'system',
                    payment: '$50.00',
                    charge: '',
                    balance: '$0.00',
                },
            ],
            applicants: [
                {
                    id: '7',
                    firstName: 'Charlie',
                    lastName: 'Andrews',
                    fullName: 'Charlie Andrews',
                    phone: '(123)456-7891',
                    email: 'charlie.andrews@gmail.com',
                    applicant_status: 'declined',
                    occupants: [],
                    pets: [],
                    vehicles: [],
                    guarantor: null,
                    is_guarantor_needed: false,
                    role: 'applicant',
                },
            ],
        },
        {
            active: true,
            leaseId: 'b6136d31-652d-480d-a0f4-082ca7991c67',
            applicantionId: 'application-5',
            application_status: 'in progress',
            record_created: '2021-06-08T09:54:16.168Z',
            move_in: '2021-08-06T09:54:16.168Z',
            balance: '$50.00',
            application_submited: '2021-07-09T09:54:16.168Z',
            community: 'e8848f98-f3df-4d3e-88ce-ecd10f6fccd6',
            rent_amount: '$895.00',
            lease_term: '2 months',
            notes: [],
            amenities: '',
            layout: '',
            unit: '322',
            application_fee: '$50',
            applicants_global_status: 'in progress',
            records: [
                {
                    date: '2021-06-08T09:54:16.168Z',
                    activity: 'system login',
                    user: 'Phil Dawson',
                },
                {
                    date: '2021-06-08T12:54:16.168Z',
                    activity: 'system logout',
                    user: 'Phil Dawson',
                },
            ],
            payments: [],
            applicants: [
                {
                    id: '11',
                    firstName: 'Owen',
                    lastName: 'Rogers',
                    fullName: 'Owen Rogers',
                    phone: '(123)456-7891',
                    email: 'owen.rogers@gmail.com',
                    applicant_status: 'submitted',
                    guarantor: null,
                    is_guarantor_needed: true,
                    role: 'applicant',
                    occupants: [
                        {
                            id: '9',
                            firstName: 'Abby',
                            lastName: 'Dawson',
                            fullName: 'Abby Dawson',
                            phone: '(123)486-7920',
                            email: 'abby.dawson@gmail.com',
                            relationship: 'Wife',
                        },
                    ],
                    pets: [],
                    vehicles: [
                        {
                            make: 'Lexus',
                            model: 'RX',
                            year: '2021',
                            color: 'Black',
                        },
                    ],
                },
            ],
        },
        {
            active: true,
            leaseId: 'b6136d31-652d-480d-a0f4-082ca7991c67',
            applicantionId: 'application-6',
            community: 'e8848f98-f3df-4d3e-88ce-ecd10f6fccd6',
            rent_amount: '$895.00',
            lease_term: '2 months',
            amenities: '',
            layout: '',
            application_status: 'accepted',
            record_created: '2019-06-08T09:54:16.168Z',
            move_in: '2019-08-06T09:54:16.168Z',
            balance: '$0.00',
            application_submited: '2019-07-09T09:54:16.168Z',
            unit: '329',
            application_fee: '$50',
            applicants_global_status: 'resident',
            payments: [
                {
                    payment_status: 'successful',
                    payment_method: 'credit card',
                    posted: '2019-07-09T09:54:16.168Z',
                    due: '2019-07-09T09:54:16.168Z',
                    charge_code: 'application payment',
                    ref_number: '21223157',
                    last_modified: 'system',
                    payment: '$50.00',
                    charge: '',
                    balance: '$0.00',
                },
                {
                    payment_status: 'successful',
                    payment_method: 'credit card',
                    posted: '2019-07-09T09:54:16.168Z',
                    due: '2019-07-09T09:54:16.168Z',
                    charge_code: 'application payment',
                    ref_number: '21223156',
                    last_modified: 'system',
                    payment: '$50.00',
                    charge: '',
                    balance: '$50.00',
                },
            ],
            notes: [
                {
                    date: '2019-08-06T09:54:16.168Z',
                    author: 'Jan Baker',
                    note: 'Et omnia in potestate nostra esse natura liber, libera, libere valeant; sed illis non est in nostra potestate sunt infirmi, servilis, licet, lex pertinet.',
                },
                {
                    date: '2019-10-06T09:54:16.168Z',
                    author: 'Rob Andrews',
                    note: 'Et omnia in potestate nostra esse natura liber, libera, libere valeant; sed illis non est in nostra potestate sunt infirmi, servilis, licet, lex pertinet.',
                },
            ],
            records: [
                {
                    date: '2019-08-06T09:54:16.168Z',
                    activity: 'system login',
                    user: 'Jorge Gomez',
                },
                {
                    date: '2019-08-06T10:29:16.168Z',
                    activity: 'system logout',
                    user: 'Jorge Gomez',
                },
                {
                    date: '2019-08-06T09:54:16.168Z',
                    activity: 'system login',
                    user: 'Valeria Barboza',
                },
            ],
            applicants: [
                {
                    id: '8',
                    firstName: 'Jorge',
                    lastName: 'Gomez',
                    fullName: 'Jorge Gomez',
                    phone: '(123)456-7820',
                    email: 'jorge.gmez@gmail.com',
                    applicant_status: 'resident',
                    role: 'applicant',
                    guarantor: null,
                    is_guarantor_needed: false,
                    pets: [
                        {
                            name: 'Kia',
                            breed: 'French Bulldog',
                            age: '1 year old',
                            weight: '20 pounds',
                            species: 'dog',
                            type: 'pet',
                        },
                    ],
                    occupants: [
                        {
                            id: '3',
                            firstName: 'Diana',
                            lastName: 'Gomez Barboza',
                            fullName: 'Diana Gomez Barboza',
                            phone: '(123)486-7920',
                            email: 'diana.gmezb@gmail.com',
                            relationship: 'Daughter',
                        },
                    ],
                    vehicles: [
                        {
                            make: 'Hyundai',
                            model: 'Tucson',
                            year: '2022',
                            color: 'Amazon Gray',
                        },
                    ],
                },
                {
                    id: '9',
                    firstName: 'Valeria',
                    lastName: 'Barboza',
                    fullName: 'Valeria Barboza',
                    phone: '(123)456-7881',
                    email: 'vale.barboza@gmail.com',
                    applicant_status: 'resident',
                    role: 'applicant',
                    guarantor: null,
                    is_guarantor_needed: false,
                    pets: [
                        {
                            name: 'Kia',
                            breed: 'French Bulldog',
                            age: '1 year old',
                            weight: '20 pounds',
                            species: 'dog',
                            type: 'pet',
                        },
                    ],
                    occupants: [
                        {
                            id: '3',
                            firstName: 'Diana',
                            lastName: 'Gomez Barboza',
                            fullName: 'Diana Gomez Barboza',
                            phone: '(123)486-7920',
                            email: 'diana.gmezb@gmail.com',
                            relationship: 'Daughter',
                        },
                    ],
                    vehicles: [
                        {
                            make: 'Range Rover',
                            model: 'Evoque',
                            year: '2021',
                            color: 'White',
                        },
                    ],
                },
            ],
        },
        {
            active: true,
            leaseId: 'b6136d31-652d-480d-a0f4-082ca7991c67',
            applicantionId: 'application-12',
            application_status: 'in progress',
            record_created: '2021-06-08T09:54:16.168Z',
            move_in: '2021-08-06T09:54:16.168Z',
            balance: '$50.00',
            application_submited: '2021-07-09T09:54:16.168Z',
            community: 'e8848f98-f3df-4d3e-88ce-ecd10f6fccd6',
            rent_amount: '$895.00',
            lease_term: '2 months',
            notes: [],
            amenities: '',
            layout: '',
            unit: '322',
            application_fee: '$50',
            applicants_global_status: 'in progress',
            records: [
                {
                    date: '2021-06-08T09:54:16.168Z',
                    activity: 'system login',
                    user: 'Phil Dawson',
                },
                {
                    date: '2021-06-08T12:54:16.168Z',
                    activity: 'system logout',
                    user: 'Phil Dawson',
                },
            ],
            payments: [],
            applicants: [
                {
                    id: '13',
                    firstName: 'Angelica',
                    lastName: 'Rayes',
                    fullName: 'Angelica Rayes',
                    phone: '(123)456-7891',
                    email: 'angelica.rayes@gmail.com',
                    applicant_status: 'submitted',
                    guarantor: null,
                    is_guarantor_needed: true,
                    role: 'applicant',
                    occupants: [
                        {
                            id: '9',
                            firstName: 'Abby',
                            lastName: 'Dawson',
                            fullName: 'Abby Dawson',
                            phone: '(123)486-7920',
                            email: 'abby.dawson@gmail.com',
                            relationship: 'Wife',
                        },
                    ],
                    pets: [],
                    vehicles: [
                        {
                            make: 'Lexus',
                            model: 'RX',
                            year: '2021',
                            color: 'Black',
                        },
                    ],
                },
            ],
        },
        {
            active: true,
            leaseId: 'b6136d31-652d-480d-a0f4-082ca7991c67',
            applicantionId: 'application-6',
            application_status: 'rental verification',
            record_created: '2019-06-08T09:54:16.168Z',
            move_in: '2019-08-06T09:54:16.168Z',
            balance: '$0.00',
            application_submited: '2019-07-09T09:54:16.168Z',
            unit: '349',
            community: 'e8848f98-f3df-4d3e-88ce-ecd10f6fccd6',
            rent_amount: '$895.00',
            lease_term: '2 months',
            amenities: '',
            layout: '',
            application_fee: '$50',
            applicants_global_status: 'in progress',
            payments: [
                {
                    payment_status: 'successful',
                    payment_method: 'credit card',
                    posted: '2019-07-10T09:54:16.168Z',
                    due: '2019-07-10T09:54:16.168Z',
                    charge_code: 'application payment',
                    ref_number: '21223157',
                    last_modified: 'system',
                    payment: '$50.00',
                    charge: '',
                    balance: '$0.00',
                },
                {
                    payment_status: 'successful',
                    payment_method: 'credit card',
                    posted: '2019-07-09T09:54:16.168Z',
                    due: '2019-07-09T09:54:16.168Z',
                    charge_code: 'application payment',
                    ref_number: '21223156',
                    last_modified: 'system',
                    payment: '$50.00',
                    charge: '',
                    balance: '$50.00',
                },
            ],
            notes: [
                {
                    date: '2019-08-06T09:54:16.168Z',
                    author: 'Jan Baker',
                    note: 'Et omnia in potestate nostra esse natura liber, libera, libere valeant; sed illis non est in nostra potestate sunt infirmi, servilis, licet, lex pertinet.',
                },
                {
                    date: '2019-10-06T09:54:16.168Z',
                    author: 'Rob Andrews',
                    note: 'Et omnia in potestate nostra esse natura liber, libera, libere valeant; sed illis non est in nostra potestate sunt infirmi, servilis, licet, lex pertinet.',
                },
            ],
            records: [
                {
                    date: '2019-08-06T09:54:16.168Z',
                    activity: 'system login',
                    user: 'Jason Anders',
                },
                {
                    date: '2019-08-06T10:29:16.168Z',
                    activity: 'system logout',
                    user: 'Jason Anders',
                },
                {
                    date: '2019-08-06T08:54:16.168Z',
                    activity: 'system login',
                    user: 'Juan Martinez',
                },
            ],
            applicants: [
                {
                    id: '10',
                    firstName: 'Jason',
                    lastName: 'Anders',
                    fullName: 'Jason Anders',
                    phone: '(123)456-7824',
                    email: 'jason.anders@gmail.com',
                    applicant_status: 'submitted',
                    pets: [],
                    vehicles: [],
                    occupants: [],
                    guarantor: null,
                    is_guarantor_needed: false,
                    role: 'applicant',
                },
                {
                    id: '11',
                    firstName: 'Juan',
                    lastName: 'Martinez',
                    fullName: 'Juan Martinez',
                    phone: '(123)486-7821',
                    email: 'juan.martinez@gmail.com',
                    applicant_status: 'identity verification',
                    pets: [],
                    occupants: [],
                    guarantor: null,
                    is_guarantor_needed: false,
                    vehicles: [],
                    role: 'applicant',
                },
            ],
        },
    ],
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
