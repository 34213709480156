import store from '@/store';

const preparePermissionChecker = permissionId => () => store.getters['sreq/hasPermission'](permissionId);

export default [
    {
        path: '/service-requests',
        name: 'sreq.index',
        component: () => import(/* webpackChunkName: "serviceRequest" */ '@/views/sreq/list/RequestsList'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'service requests',
            title: 'manage service requests',
            flowStartsWith: 'sreq.index',
            product: 'sreq',
            permissionChecker: preparePermissionChecker('SRV'),
        },
    },
    {
        path: '/service-requests/create',
        name: 'sreq.create',
        component: () => import(/* webpackChunkName: "serviceRequestCreate" */ '@/views/sreq/requests/RequestCreate'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'add new',
            title: 'add new request',
            flowStartsWith: 'sreq.create',
            product: 'sreq',
            permissionChecker: preparePermissionChecker('SRC'),
        },
    },
    {
        path: '/service-requests/:requestId',
        name: 'sreq.details',
        component: () => import(/* webpackChunkName: "serviceRequestDetails" */ '@/views/sreq/requests/RequestDetails'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'view request details',
            title: 'service request details',
            flowStartsWith: 'sreq.index',
            product: 'sreq',
            passFlowTo: 'sreq.details',
            permissionChecker: preparePermissionChecker('SRV'),
        },
    },
    {
        path: '/service-requests/:requestId/feed',
        name: 'sreq.feed',
        component: () => import(/* webpackChunkName: "serviceRequestFeed" */ '@/views/sreq/requests/RequestFeed'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'request feed',
            title: 'request feed',
            flowStartsWith: 'sreq.index',
            product: 'sreq',
            permissionChecker: preparePermissionChecker('SRV'),
        },
    },
    {
        path: '/service-requests/:requestId/print',
        name: 'sreq.print.single',
        component: () => import(/* webpackChunkName: "serviceRequestsPrint" */ '@/views/sreq/requests/RequestsPrint'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'print',
            title: 'print',
            flowStartsWith: 'sreq.index',
            product: 'sreq',
            permissionChecker: preparePermissionChecker('SRPr'),
        },
    },
    {
        path: '/service-requests/:requestId/time-in-out-notes',
        name: 'sreq.timing.notes',
        component: () => import(/* webpackChunkName: "serviceRequestsTimingNotes" */ '@/views/sreq/requests/TimeInOutNotes'),
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'add new in/out time',
            title: 'add new in/out time',
            flowStartsWith: 'sreq.index',
            product: 'sreq',
            permissionChecker: preparePermissionChecker('TLM'),
        },
    },
    {
        path: '/service-requests/print',
        name: 'sreq.print.bulk',
        component: () => import(/* webpackChunkName: "serviceRequestsPrint" */ '@/views/sreq/requests/RequestsPrint'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'bulk printing',
            title: 'bulk printing',
            flowStartsWith: 'sreq.index',
            product: 'sreq',
            permissionChecker: preparePermissionChecker('SRPr'),
        },
    },
    {
        path: '/service-requests/settings/community',
        name: 'sreq.settings.community',
        component: () => import(/* webpackChunkName: "serviceRequestSettings" */ '@/views/sreq/settings/CommunitySettings'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'service requests settings',
            title: 'service requests settings',
            flowStartsWith: 'sreq.settings.community',
            product: 'sreq',
            permissionChecker: preparePermissionChecker('CSV'),
        },
    },
    {
        path: '/service-requests/settings/community/bulk',
        name: 'sreq.settings.community.bulk',
        component: () => import(/* webpackChunkName: "serviceRequestSettings" */ '@/views/sreq/settings/BulkUpdate'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'apply settings to other communities',
            title: 'apply settings to other communities',
            flowStartsWith: 'sreq.settings.community',
            product: 'sreq',
            permissionChecker: preparePermissionChecker('CSM'),
        },
    },
];
