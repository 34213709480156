<template>
  <auth-card :loading="loading">
    <template v-slot:default>
      <div v-show="!codeSent" class="auth-card_inner-block">
        <b class="auth-heading">forgot your password?</b>
        <p class="mb-6 text-center">Enter your email or phone number below and we will send a message to reset your password</p>
        <Form :submit="sendCode" class="w-full" ref="codeForm">
          <div class="mb-4">
            <TextField
              name="username"
              label="email or phone number"
              :validate="[required, cognitoUsername]"
              :disabled="loading"
            />
          </div>
          <div>
            <button :disabled="loading" type="submit" class="btn-primary btn-solid btn-auth">reset my password</button>
          </div>
        </Form>
      </div>
      <div v-show="codeSent" class="auth-card_inner-block">
        <b class="auth-heading">reset your password</b>
        <p class="mb-6 text-center">We've sent you an email with verification code to reset your password. Please enter your verification code and new password</p>
        <Form :submit="submit" class="w-full" ref="passwordForm">
          <div class="mb-4">
            <TextField
              name="code"
              label="code"
              :validate="required"
              :disabled="loading"
              autocomplete="off"
            />
          </div>
          <div class="mb-4">
            <TextField
              type="password"
              name="newPassword"
              label="new password"
              :validate="required"
              :disabled="loading"
              autocomplete="off"
            />
          </div>
          <div>
            <button :disabled="loading" type="submit" class="btn-primary btn-solid btn-auth">reset my password</button>
          </div>
        </Form>
      </div>
      <div class="mt-6">
        <button @click="backToSignIn" class="btn-link" type="button">back to sign in form</button>
      </div>
    </template>
  </auth-card>
</template>

<script>
import {Auth, Hub} from 'aws-amplify';
import {AuthState, AUTH_STATE_CHANGE_EVENT, UI_AUTH_CHANNEL} from '@aws-amplify/ui-components';
import {
  dispatchAuthStateChangeEvent,
  dispatchToastHubEvent,
  prepareCognitoUsername,
} from '@/components/auth/helpers';
import Form from "@/components/form/Form";
import TextField from "@/components/form/TextField";
import ValidatorMixin from "@/components/form/ValidatorMixin";
import AuthCard from "@/components/auth/AuthCard";

export default {
  name: "ForgotPassword",
  components: {
    Form,
    TextField,
    AuthCard,
  },
  mixins: [ValidatorMixin],
  data() {
    return {
      codeSent: false,
      loading: false,
      username: undefined,
    }
  },
  methods: {
    closeToast() {
      const toast = document.querySelector('amplify-authenticator')
        .shadowRoot.querySelector('amplify-toast');

      if (toast) {
        toast.shadowRoot.querySelector('.toast-close').click();
      }
    },
    async sendCode({username}) {
      try {
        this.loading = true;
        this.closeToast();
        const formattedUsername = prepareCognitoUsername(username);
        this.username = formattedUsername;
        await Auth.forgotPassword(formattedUsername);
        this.codeSent = true;
      } catch (e) {
        dispatchToastHubEvent({message: e.code === "UserNotFoundException" ? "User with that email does not exist" : e.message});
      } finally {
        this.loading = false;
      }
    },
    async submit({code, newPassword}) {
      try {
        this.loading = true;
        await Auth.forgotPasswordSubmit(this.username, code, newPassword);
        dispatchAuthStateChangeEvent(AuthState.SignIn);
        dispatchToastHubEvent({message: 'Password has been reset'});

        setTimeout(() => {
          this.$refs.codeForm.$refs.form.finalForm.restart();
          this.$refs.passwordForm.$refs.form.finalForm.restart();
        });
      } catch (e) {
        dispatchToastHubEvent(e);
      } finally {
        this.loading = false;
      }
    },
    backToSignIn() {
      dispatchAuthStateChangeEvent(AuthState.SignIn)
    }
  },
  mounted() {
    Hub.listen(UI_AUTH_CHANNEL, ({payload: {event, data, message}}) => {
      if (event === AUTH_STATE_CHANGE_EVENT && message === AuthState.ForgotPassword) {
        this.codeSent = data?.codeSent || false;
        if (data?.username) {
          this.username = prepareCognitoUsername(data.username);
        }
      }
    });
  }
}
</script>
