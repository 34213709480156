import { Auth } from 'aws-amplify';
import Axios from 'axios';
import { omitBy, isNil } from 'lodash-es';
import store from '@/store';
import { stringify } from 'query-string';

const httpClient = baseURL => async request => {
    try {
        const session = await Auth.currentSession();

        return Axios.request({
            ...request,
            baseURL,
            paramsSerializer: params => stringify(params),
            headers: omitBy(
                {
                    Authorization: `Bearer ${session.getIdToken().getJwtToken()}`,
                    // TODO: rename it to "X-Quext-Profile-Id"
                    'X-Customer-User-Id': store.getters['auth/profile']?.profileId,
                    'X-Quext-Customer': store.getters['auth/profile']?.customerId,
                    ...request.headers,
                },
                isNil
            ),
        }).catch(error => {
            const response = error?.response;
            // Proxy response from RAA contains a string with a detailed error message in 'data' prop.
            const proxyResponse = typeof response?.data === 'string';
            const message = (proxyResponse && response?.data) || response?.data?.message || error.message;
            // Temporally commented
            // if (response?.status === 401) {
            //   Auth.signOut();
            // }

            return Promise.reject({
                message,
                ...response,
            });
        });
    } catch (e) {
        store.dispatch('alerts/notifyError', e.message);
        Auth.signOut();
    }
};

export default httpClient;
