import { isQuextAdmin } from '@/utils/actionAllowedRules';

export default [
    {
        routeName: 'quextUsers.index',
        items: [
            {
                title: 'add new user',
                routeName: 'quextUsers.create',
                params: {},
                allowed: isQuextAdmin,
            },
            {
                title: 'deleted users',
                routeName: 'quextUsers.deletedQuextUsers.index',
                params: {},
                allowed: isQuextAdmin,
            },
        ],
    },
    {
        routeName: 'quextUsers.edit',
        items: [],
    },
];
