<template>
    <label class="flex items-center" :class="{'cursor-pointer': !disabled}">
        <div
            class="flex flex-shrink-0 justify-center items-center border w-4 h-4"
            :class="[
                {
                    'bg-white': !isChecked,
                    'bg-blue-500': isChecked && !disabled,
                    'bg-gray-300': isChecked && disabled,
                    'mr-2': label,
                },
                disabled ? 'border-gray-300' : 'border-blue-500'
            ]"
        >
            <input
                type="checkbox"
                class="opacity-0 absolute w-0 h-0"
                :value="isChecked"
                @input="handleInput"
                :disabled="disabled"
            />
            <span class="inline-block pointer-events-none w-4 h-4 text-white" :class="{'hidden': !isChecked}">
                <icon name="tick"></icon>
            </span>
        </div>
        <div class="select-none" v-if="label" v-html="label" :class="{'text-gray-400': disabled}"></div>
    </label>
</template>

<script>
import Icon from "@/components/ui/Icon";

export default {
    name: "Checkbox",
    components: { Icon },
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        label: {
            type: String,
            required: false,
            default: ''
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        onInput: {
            type: Function,
            required: false,
        },
    },
    emits: ['change'],
    data: () => {
        return {
            isChecked: false,
        }
    },
    watch: {
        value: {
            handler: function() {
                this.isChecked = this.value;
            },
            immediate: true
        },
        isChecked: {
            handler: function() {
                /**
                 * Emitted when the checkbox value is changed
                 */
                this.$emit('change', this.isChecked);
            },
        }
    },
    methods: {
        handleInput(event) {
            const currentValue = event.target.value === 'true'; // convert to Boolean

            if (typeof this.onInput === 'function') {
                this.onInput(!currentValue);
            } else {
                this.isChecked = !currentValue;
            }
        },
    }
}
</script>
