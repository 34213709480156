<template>
    <div class="h-full overflow-hidden">
        <loader :loading="isUploading" :backdrop="true" />

        <file-manager-title>
            <span class="text-highlight-textSmall">Preview, edit and upload to "{{ manager.uploader.mainDirectory }}"</span>
        </file-manager-title>

        <div class="flex h-full mt-16">
            <div class="w-1/5 overflow-scroll">
                <div
                    class="border-4 w-32 my-2 relative"
                    :class="file.id === selectedFileId ? 'border-indigo-400' : ''"
                    @click="selectedFileId = file.id"
                    v-for="file in editedFiles"
                    :key="file.id"
                >
                    <div class="absolute top-0 right-0 w-6 h-6 flex items-center justify-center bg-gray-500 bg-opacity-50 cursor-pointer" @click="removeImage(file.id)">
                        <icon name="close" class="wh-4 h-4 block text-red-500"></icon>
                    </div>
                    <img :src="file.base64" class="cursor-pointer h-auto" />
                </div>
            </div>
            <div class="w-4/5">
                <div
                    v-for="file in editedFiles"
                    :key="file.id"
                >
                    <image-editor
                        :image="file.base64"
                        :aspect-ratio="manager.uploader.cropperOptions.aspectRatio"
                        :crop-box-resizable="manager.uploader.cropperOptions.cropBoxResizable"
                        :zoom-on-wheel="manager.uploader.cropperOptions.zoomOnWheel"
                        @image-cropped="cropImage"
                        v-if="file.id === selectedFileId"
                    >
                    </image-editor>
                </div>
            </div>
        </div>

        <div class="absolute bottom-0 left-0 w-full px-8 py-6 flex justify-between bg-gray-200">
            <button
                class="btn-primary btn-transparent"
                :disabled="isUploading"
                @click="close"
            >
                cancel
            </button>
            <button
                class="btn-primary btn-solid"
                @click="upload"
                :disabled="isUploading"
                v-if="files.length"
            >
                upload
            </button>
        </div>
    </div>
</template>

<script>
import Icon from "@/components/ui/Icon";
import Loader from "@/components/ui/Loader";
import {mapActions, mapGetters} from "vuex";
import NotifyMixin from '@/mixins/NotifyMixin';
import ImageEditor from "@/components/ui/filemanager/ImageEditor";
import FileManagerTitle from "@/components/ui/filemanager/FileManagerTitle";

export default {
    mixins: [ NotifyMixin ],
    components: { Loader, Icon, FileManagerTitle, ImageEditor },
    props: {
        managerId: {
            type: String,
            required: true
        },
        files: {
            type: Array,
            required: true,
            default: () => { return [] }
        },
        noCropper: {
          type: Boolean,
          required: false,
          default: false,
        },
    },
    emits: [
        'close',
        'uploaded-to-s3',
    ],
    data: () => {
        return {
            manager: {},
            editedFiles: [],
            selectedFileId: null,
            isUploading: false
        }
    },
    computed: {
        ...mapGetters({
            getManagerById: 'files/getManagerById'
        })
    },
    methods: {
        ...mapActions({
            addFilesToLibrary: 'files/addFilesToLibrary',
            clearManagerUploadedFiles: 'files/clearManagerUploadedFiles',
            addFilesToManagerSelectedFiles: 'files/addFilesToManagerSelectedFiles',
            addFilesToManagerLibrary: 'files/addFilesToManagerLibrary',
            removeFileFromManagerUploadedFiles: 'files/removeFileFromManagerUploadedFiles'
        }),
        cropImage(payload) {
            let file = this.editedFiles.find( file => file.id === this.selectedFileId );
            file.base64 = payload.image;
        },
        upload() {
            this.isUploading = true;
            this.$filemanagerDataProvider.create('file', {
                data: {
                    file: this.editedFiles ? this.editedFiles : this.files,
                    community_id: this.manager.communityId,
                    main_directory: this.manager.uploader.mainDirectory,
                    skip_global_directory: true
                }
            })
            .then( (response) => {
                let files = response.data;
                let payload = { managerId: this.manager.managerId, files: files };

                // create file api and get file api are not consistent
                // the first returns file_classification and the second returns file_type
                files.forEach( (file) => {
                    if(Object.hasOwn(file, 'file_classification')) {
                        file.file_type = file.file_classification;
                    }
                });

                this.addFilesToLibrary( files );

                //if(this.manager.library.length)
                this.addFilesToManagerLibrary( payload );

                this.addFilesToManagerSelectedFiles( payload );

                files.forEach( _file => this.$emit('uploaded-to-s3', _file));
            })
            .catch(() => {
                this.notifyError('There was an error uploading the file(s).')
            })
            .finally( () => {
                this.isUploading = false;
                this.close();
            });
        },
        removeImage(fileId) {
            this.editedFiles = this.editedFiles.filter( item => item.id !== fileId  );

            window.setTimeout( () => {
                this.selectedFileId = this.editedFiles.length ? this.editedFiles[0].id : null;
            }, 10);

            if(!this.editedFiles.length) {
                this.close();
            }
        },
        close() {
            this.$emit('close');
            this.clearManagerUploadedFiles(this.manager.managerId);
        }
    },
    created() {
        this.manager = this.getManagerById(this.managerId);
        this.editedFiles = this.files;
        this.selectedFileId = this.editedFiles.length ? this.editedFiles[0].id : null;
        if(this.noCropper)
          this.upload();
    }
}
</script>
