import DataProvider from '@/api/DataProvider.js';
import HttpAdapter from '@/api/adapters/HttpAdapter';
import resources from './resources';
import httpClient from '@/api/httpClient';
import getEnvVariable from '@/utils/getEnvVariable';

const baseURL = getEnvVariable('VUE_APP_API_DIGITAL_HUMAN_URL');

const actions = ['get', 'getOne', 'getList', 'create'];
const digitalHumanResources = [
    'conversationReport',
    'conversationReportPdf',
    'customResponseReportByTopic',
    'customResponseReportByTopicPdf',
    'customResponseCustomer',
    'customResponseCustomerPdf',
    'globalResponseReport',
    'globalResponseReportPdf',
    'transcriptReport',
    'mismatchReport',
    'previewGlobalResponsesReport',
    'previewCustomResponsesTopicReport',
    'previewCustomResponsesCustomerReport',
    'previewConversationGlobalReport',
    'previewConversationCustomReport',
    'translateReport',
    'uploadAvailability',
    'allUnits',
    'unit',
    'editUnit',
    'validateAvailability',
    'allCommunityCustomIntentResponses',
    'allGuest',
    'intent',
    'getIntent',
    'deleteIntent',
    'getHoursOfOperations',
    'previewTrainingPhrasesReport',
    'trainingPhrasesReportPdf',
];

const beforeRequestInterceptor = ({ requestConfig, action, resource }) => {
    if (actions.includes(action) && digitalHumanResources.includes(resource)) {
        return {
            ...requestConfig,
            responseType: requestConfig.params.responseType,
            headers: { ...requestConfig.params.headers },
        };
    }

    return requestConfig;
};

const httpAdapter = new HttpAdapter(httpClient(baseURL), resources, beforeRequestInterceptor);

export default new DataProvider(httpAdapter);
