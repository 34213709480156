const routes = [
    {
        name: 'standard-response',
        path: '/reports/standard-response',
        component: () => {
            return import('@/views/report/tabular_report/StandardResponses.vue');
        },
        meta: {
            loadInModal: true,
            breadcrumbName: 'Standard Responses',
        },
    },
    {
        name: 'custom-responses-by-customer',
        path: '/reports/custom-responses-by-customer',
        component: () => {
            return import('@/views/report/tabular_report/CustomResponsesByCustomer.vue');
        },
        meta: {
            loadInModal: true,
            breadcrumbName: 'Custom Responses by Customer',
        },
    },
    {
        name: 'mismatch-intents',
        path: '/reports/mismatch-intents',
        component: () => {
            return import('@/views/report/tabular_report/MismatchIntents.vue');
        },
        meta: {
            loadInModal: true,
            breadcrumbName: 'Mismatch Intents',
        },
    },
    {
        name: 'response-translation',
        path: '/reports/response-translation',
        component: () => {
            return import('@/views/report/tabular_report/ResponseTranslation.vue');
        },
        meta: {
            loadInModal: true,
            breadcrumbName: 'Response Translation',
        },
    },
    {
        name: 'my-conversation',
        path: '/reports/my-conversation',
        component: () => {
            return import('@/views/report/tabular_report/MyConversation.vue');
        },
        meta: {
            loadInModal: true,
            breadcrumbName: 'My Conversation',
        },
    },
    {
        name: 'conversation-transcript',
        path: '/reports/conversation-transcript',
        component: () => {
            return import('@/views/report/tabular_report/ConversationTranscript.vue');
        },
        meta: {
            loadInModal: true,
            breadcrumbName: 'Conversation Transcript',
        },
    },
    {
        name: 'conversations-by-main-topic',
        path: '/reports/conversations-by-main-topic',
        component: () => {
            return import('@/views/report/tabular_report/ConversationsByMainTopic.vue');
        },
        meta: {
            loadInModal: true,
            breadcrumbName: 'Conversations by Main Topic',
        },
    },
];

export default routes;
